import { userManagerConstants } from '../constants';
import { userManagerRequests } from '../../services'

export const userManagerActions = {}

userManagerActions.GetRolesMC = (data) => {
  return dispatch => {
    dispatch(request())
    userManagerRequests.GetRolesMC(data)
      .then(
        roleMCData => dispatch(success(roleMCData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: userManagerConstants.GET_ROLESMC_REQUEST }
  }

  function success(roleMCData) {
    return { type: userManagerConstants.GET_ROLESMC_SUCCESS, roleMCData }
  }

  function failure(error) {
    return { type: userManagerConstants.GET_ROLESMC_FAILURE, error }
  }
}

userManagerActions.GetProductUserMC = (data) => {
  return dispatch => {
    dispatch(request())
    userManagerRequests.GetProductUserMC(data)
      .then(
        productUserMC => dispatch(success(productUserMC)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: userManagerConstants.GET_PRODUCTSUSERMC_REQUEST }
  }

  function success(productUserMC) {
    return { type: userManagerConstants.GET_PRODUCTSUSERMC_SUCCESS, productUserMC }
  }

  function failure(error) {
    return { type: userManagerConstants.GET_PRODUCTSUSERMC_FAILURE, error }
  }
}

userManagerActions.RegisterUserMC = (data) => {
  return dispatch => {
    dispatch(request())
    userManagerRequests.RegisterUserMC(data)
      .then(
        registerUserMCData => dispatch(success(registerUserMCData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: userManagerConstants.REGISTER_USERMC_REQUEST }
  }

  function success(registerUserMCData) {
    return { type: userManagerConstants.REGISTER_USERMC_SUCCESS, registerUserMCData }
  }

  function failure(error) {
    return { type: userManagerConstants.REGISTER_USERMC_FAILURE, error }
  }
}

userManagerActions.UpdateUserMC = (data) => {
  return dispatch => {
    dispatch(request())
    userManagerRequests.UpdateUserMC(data)
      .then(
        updateUserMCData => dispatch(success(updateUserMCData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: userManagerConstants.UPDATE_USERMC_REQUEST }
  }

  function success(updateUserMCData) {
    return { type: userManagerConstants.UPDATE_USERMC_SUCCESS, updateUserMCData }
  }

  function failure(error) {
    return { type: userManagerConstants.UPDATE_USERMC_FAILURE, error }
  }
}

userManagerActions.GetUsersMC = (data) => {
  return dispatch => {
    dispatch(request())
    userManagerRequests.GetUsersMC(data)
      .then(
        usersMCData => dispatch(success(usersMCData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: userManagerConstants.GET_USERSMC_REQUEST }
  }

  function success(usersMCData) {
    return { type: userManagerConstants.GET_USERSMC_SUCCESS, usersMCData }
  }

  function failure(error) {
    return { type: userManagerConstants.GET_USERSMC_FAILURE, error }
  }
}