import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./TableStyle.js";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  styled,
  useTheme,
} from "@mui/material";

import { constPathRoot, paths, routes } from "../../utils/index.js";
import { ThemeConfigMake } from "../../theme/ThemeConfig.jsx";

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableMain} !important`,
  color: `${theme.palette.primary.tableText} !important`,
}));

const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
  },
}));

export function MainTableSearch({ data, SetCurrentPage }) {
  const theme = useTheme();
  const history = useNavigate();
  const localStyle = useStyles(theme);
  let path = window.location.pathname;
  const newData = data?.map((col) => {
    return {
      Folio: col.Folio,
      Solicitante: col.FullName,

      Curp: col?.Metadata?.find((dato) => dato.Name == "CURP")?.Value,
      NumeroPoliza: col?.Metadata?.find((dato) => dato.Name == "NumeroPoliza")
        ?.Value,
      Producto: col?.Metadata?.find((dato) => dato.Name == "Producto")?.Value,
      CoberturaReclamada: col?.Metadata?.find(
        (dato) => dato.Name == "CoberturaReclamada"
      )?.Value,
      Correo: col.Email,
      Estatus: col.Stage.Description,
      IdFolio: col.Id,
      TipoProducto: col.WorkFlowName,
      FechaAsignacion: col?.Metadata?.find(
        (dato) => dato.Name == "Fecha de asignación"
      )?.Value,
      RolAsignado: col?.Metadata?.find((dato) => dato.Name == "Rol asignado")
        ?.Value,
      UsuarioAsignado: col?.Metadata?.find(
        (dato) => dato.Name == "Usuario asignado"
      )?.Value,
    };
  });

  const columns = [
    { id: "folio", label: "Folio", minWidth: 100 },
    { id: "solicitante", label: "Nombre", minWidth: 170 },
    { id: "curp", label: "CURP", minWidth: 170 },
    { id: "numPoliza", label: "Número de póliza", minWidth: 150 },
    { id: "producto", label: "Producto", minWidth: 170 },
    { id: "cobeReclamada", label: "Cobertura Reclamada", minWidth: 170 },
    { id: "status", label: "Estatus", minWidth: 170 },
    { id: "dateAssign", label: "Fecha de Asignación", minWidth: 150 },
    { id: "rolAssign", label: "Rol Asignado", minWidth: 150 },
    { id: "userAssign", label: "Usuario Asignado", minWidth: 150 },
  ];

  const createData = (
    folio,
    solicitante,
    curp,
    numPoliza,
    producto,
    cobeReclamada,
    perfil,
    status,
    idOfFolio,
    dateAssign,
    rolAssign,
    userAssign
  ) => {
    return {
      folio,
      solicitante,
      curp,
      numPoliza,
      producto,
      cobeReclamada,
      perfil,
      status,
      idOfFolio,
      dateAssign,
      rolAssign,
      userAssign,
    };
  };

  const rows = newData?.map((r) => {
    return createData(
      r.Folio,
      r.Solicitante,
      r.Curp,
      r.NumeroPoliza,
      r.Producto,
      r.CoberturaReclamada,
      r.Perfil,
      r.Estatus,
      r.IdFolio,
      r.FechaAsignacion,
      r.RolAsignado,
      r.UsuarioAsignado
    );
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    console.log("newPage:::", newPage);
    SetCurrentPage(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [orderDirection, setOrderDirection] = useState("");
  const [valueToOrderBy, setValueToOrderBy] = useState("");

  const handlerSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property);
  };

  return (
    <div style={{ margin: "0 1em 0 1em", height: "100%" }}>
      <TableContainer style={{ overflow: "auto", height: "63vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCellHead
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  onClick={createSortHandler(column.label)}
                >
                  {column.label}
                </TableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRowCustom
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    onClick={() => {
                      if (path === paths.search) {
                        history(`${routes.busqueda}/${row.idOfFolio}`);
                      } else if (
                        path === paths.homeComponentSingle ||
                        path === paths.homeComponent
                      ) {
                        history(`${routes.asignacion}/${row.idOfFolio}`);
                      }
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRowCustom>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        className={localStyle.tablePaginacion}
        rowsPerPageOptions={[10]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
}
