import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
    //Main Table 
    container: {
        // width: '90%',
        width: '100%',
    },
    spinSection: {
        height: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container1: {
        width: '90%',
        // overflow: 'hidden',
        overflowX: 'hidden',
        overflowY: 'auto'
    },

    table: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        height: '90%',
        alignItems: 'center',
    },

    head: {
        fontSize: 16,
        fontWeight: 500,
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.primary.text} !important`,
    },

    //Binnacle Table

    binnacleTable: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        alignItems: 'center !important'
    },
    tablePaginacion: {
        width: '95% !important',
        overflow: 'hidden !important',
        '&.MuiTablePagination-toolbar': {
            overflow: 'hidden !important',
        }
    },

    tableRow: {
        "&:hover": {
            backgroundColor: `${theme.palette.primary.hover} !important`,
        }
    },
    tableRowControl: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // marginLeft: theme.spacing(3),
        // marginRight: theme.spacing(3),
        // height: '90%',
        // alignItems: 'center',
    },
    containerRowControl: {
        width: '100%',
    },
    container2: {
        width: '100%',
        // overflow: 'hidden',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    tablePaginacion2: {
        width: '100% !important',
        overflow: 'hidden !important',
        '&.MuiTablePagination-toolbar': {
            overflow: 'hidden !important',
        }
    },
    table2: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // marginLeft: theme.spacing(3),
        // marginRight: theme.spacing(3),
        height: '100%',
        alignItems: 'center',
        width: '81.5% !important'
    },
    containerResult: {
        display: 'grid !important',
        gridTemplateColumns: '70% 30%',
        ['@media only screen and (max-width: 812px)']: {
            // gridTemplateColumns: '5% 90% 5%',
            gridTemplateColumns: '50% 50%',
        },
        overflow: 'hidden !important',
    },
    leftColumnResult: {
        display: 'flex',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        overflowY: 'none',
        justifyContent: 'center !important',
    },
    rightColumnResult: {
        display: 'flex',
        flexDirection: 'column !important',
        alignItems: 'flex-end !important',
        justifyContent: 'center !important',
        overflowY: 'hidden !important',
    },
    inputText: {
        marginTop: 15,
        /*   display: "block", */
        "& .MuiOutlinedInput-input": {
            height: 25,
            padding: "10px 15px",
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: 12,
            borderColor: "red",
            fontSize: 14,
            "& fieldset legend span": {
                display: "none",
            },
        },
        "& .MuiInputLabel-root": {
            color: "#000",
            position: "relative",
            transform: "none",
            fontSize: 14,
            marginBottom: 8,
            marginTop: 5,
            display: "flex",
            alignItems: "center",
            pointerEvents: "auto",
            fontWeight: 500,
        },
        "& label.Mui-focused": {
            color: theme.palette.primary.main,
        },
    },
    outlinedText: {
        marginTop: 15,
        borderRadius: 12,
        fontSize: 14,
        "& .MuiOutlinedInput-input": {
            height: 25,
            padding: "10px 15px",
        },
    },
    errorInput: {
        "& fieldset": {
            border: `2px solid red`,
        },
        "& .MuiFormLabel-root": {
            color: "red",
        },
    },
    indicator: {
        color: "red",
    },
    successClass: {
        color: theme.palette.success.main,
    },
    errorClass: {
        color: theme.palette.error.main,
    },
    contentButtos: {
        width: '100% !important',
        marginTop: theme.spacing(1),
        textAlign: 'center'
    },
    dataValue: {
        display: 'flex !important',
        flexWrap: 'wrap !important',
        whiteSpace: 'normal !important',
        width: '100% !important',
        overflowX: 'auto !important'
    },
}))