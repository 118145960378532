import { userManagerConstants } from '../constants/index.js'

export const RolesMC = (state = {}, action) => {
    switch (action.type) {
        case userManagerConstants.GET_ROLESMC_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case userManagerConstants.GET_ROLESMC_SUCCESS:
            return {
                loading: false,
                items: action.roleMCData.Body,
                error: undefined
            }
        //break;
        case userManagerConstants.GET_ROLESMC_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const ProductUserMC = (state = {}, action) => {
    switch (action.type) {
        case userManagerConstants.GET_PRODUCTSUSERMC_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case userManagerConstants.GET_PRODUCTSUSERMC_SUCCESS:
            return {
                loading: false,
                items: action.productUserMC.Body,
                error: undefined
            }
        //break;
        case userManagerConstants.GET_PRODUCTSUSERMC_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const RegisterUserMC = (state = {}, action) => {
    switch (action.type) {
        case userManagerConstants.REGISTER_USERMC_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case userManagerConstants.REGISTER_USERMC_SUCCESS:
            return {
                loading: false,
                items: action.registerUserMCData.Body,
                error: undefined
            }
        //break;
        case userManagerConstants.REGISTER_USERMC_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const UpdateUserMC = (state = {}, action) => {
    switch (action.type) {
        case userManagerConstants.UPDATE_USERMC_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case userManagerConstants.UPDATE_USERMC_SUCCESS:
            return {
                loading: false,
                items: action.updateUserMCData.Body,
                error: undefined
            }
        //break;
        case userManagerConstants.UPDATE_USERMC_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const GetUsersMC = (state = {}, action) => {
    switch (action.type) {
        case userManagerConstants.GET_USERSMC_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case userManagerConstants.GET_USERSMC_SUCCESS:
            return {
                loading: false,
                items: action.usersMCData.Body,
                error: undefined
            }
        //break;
        case userManagerConstants.GET_USERSMC_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}