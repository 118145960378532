import { catalogConstants } from '../constants'
import { catalogRequest } from '../../services'

export const catalogActions = {}

catalogActions.GetCatalog = (data) => {
  return dispatch => {
    dispatch(request())
    catalogRequest.GetCatalog(data)
      .then(
        catalog => dispatch(success(catalog)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: catalogConstants.CATALOG_REQUEST }
  }

  function success(catalog) {
    return { type: catalogConstants.CATALOG_SUCCESS, catalog }
  }

  function failure(error) {
    return { type: catalogConstants.CATALOG_FAILURE, error }
  }
}
