import React, { useEffect, useState } from 'react'
import { Typography, Button, List,Box, styled,useTheme } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DeleteIcon from '@mui/icons-material/Delete';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import PersonAdd from '@mui/icons-material/PersonAdd';
import PhotoCameraOutlined from '@mui/icons-material/PhotoCameraOutlined';
import Group from '@mui/icons-material/Group';
import Description from '@mui/icons-material/Description';

// import {
//     mainStyles,//Estílos genéricos
//     theme//Téma con colores de DS
// } from '../styles/Main.js'//Se importan los estilos genéricos
import { UseImage } from './'

const ButtonItem = styled(Button)(({ theme }) => ({
    '.MuiButton-root': {
        width: '100%',
    },
    width: '100%',
    padding: '16px',
    gap: '16px',
    height: '56px',
    background: '#FCFCFC',
    border: '1px solid #F2F2F2',
    borderRadius: '0px',
    marginTop: '15px',
    '&:focus': {
        outline: 'none !important',
    },
    '&:hover': {
        outline: 'none !important',
    },
}));

export const ListElements = (props) => {

    useEffect(() => {
        // console.log("ListElements props: ", props)
    }, [props.Data])

    return (<List
        component="nav"
        aria-label="secondary mailbox folders"
        style={{
            '&.MuiList-padding': {
                padding: '0px !important'
            }
        }}
    >
        {props.Data?.map((docItem, index) => {
            // const isCapture = ifCapture(docItem.Types)
            // console.log("docItem*****************: ", docItem)
            return (
                <ButtonItem onClick={() => {
                    props.handleButton(docItem)
                }}>
                    <ItemElement
                        keyProps={docItem.Id}
                        text={docItem.Required ? `${docItem.Name}*` : docItem.Name}
                        capture={docItem.Capture}
                        description={docItem.Description}
                        status={docItem.IsCapture ? "Capturado" : ""}
                        statusColor={docItem.IsCapture ? 1 : undefined}
                    />
                </ButtonItem>
            );
        }
        )}
    </ List>);
}

const GetImageName = (props) => {
    if (props.text === 'Cobro realizado') {
        return "ic_cobro_sin_exito.png";
    } else if (props.icon?.includes('IC_SUB_')) {
        return `${props.icon}.svg`;
    } else if (props.icon === 'ICONO_02') {
        return 'ICONO_02.svg';
    } else if (props.icon === 'ICONO_01') {
        return 'ICONO_01.svg';
    } else {
        return ""
    }
}

const renderComponent = (props) => {
    if (props.text.includes('visita') || props.text.includes('Visita')) {
        return (<LocationOnIcon style={(theme)=>({ color: theme.palette.primary.color, })} />);
    } else if (props.text === 'INE / IFE*' || props.text === 'Pasaporte*' ||
        props.text === "Comprobante de domicilio*") {
        return (<PhotoCameraOutlined style={(theme)=>({ color: theme.palette.primary.color, })} />);
    } else if (props.capture === "Document Upload, Camera") {
        return (<ReceiptIcon style={(theme)=>({ color: theme.palette.primary.color, })} />);
    } else if (props.capture === "Camera") {
        return (<PhotoCameraIcon style={(theme)=>({ color: theme.palette.primary.color, })} />);
    } else if (props.capture === "Document Upload") {
        return (<AttachFileIcon style={(theme)=>({ color: theme.palette.primary.color, })} />);
    } else if (props.text.includes('Personal') || props.text === 'Presidente') {
        return (<AccountBoxIcon style={(theme)=>({ color: theme.palette.primary.color, })} />);
    } else if (props.text === 'Cobranza') {
        return (<AttachMoneyIcon style={(theme)=>({ color: theme.palette.primary.color, })} />);
    } else if (props.text === 'Biométricos') {
        return (<AccessibilityNewIcon style={(theme)=>({ color: theme.palette.primary.color, })} />);
    } else if (props.text.includes('Grupal')) {
        return (<Group style={(theme)=>({ color: theme.palette.primary.color })} />);
    } else if (props.text === 'Tesorero') {
        return (<MonetizationOnIcon style={(theme)=>({ color: theme.palette.primary.color, })} />);
    } else if (props.text === 'Secretario') {
        return (<PermPhoneMsgIcon style={(theme)=>({ color: theme.palette.primary.color, })} />);
    } else if (props.text === 'Socio' || props.text === 'Soy cliente') {
        return (<PersonIcon style={(theme)=>({ color: theme.palette.primary.color, })} />);
    } else if (props.text === 'Nuevo cliente') {
        return (<PersonAdd style={(theme)=>({ color: theme.palette.primary.color, })} />);
    } else if (props.newItem) {
        return (<AddIcon style={(theme)=>({ color: theme.palette.primary.color, })} />);
    } else {
        let imgNameCuston = GetImageName(props)
        if (imgNameCuston == "") {
            return (<Description style={(theme)=>({ color: theme.palette.primary.color, })} />);
        } else {
            return (<UseImage type='Image' src={imgNameCuston} />);
        }
    }
};

const ItemElement = (props) => {
    const theme = useTheme()
    const [statusColor, setStatusColor] = React.useState('#ffffff')
    const localStyles = null //mainStyles.bodyStyles()
    // console.log("ItemElement: ", props)

    useEffect(() => {
        console.log("props.statusColor: ", props.statusColor)
        if (props.statusColor !== undefined) {
            if (props.statusColor == 1) {
                setStatusColor('#00847F')
            } else if (props.statusColor == 2) {
                setStatusColor('red')
            } else if (props.statusColor == 3) {
                setStatusColor('#FFC200') // en aprobación
            } else if (props.statusColor == 4) {
                setStatusColor(theme.palette.primary.main) // color tema
            }
        } else {
            setStatusColor('#ffffff')
        }
    }, [props.statusColor])

    return (<Box id={props.keyProps}
        sx={{
            display: 'flex !important',
            flexDirection: 'row !important',
            alignItems: 'center !important',
            justifyContent: 'flex-start !important',
            width: '100%'
        }}>
        {renderComponent()}
        <Box sx={{
            display: 'flex !important',
            flexDirection: 'column !important',
            alignItems: 'flex-start !important',
            justifyContent: 'center !important',
            marginLeft: '10px',
            width: '90%',
            textTransform: 'none !important',
        }}>
            <Typography
                variant="subtitle2"
                display="block" >
                {props.text}
            </Typography>
            <Typography
                variant="caption"
                display="block"
                style={props.isRejected ? { color: "#F20000" } : { color: "#00847F" }}
                className={localStyles.listText} >
                {props.isRejected ? "Rechazado" : props.isRejected !== undefined ? "Leído y autorizado" : ""}
            </Typography>

            {props.status &&
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    style={{ color: statusColor }}
                    className={localStyles.listText}>
                    {props.delete && <DeleteIcon
                        key={`delete_${props.keyProps}`}
                        className={localStyles.deleteIcon} />}
                    {props.status}
                </Typography>
            }
        </Box>
        {!props.noArrow && <ArrowForwardIosIcon
            key={`arrow_${props.keyProps}`}
            className={localStyles.arrowIcon} />}
    </Box>)
}