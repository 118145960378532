import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { validationActions, fileActions } from '../../store/actions'
import { paths, routes } from '../../utils'
import { useStyles } from './ModalStyles'
import { Buttons } from '../index';

export function ModalApproval({ folioId, actorId }) {
    const theme = useTheme()
    const history = useNavigate()
    const localStyles = useStyles(theme)
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const [comments, setComments] = useState("")
    const [approved, setApproved] = useState(false)
    const dispatch = useDispatch()
    const validate = useSelector(state => state.FileValidation)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    //Validation

    const sendApproval = () => {
        setApproved(true)
        dispatch(validationActions.PostFileValidation({
            File_Id: folioId,
            Actor_Id: actorId,
            Approved: true,
            Comments: "",
            ContinueWorkFlow: true
        }))
        handleClose()
    }


    const sendRejection = () => {
        setApproved(true)
        dispatch(validationActions.PostFileValidation({
            File_Id: folioId,
            Actor_Id: actorId,
            Approved: false,
            Comments: comments,
            ContinueWorkFlow: true
        }))
        handleCloseModal()
    }

    // Se comento por flujo de SKY  09/11/2021
    useEffect(() => {
        if (validate.error !== undefined && approved) {
            alert(validate.error)
        }
        if (validate.items !== undefined && approved) {
            dispatch(fileActions.GetSearchByUser({ "SearchText": "" }))
            dispatch(fileActions.GetFileInfo(folioId))
            history(routes.home)
        }
    }, [validate])


    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Buttons ConfirmButtonText="Aprobar Solicitud"
                onClickConfirm={handleClickOpen}
                CancelButtonText="Rechazar Solicitud"
                onClickCancel={handleClickOpenModal} />

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent className={localStyles.modalApproval}>
                    <CheckCircleIcon style={{ color: "#00847F", width: '40px', height: '40px' }} />
                    <DialogContentText className={localStyles.modalText}>
                        ¿Estás seguro de aprobar la documentación del prospecto?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={localStyles.modalActionApproval}>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ height: 45, px: 10, width: "40%" }}
                        onClick={sendApproval} >
                        Aceptar
                    </Button>
                    <Button
                        fullWidth
                        variant="outlined"
                        sx={{
                            height: 45, px: 10, width: "40%",
                            color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' }
                        }}
                        onClick={handleClose} >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                <div className={localStyles.line}></div>
                <DialogContent className={localStyles.modalCancel}>
                    <div className={localStyles.modalApproval}>
                        <CancelIcon style={{ color: "red", width: '40px', height: '40px' }} />
                        <DialogContentText className={localStyles.modalText}>
                            Ingresa el motivo por el cual se rechaza la solicitud
                        </DialogContentText>
                    </div>
                    <div className={localStyles.textFieldArea}>
                        <TextField
                            autoFocus
                            margin="dense"
                            placeholder="Motivo"
                            type="text"
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined"
                            onChange={(event) => {
                                setComments(event.target.value)
                            }}

                        />
                    </div>
                </DialogContent>

                <DialogActions className={localStyles.modalActionApproval}>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ height: 45, px: 10, width: "40%" }}
                        onClick={sendRejection}  >
                        Aceptar
                    </Button>
                    <Button
                        fullWidth
                        variant="outlined"
                        sx={{
                            height: 45, px: 10, width: "40%",
                            color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' }
                        }}
                        onClick={handleCloseModal} >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )

}