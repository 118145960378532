export const notificationConstants = {
  NEW_PIN_REQUEST: 'NEW_PIN_REQUEST',
  NEW_PIN_SUCCESS: 'NEW_PIN_SUCCESS',
  NEW_PIN_FAILURE: 'NEW_PIN_FAILURE',

  GET_NOTIFICATIONS_USER_REQUEST: 'GET_NOTIFICATIONS_USER_REQUEST',
  GET_NOTIFICATIONS_USER_SUCCESS: 'GET_NOTIFICATIONS_USER_SUCCESS',
  GET_NOTIFICATIONS_USER_FAILURE: 'GET_NOTIFICATIONS_USER_FAILURE',

  GET_METADATA_FILL_REQUEST: 'GET_METADATA_FILL_REQUEST',
  GET_METADATA_FILL_SUCCESS: 'GET_METADATA_FILL_SUCCESS',
  GET_METADATA_FILL_FAILURE: 'GET_METADATA_FILL_FAILURE',

  GET_NOTIFICATIONS_CANCELABLES_REQUEST: 'GET_NOTIFICATIONS_SETCANCEL_REQUEST',
  GET_NOTIFICATIONS_CANCELABLES_SUCCESS: 'GET_NOTIFICATIONS_SETCANCEL_SUCCESS',
  GET_NOTIFICATIONS_CANCELABLES_FAILURE: 'GET_NOTIFICATIONS_SETCANCEL_FAILURE',

  GET_NOTIFICATIONS_SETCANCEL_REQUEST: 'GET_NOTIFICATIONS_SETCANCEL_REQUEST',
  GET_NOTIFICATIONS_SETCANCEL_SUCCESS: 'GET_NOTIFICATIONS_SETCANCEL_SUCCESS',
  GET_NOTIFICATIONS_SETCANCEL_FAILURE: 'GET_NOTIFICATIONS_SETCANCEL_FAILURE',

}
