import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './TableStyle.js'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel, styled, useTheme
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableMain} !important`,
  color: `${theme.palette.primary.tableText} !important`,
}));

const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
  }
}));

const CustomTableCell = ({ isCancelled }) => {
  return (
    <TableCell>
      {isCancelled ? (
        <CancelIcon color="error" />
      ) : (
        <CheckCircleIcon style={{ color: 'green' }} />
      )}
    </TableCell>
  );
};

export function MainTableDinamyc({ Rows, Columns, OnClickData }) {
  const theme = useTheme()
  const history = useNavigate()
  const localStyle = useStyles(theme)
  let path = window.location.pathname


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [orderDirection, setOrderDirection] = useState('')
  const [valueToOrderBy, setValueToOrderBy] = useState('')

  const handlerSort = (event, property) => {
    const isAscending = (valueToOrderBy === property && orderDirection === 'asc')
    setValueToOrderBy(property)
    setOrderDirection(isAscending ? 'desc' : 'asc')
  }

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property)
  }

  return (
    <div style={{ margin: '0' }}>
      <TableContainer sx={{ width: '100%' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {Columns.map((column) => (
                <TableCellHead
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  onClick={createSortHandler(column.label)}
                >
                  {column.label}
                </TableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRowCustom hover role="checkbox" tabIndex={-1} key={row.code}
                    onClick={() => {
                      if (OnClickData) {
                        OnClickData(row)
                      }
                    }}>
                    {Columns.map((column) => {
                      const value = row[column.id];
                      if (column?.id == 'State') {
                        return (<CustomTableCell isCancelled={!value} />);
                      } else {
                        return (
                          <TableCell key={column.id} align={column.align} onClick={() => console.log('CLIKO', column)}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      }
                      // }
                    })}
                  </TableRowCustom>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={localStyle.tablePaginacion}
        rowsPerPageOptions={[10]}
        component="div"
        count={Rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
      />
    </div>
  )
}