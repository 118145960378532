import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import packageJson from "../../package.json";

export function Footer() {
    const useStyles = makeStyles(() => ({
        bodyFooter: {
            marginTop: "2%",
            marginBottom: "1%",
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        },
        text: {
            fontSize: '12px',
        }
    }))
    return (
        <div className={useStyles().bodyFooter}>
            <Typography className={useStyles().text}>{`${packageJson.copyright} V.${packageJson.version}`}</Typography>
        </div>

    )
}