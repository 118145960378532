import { useStyles } from "../tabs/TabsStyle";
import {
  FormProvider,
  useFormContext,
  Controller,
  useForm,
} from "react-hook-form";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { makeStyles, styled, useTheme } from "@mui/styles";
import { DatePicker as DPick } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { UploadDcumentsControl, UseImage } from "../../components";
import { ticketActions } from "../../store/actions";
import ticketDocument from "../../assets/ticketDocument.svg";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  Box,
  Stack,
  TextField as InputText,
  Typography,
  MenuItem,
  IconButton,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { CustomLoadingOverlay, GenericHeaderActions } from "../index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useRef, useState } from "react";

const themeDatePicker = createTheme({
  palette: {
    primary: {
      main: "#000", // Color principal
      dark: "#000000",
      light: "#f0f0f0",
    },
    secondary: {
      main: "#00ff00", // Color secundario
    },
    background: {
      default: "#f0f0f0", // Color de fondo
    },
  },
});
const DatePicker = styled(DPick)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      // height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    fontSize: 14,
    width: "100%",
    "& fieldset legend span": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      borderRadius: 0,
    },
    "&:focus-within fieldset": {
      borderColor: "black ", // Cambia el color del contorno al hacer focus
    },
  },

  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B",
    backgroundColor: "#F9F2F3",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    // color: '#A8000B',
    color: "#000 !important",
  },
  "& .MuiInputLabel-root.Mui-error": {
    // color: '#A8000B',
    color: "#000 !important",
  },
  "& .MuiFormLabel-root.Mui-error": {
    // color: '#A8000B', // Color de la etiqueta en caso de error
    color: "#000 !important",
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    backgroundColor: "#F9F2F3",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input": {
    backgroundColor: "transparent", // Fondo del texto transparente
  },
}));

const TextField = styled(InputText)(({ theme }) => ({
  marginTop: "8px",
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    fontSize: 14,
    width: "100%",
    "& fieldset legend span": {
      display: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black", // Color del borde cuando está enfocado
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      borderRadius: 0,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    marginBottom: 7,
    marginTop: 0,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    fontFamily: '"UniversNextforHSBC-Regular", sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "144%",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black", // Color del borde cuando está enfocado
    },
  },
  /* "& label.Mui-focused": {
      color: theme.palette.primary.main,
    }, */
  "& label.Mui-error": {
    // color: "#A8000B !important",
    color: "#000 !important",
    // #A8000B
  },
  "& .MuiSelect-select": {
    /*  paddingTop: '15px',
         paddingBottom: '1px', */
    margin: "0 auto",
    marginTop: "3px",
    marginBottom: "2px",
    // height: '30px !important',
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
      },
    },
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B !important",
    backgroundColor: "#F9F2F3",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    // color: '#A8000B !important',
    color: "#000 !important",
  },
  "& .MuiInputLabel-root.Mui-error": {
    // color: '#A8000B !important',
    color: "#000 !important",
  },
  "& .MuiFormLabel-root.Mui-error": {
    // color: '#A8000B !important', // Color de la etiqueta en caso de error
    color: "#000 !important",
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    backgroundColor: "#F9F2F3 !important",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B !important",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input": {
    backgroundColor: "transparent !important", // Fondo del texto transparente
  },
}));

const STYLE_STACK_FIELD = {
  width: "50%",
  mt: 1,
  alignItems: "flex-start",
  justifyContent: "space-around",
  flexDirection: "column",
};
const STYLE_TAG_FIELD = {
  paddingY: 1,
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "140%",
};

export const TicketNewView = (props) => {
  const theme = useTheme();
  const localStyles = useStyles(theme);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState,
    register,
    getValues,
    setError,
    clearErrors,
  } = useForm({ mode: "onBlur" });
  const formMethods = useForm();
  const dispatch = useDispatch();

  const ticketUpdate = useSelector((state) => state.TicketUpdate);
  const auth = useSelector((state) => state.AuthenticationReferer);

  const [fileB64, setFileB64] = useState(null);
  const [docName, setDocName] = useState(null);

  const [continueAct, setContinueAct] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);

  useEffect(() => {
    setCurrentDate(getCurrentDate());
  }, []);

  function getCurrentDate() {
    const fecha = new Date();
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const año = fecha.getFullYear();
    const horas = fecha.getHours().toString().padStart(2, "0");
    const minutos = fecha.getMinutes().toString().padStart(2, "0");
    const segundos = fecha.getSeconds().toString().padStart(2, "0");

    return `${dia}/${mes}/${año} ${horas}:${minutos}:${segundos}`;
  }

  const fields = [
    {
      label: "Fecha y hora de alta",
      type: "text",
      placeHolder: "",
      value: `${getCurrentDate()} hrs`,
      disabled: true,
      required: false,
    },
    {
      label: "Nombre de responsable de alta",
      type: "text",
      placeHolder: "",
      value: `${auth.items?.UserLoginData?.Name} ${auth.items?.UserLoginData?.FatherLastName}`,
      disabled: true,
      required: false,
    },
    {
      label: "Observaciones",
      type: "textArea",
      placeHolder: "",
      value: "",
      disabled: false,
      required: false,
    },
    {
      label: "Incluir documento",
      type: "upload",
      placeHolder: "",
      value: "",
      disabled: false,
      required: false,
    },
    {
      label: "Tipo de seguimiento",
      type: "combo",
      placeHolder: "",
      value: "",
      disabled: false,
      required: true,
    },
    {
      label: "Comentarios HSBC",
      type: "textArea",
      placeHolder: "",
      value: "",
      disabled: false,
      required: true,
    },
    {
      label: "Clasificación de movimiento",
      type: "combo",
      placeHolder: "",
      value: "",
      disabled: false,
      required: true,
    },
    {
      label: "Fecha estimada de solución",
      type: "date",
      placeHolder: "",
      value: "",
      disabled: false,
      required: true,
    },
    {
      label: "Tipo de solicitud",
      type: "combo",
      placeHolder: "",
      value: "",
      disabled: false,
      required: true,
    },
  ];

  const seguimientoList = [
    { Name: "Llamada de cliente", id: "Llamada de cliente" },
    {
      Name: "Seguimiento de área de dictamen",
      id: "Seguimiento de área de dictamen",
    },
    {
      Name: "Seguimiento de área de dictamen Howden/IKE",
      id: "Seguimiento de área de dictamen Howden/IKE",
    },
    { Name: "Envío de dictamen a HSBC", id: "Envío de dictamen a HSBC" },
  ];

  const clasificacionList = [
    { Name: "Seguimiento", id: "Seguimiento" },
    { Name: "Concluido", id: "Concluido" },
  ];

  const solicitudList = [
    { Name: "Dudas en documentación", id: "Dudas en documentación" },
    { Name: "Dudas en proceso", id: "Dudas en proceso" },
    { Name: "Seguimiento a pago", id: "Seguimiento a pago" },
    { Name: "Queja o queja condusef", id: "Queja o queja condusef" },
  ];

  const setEtapa = () => {
    let current = props?.statusData[0];
    for (let i = 0; i < props?.statusData?.length; i++) {
      const currentStage = props?.statusData[i]?.EstatusProcesos?.some(
        (step) => step.Estatus === "Aceptado"
      );
      if (currentStage == false) {
        current = i > 0 ? props?.statusData[i - 1] : 0;
        break;
      }
    }

    return current.GroupName;
  };

  useEffect(() => {
    if (ticketUpdate.items != undefined && continueAct) {
      props.onClickReturn();
    }
  }, [ticketUpdate]);

  const optionsList = (field) => {
    switch (field) {
      case "Tipo de seguimiento":
        return seguimientoList;
      case "Clasificación de movimiento":
        return clasificacionList;
      case "Tipo de solicitud":
        return solicitudList;
      default:
        return [];
    }
  };

  const handleFileChange = (file, fileB64Response, extension) => {
    if (fileB64Response == undefined || fileB64Response === null) {
      return;
    } else {
      setFileB64(fileB64Response);
      !!file && !!file.name && setDocName(file.name);
    }
  };

  const handleRemoveDoc = () => {
    setFileB64(null);
  };

  const onSubmit = (data) => {
    const newDate = currentDate;
    const etapa = setEtapa();
    const objToSend = {
      Id: 0,
      File_Id: props?.folio, //Folio
      Fecha_Hora_Alta: newDate,
      Responsable: `${auth.items?.UserLoginData?.Name} ${auth.items?.UserLoginData?.FatherLastName}`,
      Observaciones: data["Observaciones"],
      NombreDocumento: docName,
      Documento: fileB64,
      TipoSeguimiento: data["Tipo de seguimiento"],
      Comentarios: data["Comentarios HSBC"],
      ClasificacionMovimiento: data["Clasificación de movimiento"],
      FechaEstimadaSolucion: data["Fecha estimada de solución"],
      TipoSolicitud: data["Tipo de solicitud"],
      Etapa: etapa,
    };
    console.log("obj to send ticket: ", objToSend, formState.errors);
    setContinueAct(true);
    dispatch(ticketActions.TicketUpdate(objToSend));
  };

  return (
    <div>
      <div className={localStyles.scroll}>
        <FormProvider {...formMethods}>
          <Stack
            display={"flex"}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"start"}
            width={"100%"}
          >
            <GenericHeaderActions
              LabelTitle={`Seguimiento Folio ${props?.folio}`}
              ConfirmButtonText={"Cancelar"}
              onClickConfirm={props.onClickReturn} //Cancelar
              ConfirmButtonVariant={"outlined"}
              CancelButtonText={"Enviar"}
              onClickCancel={handleSubmit(onSubmit)} //Enviar
              CancelButtonVariant={"contained"}
              CancelButtonDisabled={Object.keys(formState.errors).length !== 0}
            />
          </Stack>

          {ticketUpdate?.loading ? (
            <div className={localStyles.spinSection}>
              <CustomLoadingOverlay
                active={ticketUpdate?.loading}
                text="Espera un momento por favor..."
              />
            </div>
          ) : (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              direction={"column"}
              width={"100%"}
              sx={{ mt: 4, pb: 3 }}
            >
              {fields.map((field, index) => {
                const error = !!formState.errors[`${field.label}`];
                const helperText = formState.errors[`${field.label}`]?.message;

                switch (field.type) {
                  case "combo":
                    return (
                      <Controller
                        control={control}
                        name={field.label}
                        defaultValue={field.value}
                        // rules={validations}
                        render={({
                          field: { ref, onChange, ...restFields },
                        }) => (
                          <Box sx={{ width: "50%", mt: 1 }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "140%",
                                marginTop: "5px",
                                marginBottom: "5px",
                                color: !!formState.errors[`${field.label}`]
                                  ? "#DB0011"
                                  : "inherit",
                              }}
                            >
                              {field.required
                                ? `${field.label}*`
                                : `${field.label}`}
                            </Box>
                            <TextField
                              {...restFields}
                              {...register(field.label, {
                                required: field.required ? "Requerido" : "",
                              })}
                              error={error}
                              helperText={helperText}
                              // label={field.label}
                              name={field.label}
                              fullWidth
                              select
                              inputRef={ref}
                              disabled={field.disabled}
                              placeholder={field.placeHolder}
                              required={field.required}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              inputProps={{ displayEmpty: true, shrink: true }}
                            >
                              {optionsList(field.label).map((field, i) => (
                                <MenuItem
                                  key={`${field.Description}${i}`}
                                  value={field?.Name}
                                  placeholder="Seleccione"
                                  selected
                                >
                                  {field?.Name?.toUpperCase()}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                        )}
                      />
                    );
                  case "date":
                    return (
                      <Controller
                        control={control}
                        name={field.label}
                        // rules={validations}
                        render={({
                          field: { ref, onChange, ...restFields },
                        }) => {
                          return (
                            <Box sx={{ width: "50%", mt: 1 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "16px",
                                  lineHeight: "140%",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                  color: !!formState.errors[`${field.label}`]
                                    ? "#DB0011"
                                    : "inherit",
                                }}
                              >
                                {field.required
                                  ? `${field.label}*`
                                  : `${field.label}`}
                              </Box>
                              <ThemeProvider theme={themeDatePicker}>
                                <DatePicker
                                  minDate={new Date()}
                                  slotProps={{
                                    day: {
                                      sx: {
                                        "&.MuiPickersDay-root": {
                                          borderRadius: "0px",
                                          "&:hover": {
                                            borderRadius: "0px",
                                            backgroundColor: "#D3D3D3 ",
                                          },
                                        },
                                        "&.MuiPickersDay-root.Mui-selected": {
                                          backgroundColor: "#000",
                                          borderRadius: "0px",
                                        },
                                        "&.MuiPickersDay-root.Mui-selected:hover":
                                          {
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: "0px",
                                          },
                                      },
                                    },
                                    desktopPaper: {
                                      sx: {
                                        ".MuiPickersYear-yearButton": {
                                          "&:hover": { borderRadius: "0px" },
                                        }, //MuiPickersDay-root MuiPickersDay-dayWithMargin
                                        ".MuiPickersYear-yearButton.Mui-selected":
                                          {
                                            backgroundColor: "#000",
                                            borderRadius: "0px",
                                            "&:hover": {
                                              borderRadius: "0px",
                                              backgroundColor: "#D3D3D3",
                                            },
                                          },
                                        ".MuiPickersYear-yearButton.Mui-selected:hover":
                                          {
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: "0px",
                                            color: "black",
                                          },
                                      },
                                    },
                                    field: {
                                      sx: {
                                        color: "blue",
                                      },
                                    },
                                    toolbar: {
                                      toolbarPlaceholder: "",
                                    },
                                  }}
                                  sx={{
                                    width: "100%",
                                    "& fieldset": {
                                      borderColor: error
                                        ? "#A8000B !important"
                                        : "none",
                                      marginTop: "5px",
                                    },
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "black", // Color del texto al seleccionar el TextField
                                      },
                                  }}
                                  inputFormat="dd/MM/yyyy"
                                  inputProps={{
                                    placeholder: field.placeHolder,
                                  }}
                                  components={{
                                    OpenPickerIcon: CalendarTodayOutlinedIcon,
                                  }}
                                  {...restFields}
                                  onChange={(e) => {
                                    const d = new Date(e);
                                    const actualDate = new Date().setHours(
                                      0,
                                      0,
                                      0,
                                      0
                                    );
                                    if (isNaN(d.getTime())) {
                                      setError(
                                        `${field.label}`,
                                        {
                                          type: "validate",
                                          message:
                                            "La fecha ingresada no es válida",
                                        },
                                        { shouldFocus: true }
                                      );
                                    } else if (d < actualDate) {
                                      setError(
                                        `${field.label}`,
                                        {
                                          type: "validate",
                                          message:
                                            "La fecha seleccionada no debe ser menor",
                                        },
                                        { shouldFocus: true }
                                      );
                                    } else {
                                      clearErrors(`${field.label}`);
                                      setValue(`${field.label}`, e, {
                                        shouldValidate: true,
                                      });
                                    }
                                  }}
                                  inputRef={ref}
                                  disabled={field.disabled}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        {...params}
                                        error={error}
                                        helperText={helperText}
                                        fullWidth
                                      />
                                    );
                                  }}
                                />
                              </ThemeProvider>
                              {error && (
                                <p
                                  style={{ paddingLeft: "10px", color: "red" }}
                                >
                                  {helperText}
                                </p>
                              )}
                            </Box>
                          );
                        }}
                      />
                    );
                  case "upload":
                    return (
                      <>
                        {!!fileB64 ? (
                          <Box sx={{ width: "50%", mt: 2, minHeight: "100px" }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "140%",
                                marginTop: "5px",
                                marginBottom: "5px",
                                color: !!formState.errors[`${field.label}`]
                                  ? "#DB0011"
                                  : "inherit",
                              }}
                            >
                              {field.required
                                ? `${field.label}*`
                                : `${field.label}`}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignContent: "flex-start",
                                alignItems: "flex-start",
                                pb: 3,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  border: "1px solid #D2D2D2",
                                  borderRadius: "7px",
                                  px: 5,
                                  py: 3,
                                  pb: 1,
                                  maxWidth: "180px",
                                  boxShadow: 5,
                                  position: "relative",
                                  userSelect: "none",
                                }}
                              >
                                <IconButton
                                  onClick={handleRemoveDoc}
                                  sx={{
                                    position: "absolute",
                                    top: -15,
                                    right: -15,
                                    zIndex: 1,
                                  }}
                                >
                                  <CancelRoundedIcon
                                    color="error"
                                    style={{ fontSize: "1.7rem" }}
                                  />
                                </IconButton>
                                <UseImage
                                  src={ticketDocument}
                                  type="img"
                                  style={{ width: "80px" }}
                                />
                                <Box height={"50px"} width={"100px"}>
                                  <Typography
                                    height={"50px"}
                                    width={"100px"}
                                    sx={{
                                      fontSize: "12px !important",
                                      pt: 2,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {docName}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ width: "50%", mt: 2, minHeight: "10em" }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "140%",
                                marginTop: "5px",
                                marginBottom: "5px",
                                color: !!formState.errors[`${field.label}`]
                                  ? "#DB0011"
                                  : "inherit",
                              }}
                            >
                              {!!field.label ? field.label : ""}
                            </Box>
                            <UploadDcumentsControl
                              initLoadChange={true}
                              Title={
                                <Typography variant="subtitle1">
                                  Arrastre su documento con extension{" "}
                                  <b>.png, .jpg, archivo RAR o .pdf</b> o
                                </Typography>
                              }
                              onChange={(file, fileBase64, extensionChange) => {
                                console.log(
                                  "File uploaded: ",
                                  file,
                                  fileBase64,
                                  extensionChange
                                );
                                handleFileChange(
                                  file,
                                  fileBase64,
                                  extensionChange
                                );
                              }}
                              onClear={fileB64 == null ? true : false}
                              clickOnBox={true}
                              HeightBox={"20vh"}
                              ExtentionAccepted={
                                "application/pdf,application/msword"
                              }
                            />
                          </Box>
                        )}
                      </>
                    );
                  case "text":
                  default:
                    return (
                      <Controller
                        control={control}
                        name={field.label}
                        // rules={validations}
                        // defaultValue={field.value}
                        render={({
                          field: { ref, onChange, ...restFields },
                        }) => (
                          <Stack sx={STYLE_STACK_FIELD}>
                            <Typography
                              sx={{
                                ...STYLE_TAG_FIELD,
                                ...(!!error && { color: "red" }),
                              }}
                            >
                              {field.required
                                ? `${field.label}*`
                                : `${field.label}`}
                            </Typography>
                            <TextField
                              {...restFields}
                              {...register(field.label, {
                                required: field.required ? "Requerido" : "",
                                setValueAs: (value) => value?.toUpperCase(),
                              })}
                              type={"text"}
                              defaultValue={field.value}
                              fullWidth
                              name={field.label}
                              inputRef={ref}
                              multiline={true}
                              minRows={field.type == "textArea" ? 4 : 1}
                              // onKeyUp={onEnter}
                              placeholder={field.placeHolder}
                              disabled={field.disabled}
                              required={field.required}
                              error={error}
                              helperText={helperText}
                              sx={
                                field.disabled
                                  ? { backgroundColor: "#EEEEEE" }
                                  : {}
                              }
                            />
                          </Stack>
                        )}
                      />
                    );
                }
              })}
            </Stack>
          )}
        </FormProvider>
      </div>
    </div>
  );
};
