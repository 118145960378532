import { notificationConstants } from "../constants/index.js";

export const NewPin = (state = {}, action) => {
  switch (action.type) {
    case notificationConstants.NEW_PIN_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    //break;
    case notificationConstants.NEW_PIN_SUCCESS:
      return {
        loading: false,
        items: action.newPin.Body,
        error: undefined,
      };
    //break;
    case notificationConstants.NEW_PIN_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    //break;
    default:
      return state;
  }
};

export const GetNotificationsUser = (state = {}, action) => {
  switch (action.type) {
    case notificationConstants.GET_NOTIFICATIONS_USER_REQUEST:
      return {
        ...state,//Persist items on reload
        loading: true,
        // items: undefined,
        error: undefined,
      };
    case notificationConstants.GET_NOTIFICATIONS_USER_SUCCESS:
      return {
        loading: false,
        items: action.notifications.Body,
        error: undefined,
      };
    case notificationConstants.GET_NOTIFICATIONS_USER_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    default:
      return state;
  }
};

export const GetMetadataFill = (state = {}, action) => {
  switch (action.type) {
    case notificationConstants.GET_METADATA_FILL_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    case notificationConstants.GET_METADATA_FILL_SUCCESS:
      return {
        loading: false,
        items: action.metadataFill.Body,
        error: undefined,
      };
    case notificationConstants.GET_METADATA_FILL_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    default:
      return state;
  }
};

export const GetNotificationsCancelables = (state = {}, action) => {
  switch (action.type) {
    case notificationConstants.GET_NOTIFICATIONS_CANCELABLES_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    case notificationConstants.GET_NOTIFICATIONS_CANCELABLES_SUCCESS:
      return {
        loading: false,
        items: action.cancelables?.Body,
        error: undefined,
      };
    case notificationConstants.GET_NOTIFICATIONS_CANCELABLES_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    default:
      return state;
  }
};

export const UpdateCancelFilesNotifications = (state = {}, action) => {
  switch (action.type) {
    case notificationConstants.GET_NOTIFICATIONS_SETCANCEL_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    case notificationConstants.GET_NOTIFICATIONS_SETCANCEL_SUCCESS:
      return {
        loading: false,
        items: action.setCancelables?.Body,
        error: undefined,
      };
    case notificationConstants.GET_NOTIFICATIONS_SETCANCEL_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    default:
      return state;
  }
};
