import { authConstants } from '../constants/index.js'

export const Authentication = (state = {}, action) => {

  switch (action.type) {
    case authConstants.AUTH_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined
      }//Mostrar gif loader mientras ejecuta request
    //break;
    case authConstants.AUTH_SUCCESS:
      return {
        loading: false,
        items: action.authenticationData.Body,//Obtener respuesta correcta del API        
        usuario: action.authenticationData.Body.UserLoginData.Username,
        error: undefined
      }
    //break;
    case authConstants.AUTH_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error//Mostrar error del API
      }
    default:
      return state//Retornar state vacío o actual
  }
}
//Siguiente paso => Volver a screens con respuesta

export const AuthenticationReferer = (state = {}, action) => {

  switch (action.type) {
    case authConstants.AUTHREFERER_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined
      }//Mostrar gif loader mientras ejecuta request
    //break;
    case authConstants.AUTHREFERER_SUCCESS:
      return {
        loading: false,
        items: action.authenticationRefererData.Body,//Obtener respuesta correcta del API        
        usuario: action.authenticationRefererData.Body.UserLoginData.Username,
        error: undefined
      }
    //break;
    case authConstants.AUTHREFERER_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error//Mostrar error del API
      }
    default:
      return state//Retornar state vacío o actual
  }
}

export const GetClientIPsWithRefer = (state = {}, action) => {
  switch (action.type) {
    case authConstants.CLIENTIPS_REQUEST:
      return { loading: true, items: undefined, error: undefined }

    case authConstants.CLIENTIPS_SUCCESS:
      return {
        loading: false,
        items: action.clientIPs.Body,
        error: undefined
      }

    case authConstants.CLIENTIPS_FAILURE:
      return { loading: false, items: undefined, error: action.error }

    default:
      return state
  }
}