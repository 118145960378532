import React, { useEffect, useState } from 'react';
import { useStyles } from './TabsStyle'
import { mainStyles } from '../../theme/mainStyles'
import { UseImage } from '../index';
import { useDispatch, useSelector } from "react-redux";

import ine from '../../assets/id.svg'
import passport from '../../assets/passport.svg'
import curp from '../../assets/CURP.svg'
import comprobante from '../../assets/ComprobanteGen.svg'
import consentimiento from '../../assets/consentimiento.svg'
import contrato from '../../assets/contrato.svg'
import privacidad from '../../assets/privacidad.svg'
import ingresos from '../../assets/ingresos.svg'
import nom from '../../assets/NOM.svg'
import docGenerico from '../../assets/docGenerico.svg'
import NoDocs from '../../assets/NoDocs.png'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import loadDoc from "../../assets/loadDoc.svg";
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { catalogActions, validationActions, workFlowActions } from '../../store/actions';

import {
  Typography,
  Card,
  CardActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box, useTheme
} from '@mui/material';

import {
  ModalVisual,
  ActorMVisual,
} from '../modals';
import { CustomAlert } from "../CustomAlert";
import { useParams } from 'react-router-dom';

export function Documentation({ cards, val, docsA, cActor, refresh, bioData, IsValidate, FinaliceValidaction, fileId, activaFinalizaValidacion, Product }) {
  const theme = useTheme()
  const localStyles = useStyles(theme)
  const dispatch = useDispatch()
  const cardStyle = mainStyles.cards()
  const noInfo = mainStyles.noDataSections()
  const catalogDecline = useSelector((state) => state.GetCatalog);
  const infoFile = useSelector(state => state.FileInfo)

  const fileData = infoFile?.items

  const [openFinaliceValidation, setOpenFinaliceValidation] = useState(false);
  const [openFinaliceValidationAdjudicator, setOpenFinaliceValidationAdjudicator] = useState(false);
  const [documentsPendientesValidacion, setDocumentsPendientesValidacion] = useState(false);
  const [documentsRechazadosValidacion, setDocumentsRechazadosValidacion] = useState(false);
  const [optionsList, setOptionsList] = useState([]/* [{ Option: 'example 1', Value: '1' }, { Option: 'example 2', Value: '2' }] */)
  const [isAdjudicator, setIsAdjudicator] = useState(null)
  const [validateAdjudicator, setValidateAdjudicator] = useState(null)
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (cards?.length > 0) {
      setDocumentsPendientesValidacion(null)
      setDocumentsRechazadosValidacion(null)

      let rechazados = cards.filter(item => item.documentos?.filter(itemDoc => itemDoc?.validacion == false).length > 0)
      console.log("***********Documentation cardsArray rechazados***************: ", rechazados)

      /* if (docx.DocumentTypes.length > 1) {
        return (docx.DocumentTypes?.some((docType) => (docType?.Document?.Status === "Rechazado"))
          || (docx?.Required && docx.DocumentTypes?.some((docType) => !!docType?.Document) ? false : true))
      } else {
        return docx.DocumentTypes?.some((docType) => (docType?.Document?.Status === "Rechazado" || !docType.Document) && docx?.Required === true)
      } */

      let pendientes = cards.filter(item => item.documentos?.filter(itemDoc => itemDoc?.validacion == null).length > 0)
      console.log("***********Documentation cards pendientes***************: ", cards, pendientes)

      setDocumentsRechazadosValidacion(rechazados?.length > 0 ? true : false)
      setDocumentsPendientesValidacion(pendientes?.length > 0 ? true : false)
    }
  }, [cards])

  useEffect(() => {
    const validateByFile = fileData?.Stage.Properties?.find(prop => prop.Name == 'ValidateByFile')?.Value == '1'
    setIsAdjudicator(validateByFile)
    if (Object.keys(cActor).length === 0) {
      dispatch(catalogActions.GetCatalog(fileData?.Product === 'Ayuda por Hospitalización' ? 'OpcionRechazo-Seguro para Apoyo por Hospitalización' : 'OpcionRechazo-Vida Individual T5'))
    }
  }, [])

  useEffect(() => {
    if (catalogDecline.items !== undefined && Object.keys(cActor).length === 0) {
      const newList = catalogDecline.items?.map((item) => {
        return { ...item, Option: item.Clave }
      })
      console.log('newList rechazo: ', newList)
      setOptionsList(newList)
    }
  }, [catalogDecline])

  // const itIncludes = (oneString) => {
  //   if (oneString.includes("INE / IFE")) {
  //     return ine
  //   } else if (
  //     oneString.includes("PASAPORTE") ||
  //     oneString.includes("pasaporte") ||
  //     oneString.includes("Pasaporte")
  //   ) {
  //     return passport
  //   } else if (
  //     oneString.includes("curp") ||
  //     oneString.includes("CURP")
  //   ) {
  //     return curp
  //   } else if (
  //     (oneString.includes("consentimiento") ||
  //       oneString.includes("Consentimiento")) &&
  //     (oneString.includes("INE") ||
  //       oneString.includes("IFE"))) {
  //     return consentimiento
  //   } else if (
  //     oneString.includes("contrato")
  //     || oneString.includes("Contrato")) {
  //     return contrato
  //   } else if (
  //     oneString.includes("privacidad") ||
  //     oneString.includes("Privacidad")) {
  //     return privacidad
  //   } else if (
  //     oneString.includes("nom") ||
  //     oneString.includes("NOM") ||
  //     oneString.includes("norma") ||
  //     oneString.includes("Nom") ||
  //     oneString.includes("Expediente") ||
  //     oneString.includes("expediente")
  //   ) {
  //     return nom
  //   } else if (
  //     oneString.includes("TELMEX") ||
  //     oneString.includes("Telmex") ||
  //     oneString.includes("CFE") ||
  //     oneString.includes("cfe") ||
  //     oneString.includes("domicilio") ||
  //     oneString.includes("Domicilio")) {
  //     return comprobante
  //   } else if (
  //     oneString.includes("Ingresos") ||
  //     oneString.includes("INGRESOS") ||
  //     oneString.includes("ingresos")) {
  //     return ingresos
  //   } else {
  //     return docGenerico
  //   }
  // }

  // const HtmlTooltip = withStyles((theme) => ({
  //   tooltip: {
  //     backgroundColor: theme.palette.secondary.light,
  //     color: 'rgba(0, 0, 0, 0.87)',
  //     maxWidth: 220,
  //     fontSize: theme.typography.pxToRem(12),
  //     border: '1px solid #dadde9',
  //   },
  // }))(Tooltip);

  const handleAdjudicatorValidation = (isValidated) => {
    if (isValidated) {
      setValidateAdjudicator(true)
      setOpenFinaliceValidationAdjudicator(true)
    } else {
      setValidateAdjudicator(false)
      setOpenRejectDialog(true)
    }
  }

  return (
    <div>

      <div>
        {console.log("***********Documentation cards***************: ", cards)}
        {cards?.length > 0 ? cards?.map((card) => {
          if (card.grupo !== '.CER' && card.grupo !== '.KEY') {
            return <Accordion
              classes={{
                root: localStyles.accordion
              }}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={localStyles.textGroup}>{card.grupo}
                </Typography>
                <Box sx={{
                  padding: '1px 0.5em;',
                }}>
                  {card.documentos?.filter(item => item?.validacion === undefined ||
                    item?.validacion === null)?.length ? (
                    <UseImage
                      src={loadDoc}
                      type="img"
                      className={cardStyle.loadDocIcon}
                    />
                  ) : card.documentos?.filter(item => item?.validacion == true)?.length ? (
                    <CheckCircleIcon style={{ color: "#00847F" }} />
                  ) : card.documentos?.filter(item => item?.validacion == false)?.length ? (
                    <CancelIcon style={{ color: "red" }} />
                  ) : (
                    ""
                  )}
                  {/* {getStatusIcon(card)} */}
                </Box>
              </AccordionSummary>
              <AccordionDetails className={localStyles.accordionDetail}>
                {card.documentos?.map((doc) => {
                  console.log("doc-----------: ", doc)
                  if (doc.nombre?.includes('Firma autógrafa')
                    || doc.nombre?.includes('Firma autografa')
                    || doc.extension === '.xml'
                    || doc.extension === '.zip'
                    || doc.extension === '.wsq') {
                    return ''
                  } else if (Object.keys(cActor).length === 0) {
                    return (
                      <div className={localStyles.iconSection}>
                        {console.log("ITEMS DOCS*******************1*: ", cActor /* IsValidate, doc.validacion, doc */)}
                        <ModalVisual docName={doc.nombre} idDoc={doc.id} metaData={doc.metaData} optionsList={optionsList} disabledConfirmAlert={true}
                          docVal={doc.validacion} extDocVal={doc.docExtVal} validation={val}
                          docsApproved={docsA} refresh={refresh}
                          statusVideo={bioData.Face?.Selfie?.ComparisonVsVideo?.StatusVideo} IsValidate={IsValidate} fileId={fileId} />
                      </div>
                    )
                  } else if (Object.keys(cActor).length !== 0) {
                    return (
                      <div className={localStyles.iconSection}>
                        {console.log("ITEMS DOCS*******************2*: ", cActor/* IsValidate, doc.validacion, doc */)}
                        <ActorMVisual docName={doc.nombre} idDoc={doc.id} metaData={doc.metaData}
                          docVal={doc.validacion} extDocVal={doc.docExtVal} validation={val}
                          docsApproved={docsA} actor={cActor} refresh={refresh} IsValidate={IsValidate} fileId={fileId} />
                      </div>
                    )
                  }
                })}

              </AccordionDetails>
            </Accordion>
          } else {
            return ''
          }
        }) :
          <div className={noInfo.noData}>
            <UseImage src={NoDocs} type='img' className={localStyles.imageNotFound} />
            <Typography variant="h6" className={noInfo.h6}>Sin documentación</Typography>
          </div>
        }

        {/* {console.log("***---documentsPendientesValidacion,documentsRechazadosValidacion---**** ", documentsPendientesValidacion, documentsRechazadosValidacion)} */}
        {isAdjudicator /* && val.ShowSelectApproval === true */ && IsValidate === true ?
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1em',
            marginBottom: '0.5em',
          }}>
            <Button
              variant="contained"
              sx={{ height: 45, width: 300, mr: 1 }}
              onClick={() => { handleAdjudicatorValidation(true) }} >
              Aprobar validación
            </Button>
            <Button
              variant="outlined"
              sx={{ height: 45, width: 300, ml: 1, color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' } }}
              onClick={() => { handleAdjudicatorValidation(false) }} >
              Rechazar validación
            </Button>
          </Box> : null
        }
        {documentsPendientesValidacion == false && activaFinalizaValidacion == true && !isAdjudicator ?
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1em',
            marginBottom: '0.5em',
          }}>
            <Button
              variant="contained"
              sx={{ height: 45, width: 300 }}
              onClick={() => { setOpenFinaliceValidation(true) }} >
              Finalizar validación
            </Button>
          </Box>
          : null}
      </div>
      <CustomAlert
        closeAction={() => {
          setOpenFinaliceValidation(false);
        }}
        open={openFinaliceValidation}
        title="Finalizar validación"
        body="¿Estás seguro de finalizar la validación?"
        leftButtonText="Cancelar"
        leftButtonAction={() => {
          setOpenFinaliceValidation(false);
        }}
        rightButtonText="Aceptar"
        rightButtonAction={() => {
          FinaliceValidaction({ Comments: '', IsReject: documentsRechazadosValidacion })
          setOpenFinaliceValidation(false);
          // refresh()
        }}
      />
      <CustomAlert
        closeAction={() => {
          setOpenFinaliceValidationAdjudicator(false);
        }}
        open={openFinaliceValidationAdjudicator}
        title="Finalizar validación"
        body={validateAdjudicator ? "¿Estás seguro de aprobar la documentación?" : "¿Estás seguro de rechazar la documentación?"}
        leftButtonText="Cancelar"
        leftButtonAction={() => {
          setOpenFinaliceValidationAdjudicator(false);
        }}
        rightButtonText="Aceptar"
        rightButtonAction={() => {
          FinaliceValidaction({ Comments: comment, IsReject: !validateAdjudicator })
          setOpenFinaliceValidationAdjudicator(false);
          // refresh()
        }}
      />

      <CustomAlert
        closeAction={() => {
          setOpenRejectDialog(false)
        }}
        open={openRejectDialog}
        title="Rechazar Validación"
        body="Específica el motivo de rechazo"
        leftButtonText="Cancelar"
        leftButtonAction={() => {
          setOpenRejectDialog(false);
          setComment("");
        }}
        rightButtonText="Aceptar"
        list={optionsList}
        valueComment={comment}
        commentPlaceHolder="Motivo de rechazo"
        setValueComment={setComment}
        disabledConfirm={true}
        rightButtonAction={() => {
          setOpenRejectDialog(false);
          FinaliceValidaction({ Comments: comment, IsReject: !validateAdjudicator })
        }}
      />
    </div>

  )
}


const getStatusIcon = (card, IsIcon = false) => {
  const cardStyle = mainStyles.cards()

  if (card.documentos?.filter(item => item?.validacion === null)?.length > 0) {
    return IsIcon ? <UseImage
      src={loadDoc}
      type="img"
      className={cardStyle.loadDocIcon}
    /> : "Pendiente"
  }
  else if (card.documentos?.filter(item => item?.validacion == true)?.length > 0) {
    return IsIcon ? <CheckCircleIcon style={{ color: "#00847F" }} /> : "Aprobado"
  } else if (card.documentos?.filter(item => item?.validacion == false)?.length > 0) {
    return IsIcon ? <CancelIcon style={{ color: "red" }} /> : "Rechazado"
  } else {
    return ""
  }
}