import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./TableStyle";

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  styled,
  useTheme,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { paths, routes } from "../../utils";

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableMain} !important`,
  color: `${theme.palette.primary.tableText} !important`,
}));

const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
  },
}));

export function MainTable({
  data,
  PageSize,
  CurrentPage,
  TotalRecords,
  SetCurrentPage,
}) {
  const theme = useTheme();
  const history = useNavigate();
  let path = window.location.pathname;
  const newData = data?.map((col) => {
    return {
      Folio: col.Folio,
      Solicitante: col.FullName,
      Curp: col?.Metadata?.find((dato) => dato.Name == "CURP")?.Value,
      NumeroPoliza: col?.Metadata?.find((dato) => dato.Name == "NumeroPoliza")
        ?.Value,
      Producto: col?.Metadata?.find((dato) => dato.Name == "Producto")?.Value,
      CoberturaReclamada: col?.Metadata?.find(
        (dato) => dato.Name == "CoberturaReclamada"
      )?.Value,
      Correo: col.Email,
      Estatus: col.Stage.Description,
      IdFolio: col.Id,
      TipoProducto: col.WorkFlowName,
      FechaAsignacion: col?.Metadata?.find(
        (dato) => dato.Name == "Fecha de asignación"
      )?.Value,
      RolAsignado: col?.Metadata?.find((dato) => dato.Name == "Rol asignado")
        ?.Value,
      UsuarioAsignado: col?.Metadata?.find(
        (dato) => dato.Name == "Usuario asignado"
      )?.Value,
    };
  });

  const columns = [
    { id: "folio", label: "Folio", minWidth: 100 },
    { id: "solicitante", label: "Nombre", minWidth: 170 },
    { id: "curp", label: "CURP", minWidth: 200 },
    { id: "numPoliza", label: "Número de póliza", minWidth: 150 },
    { id: "producto", label: "Producto", minWidth: 170 },
    { id: "cobeReclamada", label: "Cobertura Reclamada", minWidth: 170 },
    { id: "status", label: "Estatus", minWidth: 170 },
    { id: "dateAssign", label: "Fecha de Asignación", minWidth: 150 },
    { id: "rolAssign", label: "Rol Asignado", minWidth: 150 },
    { id: "userAssign", label: "Usuario Asignado", minWidth: 150 },
  ];

  const createData = (
    folio,
    solicitante,
    curp,
    numPoliza,
    producto,
    cobeReclamada,
    perfil,
    status,
    idOfFolio,
    dateAssign,
    rolAssign,
    userAssign
  ) => {
    return {
      folio,
      solicitante,
      curp,
      numPoliza,
      producto,
      cobeReclamada,
      perfil,
      status,
      idOfFolio,
      dateAssign,
      rolAssign,
      userAssign,
    };
  };

  const rows = newData?.map((r) => {
    return createData(
      r.Folio,
      r.Solicitante,
      r.Curp,
      r.NumeroPoliza,
      r.Producto,
      r.CoberturaReclamada,
      r.Perfil,
      r.Estatus,
      r.IdFolio,
      r.FechaAsignacion,
      r.RolAsignado,
      r.UsuarioAsignado
    );
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PageSize);

  const handleChangePage = (newPage) => {
    SetCurrentPage(newPage);
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [orderDirection, setOrderDirection] = useState("");
  const [valueToOrderBy, setValueToOrderBy] = useState("");

  const handlerSort = (event, property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property);
  };

  return (
    <div style={{ margin: "0 1em 0 1em", height: "100%" }}>
      <TableContainer
        style={{ overflow: "auto", height: "calc(100vh - 300px)" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow key={0}>
              {columns.map((column) => (
                <TableCellHead
                  key={column.id}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: "200px !important",
                  }}
                  onClick={createSortHandler(column.label)}
                >
                  {column.label}
                </TableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRowCustom
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    onClick={() => {
                      if (path === paths.search) {
                        history(`${routes.busqueda}/${row.idOfFolio}`);
                      } else if (
                        path === paths.homeComponentSingle ||
                        path === paths.homeComponent
                      ) {
                        history(`${routes.asignacion}/${row.idOfFolio}`);
                      }
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{
                            maxWidth: column?.minWidth,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRowCustom>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationCustom
        CurrentPage={CurrentPage}
        TotalRecords={TotalRecords}
        PageSize={PageSize}
        OnChangePage={(pg) => {
          handleChangePage(pg);
        }}
      />
    </div>
  );
}

const PaginationCustom = ({
  CurrentPage,
  TotalRecords,
  PageSize,
  OnChangePage,
}) => {
  const reachCurrentRecords =
    CurrentPage * PageSize > TotalRecords
      ? TotalRecords
      : CurrentPage * PageSize;
  const startCurrentRecords = CurrentPage * PageSize - PageSize + 1;
  return (
    <Stack
      sx={{
        py: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Box sx={{ px: 1 }}>
        <Typography variant="body2">
          {startCurrentRecords} - {reachCurrentRecords} de {TotalRecords}
        </Typography>
      </Box>
      <Box>
        <IconButton
          disabled={startCurrentRecords == 1}
          onClick={() => {
            OnChangePage(CurrentPage - 1);
          }}
        >
          <ArrowBackIosNewIcon sx={{ fontSize: 15 }} />
        </IconButton>
        <IconButton
          disabled={reachCurrentRecords == TotalRecords}
          onClick={() => {
            OnChangePage(CurrentPage + 1);
          }}
        >
          <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
        </IconButton>
      </Box>
    </Stack>
  );
};
