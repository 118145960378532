import React from 'react'

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow, useTheme
} from '@mui/material';
import { useStyles } from './TabsStyle'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export function RecordInfo({ data, dataValidationFile }) {
  const theme = useTheme()
  const localStyles = useStyles(theme)

  const formatBackDate = (date) => {
    const dateArr = date?.split('/')
    return {
      day: dateArr[0] ?? '',
      month: dateArr[1] ?? '',
      year: dateArr[2] ?? ''
    }
  }

  // console.log("Data****************: ",data)
  return (
    <div>
      {data.map((group) => {
        // console.log("Data****************: ",group)
        if (group.GroupName == 'Validación de Asegurabilidad') {
          const policyStartValidity = group.MetadataList.filter(row => row.MetadataName == 'Inicio de vigencia')
          const policyEndValidity = group.MetadataList.filter(row => row.MetadataName == 'Fin vigencia')

          const hospitalStart = group.MetadataList.filter(row => row.MetadataName == 'FECHA INGRESO AL HOSPITAL')
          const hospitalEnd = group.MetadataList.filter(row => row.MetadataName == 'FECHA EGRESO DEL HOSPITAL')

          const sumPolicyValidity = ''

        }
 
        return (
          <Accordion
            classes={{
              root: localStyles.accordion
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={localStyles.textGroup}>{group.GroupName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableBody>
                    {group.MetadataList.map((row) => {
                      return (
                        !!!row.MetadataValue ? null :
                          <TableRow>
                            <TableCell align="left" style={{
                              borderTop: "solid #A0A1A2 1px",
                              paddingTop: 10,
                              paddingBottom: 10,
                            }} padding="none"
                              width="50%"
                            >{row.MetadataName}</TableCell>
                            <TableCell align="left" style={{
                              borderTop: "solid #A0A1A2 1px",
                              paddingTop: 10,
                              paddingBottom: 10
                            }} padding="none"
                              width="50%"
                            >{row.MetadataValue === 'True'
                              ? <CheckCircleIcon style={{ color: "#00847F" }} />
                              : row.MetadataValue === 'False'
                                ? <CancelIcon style={{ color: "red" }} />
                                : row.MetadataValue === 'Seleccionar'
                                  ? ''
                                  : group.GroupName.includes('facial')
                                    && row.MetadataName.includes('Similitud')
                                    ? `${row.MetadataValue}`
                                    : row.MetadataValue}</TableCell>
                          </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        )
      })}

      {dataValidationFile.map((group) => {
        // console.log("Data****************: ",group)
        return (
          <Accordion
            classes={{
              root: localStyles.accordion
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={localStyles.textGroup}>{group.GroupName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableBody>
                    {group.ValidationList.map((row) => (
                      <TableRow>
                        <TableCell align="left" style={{
                          borderTop: "solid #A0A1A2 1px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }} padding="none"
                          width="40%"
                        >{row.Name}</TableCell>

                        <TableCell align="left" style={{
                          borderTop: "solid #A0A1A2 1px",
                          paddingTop: 10,
                          paddingBottom: 10
                        }} padding="none"
                          width="45%"
                        >{row.Value}</TableCell>

                        <TableCell align="left" style={{
                          borderTop: "solid #A0A1A2 1px",
                          paddingTop: 10,
                          paddingBottom: 10
                        }} padding="none"
                          width="15%"
                        >{row.Result === 'True'
                          ? <CheckCircleIcon style={{ color: "#00847F" }} />
                          : row.Result === 'False'
                            ? <CancelIcon style={{ color: "red" }} />
                            : row.Result === 'true'
                              ? <CheckCircleIcon style={{ color: "#00847F" }} />
                              : row.Result === 'false'
                                ? <CancelIcon style={{ color: "red" }} />
                                : row.Result}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}
