import { constPathRoot } from "./PathPublish";
const { createHash } = require("crypto");

export const paths = {
  home: `${constPathRoot.pathRoot}/HomeComponent`,
  // search: `${constPathRoot.pathRoot}/SearchComponent`,
  report: `${constPathRoot.pathRoot}/DashboardComponent`,
  notice: `${constPathRoot.pathRoot}/NotificationsComponent`,
  folio: "asignacion",
  folioSearch: "busqueda",
  recordatorios: "notifications/",
  userManager: `${constPathRoot.pathRoot}/UserManager`,
  blackList: `${constPathRoot.pathRoot}/BlackListComponent`,
  customers: `${constPathRoot.pathRoot}/CustomersComponent`,
  dependencies: `${constPathRoot.pathRoot}/DependenciesComponent`,
  documents: `${constPathRoot.pathRoot}/DocumentsComponent`,
  employees: `${constPathRoot.pathRoot}/EmployeesComponent`,
  newEmployees: `${constPathRoot.pathRoot}/NewEmployeesComponent`,
  RegistrationCandidates: `${constPathRoot.pathRoot}/Registration`,
  search: `${constPathRoot.pathRoot}/inbox/search`,
  notificationsAlert: `${constPathRoot.pathRoot}/inbox/notificationsAlert`,
  homeComponent: `${constPathRoot.pathRoot}/inbox/homeComponent`,
  homeComponentSingle: `${constPathRoot.pathRoot}/inbox/homeComponent/single`,
  homeComponentMasive: `${constPathRoot.pathRoot}/inbox/homeComponent/masive`,
};

export const routes = {
  home: "/inbox/homeComponent",
  search: "/inbox/search",
  report: "/inbox/dashboards",
  notice: "/NotificationsComponent",
  userManager: "/UserManager",
  blackList: "/BlackListComponent",
  customers: "/CustomersComponent",
  dependencies: "/DependenciesComponent",
  documents: "/DocumentsComponent",
  employees: "/EmployeesComponent",
  newEmployees: "/NewEmployeesComponent",
  RegistrationCandidates: "/Registration",
  busqueda: `/inbox/busqueda`,
  asignacion: `/inbox/asignacion`,
  recordatorios: `/inbox/notifications`,
  alertaNotificaciones: `/inbox/notificationsAlert`,
};

export const getDateHour = (dateHour) => {
  let theDate = dateHour.split("T")[0];
  let hour = dateHour.split("T")[1].split(".")[0].split(":")[0];
  let min = dateHour.split("T")[1].split(".")[0].split(":")[1];
  let seg = dateHour.split("T")[1].split(".")[0].split(":")[2];

  let formatDate = `${theDate} ${hour}:${min}:${seg}`;

  return formatDate;
};

export function getHash(string) {
  return createHash("sha256").update(string).digest("hex");
}

export function getFormatComboArray(data) {
  if (data?.length > 0) {
    let listOptions = data.map((item, index) => {
      return {
        id: item.Id,
        label: item.Name,
        value: item.Id,
        required: true,
      };
    });

    console.log("listOptions: --------------------------- ", listOptions);
    return listOptions;
  } else {
    return [];
  }
}

export function Logout() {
  console.log("CIERRASESION");
  localStorage.clear();
  sessionStorage.clear();
  sessionStorage.removeItem("token");
  localStorage.removeItem("versionApp");
}

export function clearCacheData() {
  return new Promise((resolve, reject) => {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    resolve("Ejecucion correcta");
  });
}

// export const B64toBlobAsync = async (b64Data, contentType = '', sliceSize = 512) => {
//   let atob = require('atob');
//   const byteCharacters = atob(b64Data);
//   const byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize);

//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }

//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }

//   const blob = new Blob(byteArrays, { type: contentType });
//   return blob;
// }

export const convertPdfToB64 = async (file) => {
  const fileConverted = await toBase64Async(file);
  const base64result = fileConverted.split(",")[1];
  // const wordUrl = URL.createObjectURL(file);
  // return { base64result, wordUrl };
  return { base64result };
};

const toBase64Async = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const b64toUrl = async (base64Data) => {
  const r = await fetch(base64Data);
  const blob = await r.blob();
  return URL.createObjectURL(blob);
};

export const blobToBase64 = (blob) => {
  try {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  } catch (e) {
    console.log("blobToBase64: ", e);
  }
};

// export const B64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
//   let atob = require('atob');
//   const byteCharacters = atob(b64Data);
//   const byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize);

//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }

//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }

//   const blob = new Blob(byteArrays, { type: contentType });
//   return blob;
// }

export const GetMimeType = (extension) => {
  if (extension == ".jpg" || extension == ".jpge") {
    return "data:image/jpeg;base64";
  } else if (extension == ".jpg") {
    return "data:image/png;base64";
  } else {
    return "data:application/pdf;base64";
  }
};

export const B64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getSizeBlob = (base64) => {
  const fileSize = base64.length * (3 / 4) - (base64.slice(-2) == "==" ? 2 : 1);
  return fileSize;
};

export function obtenerDireccionIP(callback) {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300) {
      const respuesta = JSON.parse(xhr.responseText);
      const ip = respuesta.ip;
      callback(ip);
    } else {
      callback(null);
    }
  };
  xhr.open("GET", "https://api.ipify.org?format=json");
  xhr.send();
}

export const IsDevice = () => {
  let IsAndroid = navigator.userAgent.match(/Android/i) ? true : false;
  let IsIOS = navigator.userAgent.match(/iPhone/i) ? true : false;
  let IsMobile =
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
      ? true
      : false;

  return { IsAndroid, IsIOS, IsMobile };
};

export const ExtractHour = (date) => {
  if (typeof date == "string") return date;
  const current = date;
  const hours = current.getHours();
  const minutes = current.getMinutes();
  const hourTemp = hours < 10 ? "0" + hours : hours;
  const minutesTemp = minutes < 10 ? "0" + minutes : minutes;

  const hourToReturn = `${hourTemp}:${minutesTemp}`;
  return hourToReturn;
};
