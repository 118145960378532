import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button, Box, useTheme
} from '@mui/material';
import { Brightness1, CheckCircle, Cancel } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Visibility from '@mui/icons-material/Visibility';

import NoDocs from '../../assets/NoDocs.png'
import { ModalVisualDoc } from '../modals';
import { useStyles } from './TabsStyle'
import { mainStyles } from '../../theme/mainStyles'
import { UseImage, CustomLoadingOverlay, AlertConfirm } from '../index';
import { MainTableEquivalencias } from '../table'
import { documentActions, validationActions } from '../../store/actions';
import { CustomAlert } from "../CustomAlert";


export function EquivalenciasTables({ items, val, refresh, fileId, status }) {
  const theme = useTheme()
  const localStyles = useStyles(theme)
  const cardStyle = mainStyles.cards()
  const noInfo = mainStyles.noDataSections()
  const [approvedValideDocument, setApprovedValideDocument] = useState(false);
  const [approvedValideProcess, setApprovedValideProcess] = useState(false);
  const [updateMetadataDocument, setUpdateMetadataDocument] = useState(false);
  const [comment, setComment] = useState("");
  const [openDocFileApproval, setOpenDocFileApproval] = useState(false);
  const [openDocFileRejection, setOpenDocFileRejection] = useState(false);
  const [openVisor, setOpenVisor] = useState(false);
  const [activeRechazoDoc, setActiveRechazoDoc] = useState(false);

  const [documentCurrentId, setDocumentCurrentId] = useState(0);
  const [documentCurrentName, setDocumentCurrentName] = useState("");
  const [documentCurrentStatus, setDocumentCurrentStatus] = useState(false);
  const [typeCurrentProcess, setTypeCurrentProcess] = useState("");
  const [documentCurrentMetadata, setDocumentCurrentMetadata] = useState([]);
  const [loadView, setLoadView] = useState(false)

  const docValidate = useSelector((state) => state.DocumentValidation);
  const processRulesData = useSelector((state) => state.ProcessValRulesData);
  const docUpdateMetData = useSelector((state) => state.DocumentUpdateMetData);
  const fileInfoData = useSelector((state) => state.FileInfo);
  const dispatch = useDispatch();
  console.log('Entra aqui xd')
  useEffect(() => {
    if (openDocFileApproval) {
      AlertConfirm(
        {
          icon: "warning",
          html: <Box>
            <Typography
              textAlign={"center"}
              variant="body1"
              sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
            >
              ¿Estás seguro de aprobar el documento?
            </Typography>
          </Box>,
          okbtntext: "Aceptar",
          notbtntext: "Cancelar",
        },
        (result) => {
          if (result.isConfirmed) {
            sendApproval()
          } else if (result.dismiss === "cancel" && result.isDismissed) {

          }
          setOpenDocFileApproval(false);
        }
      );
    }
  }, [openDocFileApproval])

  useEffect(() => {
    if (docValidate.error !== undefined && approvedValideDocument) {
      setApprovedValideDocument(false)
      setLoadView(false)
      alert(docValidate.error);
    } else if (docValidate.items !== undefined && approvedValideDocument) {
      setApprovedValideDocument(false)
      setDocumentCurrentName("")
      setTypeCurrentProcess("")
      handleClose();
      setLoadView(false)
      console.log("***********************Refresh fileinfo.***************************")
      refresh();
    }
  }, [docValidate]);

  useEffect(() => {
    if (docUpdateMetData.error !== undefined && updateMetadataDocument) {
      setUpdateMetadataDocument(false)
      setLoadView(false)
      alert(docUpdateMetData.error);
    } else if (docUpdateMetData.items !== undefined && updateMetadataDocument) {
      setUpdateMetadataDocument(false)
      setApprovedValideProcess(true)
      console.log("excute ProcessValidationRules fileId: ", fileId)
      dispatch(validationActions.ProcessValidationRules(fileId))
      handleClose();
    }
  }, [docUpdateMetData]);


  useEffect(() => {
    if (processRulesData.error !== undefined && approvedValideProcess) {
      setLoadView(false)
      setApprovedValideProcess(false)
      alert(processRulesData.error);
    } else if (processRulesData.items !== undefined && approvedValideProcess) {
      setApprovedValideProcess(false)
      console.log("excute processRulesData.items  refresh: ", processRulesData.items)
      if (activeRechazoDoc == true) {
        setActiveRechazoDoc(false)
        let objRequestRechazo = {
          Document_Id: documentCurrentId,
          Approved: false,
          Comments: comment,
          ValidateFileComplete: null,
        }
        console.log("objRequestRechazo: sendRejection PostDocumentValidation: ", objRequestRechazo)
        dispatch(validationActions.PostDocumentValidation(objRequestRechazo));
      } else {
        let objRequest = {
          Document_Id: documentCurrentId,
          Approved: true,
          Comments: "",
          ValidateFileComplete: null,
        }
        console.log("objRequest: sendApproval PostDocumentValidation: ", objRequest)
        // alert("Aprobado : " + objRequest)
        dispatch(validationActions.PostDocumentValidation(objRequest));
      }
    }
  }, [processRulesData]);

  useEffect(() => {
    console.log("documentCurrentId: ", documentCurrentId, " typeCurrentProcess: ", typeCurrentProcess)
    if (documentCurrentId > 0 && documentCurrentName.length > 0 && typeCurrentProcess === "Visor") {
      setOpenVisor(true)
    } else if (documentCurrentId > 0 && typeCurrentProcess === "Aprobar") {
      setOpenDocFileApproval(true);
    }
    else if (documentCurrentId > 0 && typeCurrentProcess === "Rechazar") {
      setOpenDocFileRejection(true);
    }
  }, [documentCurrentId, documentCurrentName, typeCurrentProcess]);

  const sendApproval = () => {
    setLoadView(true)
    setApprovedValideDocument(true);
    setUpdateMetadataDocument(true)
    let objBodyRequest = {
      ObjectId: documentCurrentId,
      Metadata: documentCurrentMetadata,
      ContinueWorkFlow: false,
    };
    console.log("***sendApproval*** objBodyRequest: updateMetadata: ", objBodyRequest)
    dispatch(documentActions.UpdateMetaData(objBodyRequest))

    // let objRequest = {
    //   Document_Id: documentCurrentId,
    //   Approved: true,
    //   Comments: "",
    //   ValidateFileComplete: true,
    // }
    // console.log("objRequest: sendApproval PostDocumentValidation: ", objRequest)
    // // alert("Aprobado : " + objRequest)
    // dispatch(validationActions.PostDocumentValidation(objRequest));
    handleClose();
  };

  const sendRejection = () => {
    setLoadView(true)

    setApprovedValideDocument(true);
    setUpdateMetadataDocument(true)
    setActiveRechazoDoc(true)

    let objBodyRequest = {
      ObjectId: documentCurrentId,
      Metadata: documentCurrentMetadata,
      ContinueWorkFlow: false,
    };
    console.log("******sendRejection********objBodyRequest: updateMetadata: ", objBodyRequest)
    dispatch(documentActions.UpdateMetaData(objBodyRequest))

    // let objRequest = {
    //   Document_Id: documentCurrentId,
    //   Approved: false,
    //   Comments: comment,
    //   ValidateFileComplete: true,
    // }
    // console.log("objRequest: sendRejection PostDocumentValidation: ", objRequest)
    // dispatch(validationActions.PostDocumentValidation(objRequest));
    // alert("Rechazado: " + objRequest)
    handleClose();
  };

  const handleClose = () => {
    setOpenDocFileApproval(false);
    setOpenDocFileRejection(false);
  };

  const handleOpenDocument = (e) => {
    setDocumentCurrentId(e.currentTarget.id.split('_')[1])
    setDocumentCurrentName(e.currentTarget.name)
    setDocumentCurrentStatus(e.currentTarget.id.split('_')[2])
    setTypeCurrentProcess("Visor")
  };

  const handleCloseDocument = (e) => {
    setDocumentCurrentId(0)
    setDocumentCurrentName("")
    setOpenVisor(false)
  };

  const handleValidateDoc = async (formData, e) => {
    // console.log("event e", e)
    let currentTarget = e.target.offsetParent.id.split('_')

    // console.log("event currentTarget: ", currentTarget, " - formData", formData)
    // console.log("currentTarget.offsetParent: ", e.target.offsetParent)
    // console.log("Validate: ", e.target.offsetParent.id)
    // console.log("Items: ", items)
    let currentMetadata = items?.find(itemDoc => itemDoc.id === parseInt(currentTarget[2]))
    // console.log("currentMetadata: ", currentMetadata)
    let groupNotNull = currentMetadata?.validationRule?.Results?.find(itemDoc => itemDoc.ValueGroupName !== undefined && itemDoc.ValueGroupName !== null && itemDoc.ValueGroupName !== "")
    // console.log("groupNotNull******************: ", groupNotNull?.ValueGroupName)
    let groupNameCurrent = groupNotNull?.ValueGroupName?.length > 0 ? groupNotNull?.ValueGroupName : currentMetadata?.nombre
    // console.log("GroupNameCurrent******************: ", groupNameCurrent)

    const generalMetadata = currentMetadata.validationRule?.Results?.map((meta, i) => {
      return {
        GroupName: meta.ValueGroupName === undefined || meta.ValueGroupName === null ? groupNameCurrent : meta.ValueGroupName,
        Name: meta.ValueMetadataName === undefined || meta.ValueMetadataName === null ? meta.VsValueMetadataName : meta.ValueMetadataName,
        // Value: formData[meta.ValueMetadataName],
        Value: formData[meta.ValueMetadataName === undefined || meta.ValueMetadataName === null ? meta.VsValueMetadataName : meta.ValueMetadataName],
        ApprovedConfidence: null,
        Confidence: null,
        MetadataType: null,
        ValueType: null
      };
    });

    // console.log("generalMetadata: ", generalMetadata)
    handleClose()
    setDocumentCurrentId(parseInt(currentTarget[2]))
    setTypeCurrentProcess(currentTarget[1])
    setComment("")
    setDocumentCurrentMetadata(generalMetadata)
  };

  return (
    <div>
      <div>
        {loadView == true ?
          <CustomLoadingOverlay
            active={loadView} text="Espera un momento por favor..."
          /> : null}

        {/* {console.log("items*********: ", items)} */}
        {items.length > 0 ? items.map((itemDocument, index) => {
          return <Accordion
            classes={{
              root: localStyles.accordion
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"

            >
              <div className={localStyles.containerResult}>
                <div className={localStyles.leftColumnResult}>
                  <Typography className={localStyles.textGroup}>{itemDocument.description}
                    {itemDocument.validacion == true ?
                      <CheckCircle style={{
                        color: "#00847F",
                        fontSize: 25,
                        marginLeft: 7,
                        marginBottom: 3,
                      }} />
                      : itemDocument.validacion === null ?
                        <Brightness1 style={{
                          color: "#00847F",
                          fontSize: 25,
                          marginLeft: 7,
                          marginBottom: 3,
                        }} />
                        : <Cancel
                          style={{
                            color: "#DC3545",
                            fontSize: 25,
                            marginLeft: 7,
                            marginBottom: 3,
                          }}
                        />
                    }
                  </Typography>
                </div>
                <div className={localStyles.rightColumnResult}>
                  <Button id={`btn_${itemDocument.id}_${itemDocument.validacion}`}
                    name={itemDocument.nombre}
                    startIcon={<Visibility />}
                    variant="outlined"
                    sx={{ height: 45, px: 10, maxWidth: 300, color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' } }}
                    onClick={handleOpenDocument} >
                    Ver documento
                  </Button>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className={localStyles.accordionDetailContent}>
              {/* {itemDocument.validationRule?.Results?.length > 0 ? */}
              <MainTableEquivalencias
                // data={itemDocument.metaData}
                data={itemDocument.validationRule?.Results}
                handleSave={handleValidateDoc}
                documentId={itemDocument.id}
                documentName={itemDocument.nombre}
                Validation={itemDocument.validation?.Approved}
                status={status}
              />
              {/* : <Typography variant="h6" className={noInfo.h6}>Sin equivalencias</Typography>} */}
            </AccordionDetails>
          </Accordion>
        }) :
          <div className={noInfo.noData}>
            <UseImage src={NoDocs} type='img' className={localStyles.imageNotFound} />
            <Typography variant="h6" className={noInfo.h6}>Sin equivalencias</Typography>
          </div>
        }
      </div>

      <CustomAlert
        closeAction={() => {
          setOpenDocFileRejection(false);
          setTypeCurrentProcess("")
          setComment("");
        }}
        open={openDocFileRejection}
        title="Rechazar Documento"
        body="Específica el motivo de rechazo del documento."
        leftButtonText="Cancelar"
        leftButtonAction={() => {
          setOpenDocFileRejection(false);
          setTypeCurrentProcess("")
          setComment("");
        }}
        rightButtonText="Aceptar"
        comments={true}
        valueComment={comment}
        commentPlaceHolder="Motivo de rechazo"
        setValueComment={setComment}
        rightButtonAction={sendRejection}
      />

      <ModalVisualDoc open={openVisor}
        handleClose={handleCloseDocument}
        docName={documentCurrentName}
        idDoc={documentCurrentId}
        isApprovedDocument={documentCurrentStatus}
        statusVideo="Prueba" />
    </div>
  )
}