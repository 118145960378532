import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Box,
    SwipeableDrawer, useTheme
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useForm, FormProvider } from "react-hook-form";

// import { mainStyles, theme } from '../../styles'
import { blobToBase64, B64toBlob, IsDevice } from "../../utils";
import { documentActions, fileActions, workFlowActions } from '../../store/actions'
import {
    CustomAlertMaterial, FullUploadFiles, FullCameraCapture, CustomUploadCamera,
    SeeOCRExtraction, AccordeonElemnts, CustomLoadingOverlay, Alert, InputField,
    ButtonsLined, AlertConfirm, Buttons,
    CustomAlert
} from '../'
import { Logout } from '../../utils'
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    drawer: {
        marginLeft: '20% !important',
        width: '60% !important',
        borderRadius: '5px 5px 0px 0px !important',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '5px 24px',
        [theme.breakpoints.only('xs')]: {
            marginLeft: '0% !important',
            width: '100% !important',
        },
    },
    drawerCenter: {
        paddingTop: '24px',
        width: '90%',
        [theme.breakpoints.only('xs')]: {
            width: '95%',
        },
    },
    cancelIcon: {
        marginRight: theme.spacing(2),
    },
    helpListInstructions: {
        display: 'flex',
        marginBottom: theme.spacing(1),
    },
}))


export const DocumentUploadComponent = (props) => {
    console.log('Document upload::: ', props)
    const formMethods = useForm({ mode: "onChange" });
    const { control, handleSubmit, register, formState, setValue } = useForm({ mode: "onBlur" });
    const theme = useTheme()
    const [fileId, setFileId] = useState(0)
    const localStyles = useStyles();
    const navigate = useNavigate()

    const [loadView, setLoadView] = useState(false)
    const [listDisplayDocsItems, setListDisplayDocsItems] = useState([])
    const [texSubTittle, setTexSubTittle] = useState([])
    const [descriptionStage, setDescriptionStage] = useState("")
    const [documentCurrentId, setDocumentCurrentId] = useState(0)
    const [groupDocumentCurrentId, setGroupDocumentCurrentId] = useState(0)
    const [documentNameCurrent, setDocumentNameCurrent] = useState("");
    const [sectionId, setSectionId] = useState(0)
    const [sectionName, setSectionName] = useState("")
    const [ocrInfo, setOcrInfo] = useState({});
    const [base64CurrentFile, setBase64CurrentFile] = useState(null);
    const [initCamera, setInitCamera] = useState(false)
    const [initChangeFile, setInitChangeFile] = useState(false)
    const [extFileCurrent, setExtFileCurrent] = useState("")
    const [currentMetadata, setCurrentMetadata] = useState([])
    const [documentFileCurrent, setDocumentFileCurrent] = useState("")
    const [sendUploadSections, setSendUploadSections] = useState(false)
    const [sendUploadWithSections, setSendUploadWithSections] = useState(false)
    const [sendMetadataExtraction, setSendMetadataExtraction] = useState(false)
    const [isValidOcr, setIsValidOcr] = useState(false)
    const [openSeeOCRExtraccion, setOpenSeeOCRExtraccion] = useState(false);
    const [fileInfoActiveFind, setFileInfoActiveFind] = useState(false);
    const [activeContinueWF, setActiveContinueWF] = useState(false);
    const [continueButton, setContinueButton] = useState(false);

    const [openRedirectDialog, setOpenRedirectDialog] = useState(false)

    const [showRecapturedDocAlert, setShowRecapturedDocAlert] = useState(false)
    const [showCaptureFilePhotoAlert, setShowCaptureFilePhotoAlert] = useState(false)
    const [showDrawerOptionsType, setShowDrawerOptionsType] = useState(false)

    const actorId = props.ActorId;

    const askforTransactionId = () => {
        setLoadView(true)
        let requestUpload = {
            UploadSectionRequestData: {
                B64Content: base64CurrentFile,
                FileId: fileId,
                Actor_Id: actorId,
                SkipActor: null,
                SectionId: sectionId,
                Extension: extFileCurrent ? extFileCurrent : '.jpg',
                Skip: false,
                SkipReason: null,
                Metadata: currentMetadata.length > 0 ? currentMetadata : null,
                ContinueWorkFlow: false,
                Geolocation: null,
                DeferLoad: false,
                OmitirMergeVideo: null,
                OmitirRemovebg: null,
                AsignarEstatusCarga: null
            },
            File_id: fileId,
            Carga: {
                Referencia: fileId.toString(),
                TransactionId: null,
                EsBolqueFin: true,
                TipoDocumento: documentNameCurrent,
                Seccion: sectionName,
                Imagenes: [
                    {
                        B64: base64CurrentFile,
                        Extension: extFileCurrent ? extFileCurrent : '.jpg',
                    },
                ],
                Metadatos: null,
            },
        };
        setLoadView(true);
        console.log(".......................Request Upload....---: ", requestUpload)
        setSendUploadWithSections(true)
        dispatch(documentActions.CargaDocumentoWithSection(requestUpload))
    }

    const uploadSecDocResponse = useSelector((state) => state.UploadSections);
    const cargaDocWithSecResponse = useSelector(state => state.CargaDocWithSecData)
    const fileInfoResponse = useSelector((state) => state.FileInfo);
    const continueWFResponse = useSelector((state) => state.ContinueWorkFlowData);
    const registeredDataResponse = useSelector((state) => state.RegisterMetadaData);
    const ocrDataResponse = useSelector((state) => state.OcrData);

    // const regFolioRemote = useSelector(state => state.Folio)`
    const dispatch = useDispatch()

    useEffect(() => {
        console.log("props.Data *****************", props)
        if (props.Data !== undefined && props.Data !== null) {

            setFileId(props.Data.Id)
            let textDesc = props.Data.Stage?.Properties?.find((item) => item.Name === "textSubTittle")?.Value;
            setTexSubTittle(textDesc);
            setDescriptionStage(props.Data.Stage.Description);

            let itemsToList = props.Data.Stage.DocumentalCategory?.DocumentalGroups?.map((item) => {
                console.log("item.DocumentTypes[0].Document...........: ", item.DocumentTypes[0].Document)
                return {
                    ...item,
                    Capture: item.DocumentTypes[0].Sections[0].CaptureType,
                    ActiveNSections: item.DocumentTypes[0].Sections?.length > 1 ? true : false,
                    Sections: item.DocumentTypes[0].Sections,
                    IsCapture: IsCaptured(item),
                    // IsRejected: item.DocumentTypes[0]?.Document === null || item.DocumentTypes[0]?.Document?.Validation?.Approved === undefined
                    //     ? null : item.DocumentTypes[0]?.Document?.Validation?.Approved,
                    IsRejected: IsRejected(item),
                    Types: item.DocumentTypes.map(item => {
                        return {
                            ...item,
                            Options: [{ Option: item.Name, Value: item.Id }]
                        }
                    }),
                    Options: item.DocumentTypes.map(item => {
                        return { ...item, Option: item.Name, Value: item.Id }
                    })
                };
            }
            );
            setListDisplayDocsItems(itemsToList);
            console.log("itemsToList: ", itemsToList)
        }
    }, [props.Data])

    useEffect(() => {
        if (base64CurrentFile !== undefined && base64CurrentFile !== null && base64CurrentFile !== "") {
            console.log("ocrInfo.ocr*****: ", ocrInfo.ocr, " - base64CurrentFile: ", base64CurrentFile)
            //Si es documento no trae OCR
            if (parseInt(ocrInfo.ocr === undefined || ocrInfo.ocr === null ? "0" : ocrInfo.ocr) === 0) {

                const dataToSend = {
                    B64Content: base64CurrentFile,
                    FileId: fileId,
                    Actor_Id: actorId,
                    SkipActor: null,
                    SectionId: sectionId,
                    Extension: extFileCurrent,
                    Skip: false,
                    SkipReason: null,
                    Metadata: currentMetadata.length > 0 ? currentMetadata : null, //pending
                    ContinueWorkFlow: false, //to upload all files before moving
                    Geolocation: null, //Pending
                    DeferLoad: false, // heavy file, true to load on backgroud
                };
                setLoadView(true);
                setSendUploadSections(true);
                console.log("................Request1_CargaSeccion..............: ", dataToSend)
                dispatch(documentActions.CargaSeccion(dataToSend));
            }
            else {
                askforTransactionId();
                setLoadView(true)

                let mimeType = extFileCurrent === ".pdf" ? 'application/pdf' : 'image/jpeg'
                const blob = B64toBlob(base64CurrentFile, mimeType);
                const blobUrl = URL.createObjectURL(blob);
                setDocumentFileCurrent(blobUrl);
            }
            dispatch(fileActions.AsignateCurrentUser({ File_Id: fileId }));
        }
    }, [base64CurrentFile]);

    useEffect(() => {
        console.log("sendUploadSections, uploadSecDocResponse.items: ", sendUploadSections, uploadSecDocResponse, uploadSecDocResponse.items)
        setLoadView(uploadSecDocResponse.loading)
        if (uploadSecDocResponse.items !== undefined && sendUploadSections) {

            setSendUploadSections(false)
            setOpenSeeOCRExtraccion(false);
            Alert({
                icon: "success",
                title: "Registro correcto.",
            });
            setFileInfoActiveFind(true)
            makeDispatchFileInfo();
        } else if (uploadSecDocResponse.error !== undefined && sendUploadSections) {

            setLoadView(false)
            setOpenSeeOCRExtraccion(false);
            setSendUploadSections(false)
            Alert({
                icon: 0,
                title: uploadSecDocResponse.error,
            });
        }
    }, [uploadSecDocResponse]);

    useEffect(() => {
        setLoadView(cargaDocWithSecResponse.loading)
        console.log('cargaDocWithSecResponse', cargaDocWithSecResponse);
        if (cargaDocWithSecResponse?.items !== undefined && sendUploadWithSections) {
            if ((cargaDocWithSecResponse.items?.UploadSectionResponseData?.Result?.Id !== null
                || cargaDocWithSecResponse.items?.UploadSectionResponseData?.Result?.Id !== undefined) && !sendMetadataExtraction) {
                console.log("cargaDocWithSecResponse_paso1_sendMetadataExtraction: ", sendMetadataExtraction)
                console.log('ENTRA A CERRAR')
                setIsValidOcr(false);

            } else if ((cargaDocWithSecResponse.items?.UploadSectionResponseData?.Result?.Id !== null
                || cargaDocWithSecResponse.items?.UploadSectionResponseData?.Result?.Id !== undefined) && sendMetadataExtraction) {
                console.log("cargaDocWithSecResponse_paso2_sendMetadataExtraction: ", sendMetadataExtraction)
                setOpenSeeOCRExtraccion(false)
                dispatch(documentActions.RegisterMetadata({
                    Document_Id: cargaDocWithSecResponse.items?.UploadSectionResponseData?.Result?.Id,
                    Metadata: currentMetadata.length > 0 ? currentMetadata : null,
                }));
                setIsValidOcr(true);
            }
        }

        if (cargaDocWithSecResponse.items !== undefined && cargaDocWithSecResponse.items !== null && cargaDocWithSecResponse.items !== "") {
            if (cargaDocWithSecResponse.items?.TransactionId !== undefined || cargaDocWithSecResponse.items?.TransactionId !== null
                || cargaDocWithSecResponse.items?.TransactionId !== "") {
                console.log('TERMINA AQUI')
                localStorage.setItem("TransactionId", cargaDocWithSecResponse.items?.TransactionId); //Almacenar TransactionId
                let request = {
                    File_id: fileId,
                    DocumentType_Id: fileInfoResponse.items.Stage?.DocumentalCategory?.DocumentalGroups[0]?.DocumentTypes[0]?.Id,
                    TransactionId: cargaDocWithSecResponse.items?.TransactionId
                }
                console.log("Request OCR: ", request)
                dispatch(documentActions.GetOcrData(request));
            }
        }

        if (cargaDocWithSecResponse.error !== undefined && sendUploadWithSections) {
            console.log(cargaDocWithSecResponse.error)
            setLoadView(false)
            Alert({
                icon: 0,
                title: cargaDocWithSecResponse.error,
            });
        }
    }, [cargaDocWithSecResponse])

    useEffect(() => {
        setLoadView(fileInfoResponse.loading);
        if (fileInfoResponse.items !== undefined && fileInfoResponse.items !== null && fileInfoActiveFind) {
            setFileInfoActiveFind(false)
            console.log(".......fileInfoResponse.items........: ", fileInfoResponse.items)
            let itemsToList = fileInfoResponse.items.Stage.DocumentalCategory?.DocumentalGroups?.map((item) => {
                return {
                    ...item,
                    Capture: item.DocumentTypes[0].Sections[0].CaptureType,
                    ActiveNSections: item.DocumentTypes[0].Sections?.length > 1 ? true : false,
                    Sections: item.DocumentTypes[0].Sections,
                    IsCapture: IsCaptured(item),
                    // IsRejected: item.DocumentTypes[0]?.Document === null || item.DocumentTypes[0]?.Document?.Validation?.Approved == undefined
                    //     ? null : item.DocumentTypes[0]?.Document?.Validation?.Approved,
                    IsRejected: IsRejected(item),
                    Types: item.DocumentTypes.map(item => {
                        return {
                            ...item,
                            Options: [{ Option: item.Name, Value: item.Id }]
                        }
                    }),
                    Options: item.DocumentTypes.map(item => {
                        return { ...item, Option: item.Name, Value: item.Id }
                    })
                };
            }
            );
            setListDisplayDocsItems(itemsToList);
            console.log("itemsToList 2: ", itemsToList)
        }
        else if (fileInfoResponse.error !== undefined && fileInfoActiveFind) {
            if (fileInfoResponse.error == 'unauthorized') {
                setLoadView(false)
                Alert({
                    icon: 'error',
                    title: "Error de autentificación, inicia sesión nuevamente",
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }, () => {
                    Logout()
                    // navigate('/auth/login')
                });
            } else {
                setLoadView(false)
                setFileInfoActiveFind(false)
                Alert({
                    icon: 0,
                    title: fileInfoResponse.error,
                });
            }

        }
    }, [fileInfoResponse]);

    useEffect(() => {
        console.log('ENTRA AQUI AVANZA EL FLUJO')
        setLoadView(continueWFResponse.loading)
        if (continueWFResponse.items !== undefined && continueWFResponse.items?.Stage !== undefined && activeContinueWF) {
            console.log('ENTRA A CERRAR**********************makeDispatchFileInfo***')
            setActiveContinueWF(false)
            if (continueWFResponse.items?.Stage.Name === 'Finalizar Paralelo') {
                Alert({
                    icon: 'success',
                    title: "Has concluido con la información de la solicitud",
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }, () => {
                    // setOpenRedirectDialog(false)
                    navigate("/inbox/homeComponent")
                });
                // setOpenRedirectDialog(true)
            } else {
                makeDispatchFileInfo();
            }
        } else
            if (continueWFResponse.error !== undefined && activeContinueWF) {
                setLoadView(false);
                setActiveContinueWF(false)
                Alert({
                    icon: 0,
                    title: continueWFResponse.error,
                });
            }

    }, [continueWFResponse]);

    useEffect(() => {
        setLoadView(registeredDataResponse.loading)
        if (registeredDataResponse?.items !== undefined && registeredDataResponse?.items !== null && sendUploadWithSections) {
            console.log('ENTRA 1')
            makeDispatchFileInfo();
            setLoadView(false);
            setContinueButton(true)
        } else if (registeredDataResponse?.error !== undefined && sendUploadWithSections) {
            console.log('ENTRA 2')
            setOpenSeeOCRExtraccion(false);
            setLoadView(false);
            Alert({
                icon: 0,
                title: registeredDataResponse.error,
            });
        }
    }, [registeredDataResponse]);

    useEffect(() => {
        console.log('isValidOcr', isValidOcr)
        setLoadView(ocrDataResponse.loading)
        if (ocrDataResponse.items !== undefined && ocrDataResponse.items?.Metadatos !== null && sendUploadWithSections) {
            if (ocrDataResponse.items.Metadatos[0].Name === "ProcessError") {
                console.log('ENTRO EN FALSE ')
                setOpenSeeOCRExtraccion(false);
                setLoadView(false);
            } else if (ocrDataResponse.items.Metadatos[0].Name !== "ProcessError") {
                console.log('ENTRO EN 1 isValidOcr', isValidOcr,)
                if (isValidOcr === true) {
                    console.log('entra a cambiar', isValidOcr)
                    setOpenSeeOCRExtraccion(false);
                    setCurrentMetadata([]);
                    makeDispatchFileInfo();
                    setIsValidOcr(false);
                } else {
                    console.log('ENTRO EN 2 ocrDataResponse.items.Metadatos', ocrDataResponse.items.Metadatos)
                    setCurrentMetadata(ocrDataResponse.items.Metadatos);
                    setOpenSeeOCRExtraccion(true)
                }
            }
        }
        if (ocrDataResponse.error !== undefined && sendUploadWithSections) {
            setLoadView(false);
            setSendUploadWithSections(false)
            Alert({
                icon: 0,
                title: ocrDataResponse.error,
            });
        }

    }, [ocrDataResponse]);

    useEffect(() => {
        if (listDisplayDocsItems?.length > 0) {
            console.log("listDisplayDocsItems:---------: ", listDisplayDocsItems)
            setContinueButton(listDisplayDocsItems
                ?.filter(item => item.IsCapture == false && item.Required == true)?.length == 0)

            // console.log("items****: ", IsCaptured(listDisplayDocsItems), listDisplayDocsItems, listDisplayDocsItems.length)
            // if (IsCaptured(listDisplayDocsItems) === listDisplayDocsItems.length && listDisplayDocsItems.length > 0) {
            //     console.log("Entro a actilet el boton ")
            //     setContinueButton(true);
            //     // if (metaToSend === true) {
            //     //   handleContinue();
            //     // }
            // } else {
            //     setContinueButton(false);
            // }
        }
    }, [listDisplayDocsItems]);

    const makeDispatchFileInfo = () => {
        console.log("makeDispatchFileInfo: fileId: ", fileId)
        // setOpenRedirectDialog(true)
        setFileInfoActiveFind(true)
        dispatch(fileActions.GetFileInfo(fileId));
    };

    const handleSelectAccordionDocument = (data) => {
        if (data.DocumentTypes?.length > 1 && (!data.IsCapture || data.IsCapture)) {
            console.log("----evento 1-----: ", data)
            setDocumentCurrentId(0)
            setGroupDocumentCurrentId(data.Id)
            setShowDrawerOptionsType(true);
        }
        else if (data.DocumentTypes?.length === 1 && (!data.IsCapture || data.IsCapture)) {
            let dataEvent = data.DocumentTypes[0]
            console.log("----dataEvent-----", dataEvent)
            setDocumentCurrentId(dataEvent.Id);
            setOcrInfo(getOcrValue(dataEvent.Properties));
            setDocumentNameCurrent(dataEvent.Name);
            setSectionId(dataEvent.Sections[0].Id);
            setSectionName(dataEvent.Sections[0].Name);
            if (data.Capture === 'Camera') {
                console.log("----evento 4---: ", dataEvent)
                setBase64CurrentFile(null)
                setExtFileCurrent(".jpg")
            }
            else if (data.Capture === 'Document Upload, Camera') {
                console.log("---evento 5----: ", dataEvent)
                setBase64CurrentFile(null)
                setExtFileCurrent(".pdf")
            }
            else if (data.IsCapture) {
                console.log("evento 2: ", dataEvent)
                setShowRecapturedDocAlert(true);
            }
        }
    }

    useEffect(() => {
        if (showRecapturedDocAlert) {
            AlertConfirm(
                {
                    icon: "warning",
                    html: <Box>
                        <Typography
                            textAlign={"center"}
                            variant="body1"
                            sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
                        >
                            Este documento ya fue capturado ¿Deseas capturarlo nuevamente?
                        </Typography>
                    </Box>,
                    okbtntext: "Aceptar",
                    notbtntext: "Rechazar",
                },
                (result) => {
                    if (result.isConfirmed) {
                        setShowDrawerOptionsType(true);
                    } else if (result.dismiss === "cancel" && result.isDismissed) {

                    }
                    setShowRecapturedDocAlert(false)
                }
            );
        }
    }, [showRecapturedDocAlert]);

    const handleButtonContinue = () => {
        setLoadView(true);
        console.log('entro a avanzar')
        setActiveContinueWF(true)
        dispatch(workFlowActions.ContinueWorkFlow({ File_Id: fileId }));
    };

    const RestoreValue = () => {
        listDisplayDocsItems.forEach(itemGroup => {
            console.log("ItemGroup-------. ", itemGroup)
            itemGroup.Types.forEach(itemInput => {
                setValue(itemInput.Name, null)
            })
        })
    }

    return (
        <FormProvider {...formMethods} theme={theme}>

            {openRedirectDialog &&
                <CustomAlert
                    closeAction={() => {
                        setOpenRedirectDialog(false)
                        navigate("/inbox/homeComponent")
                    }}
                    open={openRedirectDialog}
                    title=''
                    body='Has concluido con la información de la solicitud'
                    rightButtonText='Aceptar'
                    rightButtonAction={() => {
                        setOpenRedirectDialog(false)
                        navigate("/inbox/homeComponent")
                    }}
                    NoCancelButton={true}
                /* leftButtonAction={() => {
                    setOpenRedirectDialog(false)
                    navigate("/inbox/homeComponent")
                }} */
                />}
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                {console.log("loadView**************: ", loadView)}
                {loadView == true ?
                    <CustomLoadingOverlay
                        active={loadView} text="Espera un momento por favor..."
                    /> : null}

                {texSubTittle ?
                    <Typography variant="subtitle1">
                        {texSubTittle}
                    </Typography>
                    : null}
                {/* {fileInfoResponse?.items?.Status === "Carga documental por Background Check Vendor" ? (
                    <Typography variant="subtitle1" style={{ marginBottom: '1em' }}>
                        Carga los antecedentes que formarán parte de la documentación del candidato.
                        En caso de que el documento que ingresaste no sea el correcto, presiona el botón "Cargar documento" y sube el archivo correcto.
                    </Typography>
                ) : null} */}


                {listDisplayDocsItems?.map((item, index) =>
                    <AccordeonElemnts Data={item} Index={index} handleButton={handleSelectAccordionDocument}
                        handleCamera={() => {
                            console.log("Activa la camara desde accordion.")
                            setExtFileCurrent(null)
                            setInitCamera(true)
                        }}
                        onSendData={(data, extention) => {
                            console.log("Data upload file: extention, data: ", extention, data)
                            setExtFileCurrent(extention)
                            setBase64CurrentFile(data)
                            setLoadView(true)
                            setInitChangeFile(false)
                        }}
                    />
                )}


                {/* <AccordeonElemnts Data={listDisplayDocsItems} handleButton={handleSelectAccordionDocument}
                    handleCamera={() => {
                        console.log("Activa la camara desde accordion.")
                        setExtFileCurrent(null)
                        setInitCamera(true)
                    }}
                    onSendData={(data, extention) => {
                        console.log("Data upload file: extention, data: ", extention, data)
                        setExtFileCurrent(extention)
                        setBase64CurrentFile(data)
                        setLoadView(true)
                        setInitChangeFile(false)
                    }}
                /> */}

                {continueButton &&
                    <Buttons onClickConfirm={() => handleButtonContinue()}
                        marginBottom={IsDevice().IsMobile ? 50 : 1} />
                }

                {showDrawerOptionsType ?
                    <SwipeableDrawer anchor="bottom"
                        open={showDrawerOptionsType}
                        classes={{ paper: localStyles.drawer }}
                        onClose={(event, reason) => {
                            console.log("Onclose: ", reason, event)
                            if (reason === 'backdropClick') {
                                return;
                            }
                            setShowDrawerOptionsType(false);
                        }} >
                        <Box className={localStyles.drawerCenter}>
                            <Typography variant="h6">
                                {listDisplayDocsItems.find(item => item.Id == groupDocumentCurrentId)?.ActiveNSections == true
                                    ? "Tipo de comprobante"
                                    : "Tipo de documento"}
                            </Typography>
                            {listDisplayDocsItems.find(item => item.Id == groupDocumentCurrentId)?.ActiveNSections == true ? null : (
                                <Typography variant="subtitle1">
                                    Selecciona el tipo de documento que deseas cargar.
                                </Typography>
                            )}
                            {console.log("*..................listDisplayDocsItems..............**: ", listDisplayDocsItems)}
                            {listDisplayDocsItems.length == 0 ?
                                <span>Sin tipos de documento</span>
                                : listDisplayDocsItems.map((itemInput) => (
                                    <InputField
                                        inputType="radio"
                                        control={control}
                                        name={`${itemInput.Name}`}
                                        // label={itemInput.Name?.toUpperCase()}
                                        radioValues={itemInput.Options}
                                        validations={{ required: itemInput.Required }}
                                        error={!!formState.errors[`${itemInput.Name}`]}
                                        helperText={formState.errors[`${itemInput.Name}`]?.message}
                                        required={itemInput.Required}
                                        onSelect={(e, value) => {
                                            console.log("RADIO itemInput", itemInput)
                                            console.log("RADIO value: ", value)
                                            let getProperties = itemInput.DocumentTypes.find(item => item.Id == value)
                                            setDocumentCurrentId(value);
                                            setOcrInfo(getOcrValue(getProperties?.Properties))
                                            setDocumentNameCurrent(itemInput.Name);
                                            setSectionId(getProperties.Sections[0].Id);
                                            setSectionName(getProperties.Sections[0].Name);
                                            setBase64CurrentFile(null)
                                        }}
                                        disabled={itemInput.Disabled}
                                    />
                                ))}
                            <ButtonsLined ConfirmButtonText='Aceptar'
                                onClickConfirm={handleSubmit((dataSelec) => {
                                    AlertConfirm(
                                        {
                                            icon: "success",
                                            html: <Box>
                                                <Typography
                                                    textAlign={"center"}
                                                    variant="body1"
                                                    sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
                                                >
                                                    Ahora puedes subir un archivo desde tu dispositivo o capturar una foto. ¿Qué deseas hacer?
                                                </Typography>
                                            </Box>,
                                            okbtntext: "Subir archivo",
                                            notbtntext: "Tomar foto",
                                        },
                                        (result) => {
                                            if (result.isConfirmed) {
                                                setInitChangeFile(true)
                                            } else if (result.dismiss === "cancel" && result.isDismissed) {
                                                setInitCamera(true)
                                                setExtFileCurrent(".jpg")
                                            }
                                            setShowCaptureFilePhotoAlert(false);
                                            RestoreValue()
                                        }
                                    );

                                })}
                                onClickCancel={() => {
                                    setShowDrawerOptionsType(false)
                                    setBase64CurrentFile(null)
                                    RestoreValue()
                                }}
                            />
                        </Box>
                    </SwipeableDrawer>
                    : null
                }

                {initChangeFile ?
                    <FullUploadFiles open={initChangeFile}
                        DocName={documentNameCurrent}
                        onClose={() => {
                            setInitChangeFile(false)
                            setShowDrawerOptionsType(false);
                        }}
                        onSendData={(data) => {
                            console.log("Data upload file: ", data)
                            setBase64CurrentFile(data)
                            setLoadView(true)
                            setExtFileCurrent(".pdf")
                            setInitChangeFile(false)
                        }}
                    />
                    : null
                }

                {initCamera ?
                    navigator.userAgent.match(/iPhone/i) ||
                        navigator.userAgent.match(/Android/i) ?
                        <CustomUploadCamera onCapture={blob => {
                            console.log("camera return blob: ", blob)
                            blobToBase64(blob).then(res => {
                                let resultReplace = res.replace('data:image/jpeg;base64,', '')
                                setExtFileCurrent(".jpg")
                                setBase64CurrentFile(resultReplace)
                                setInitChangeFile(false)
                                setInitCamera(false)
                            });
                        }} />
                        :
                        <FullCameraCapture open={initCamera}
                            onClose={() => {
                                setInitCamera(false)
                                setShowDrawerOptionsType(false);
                            }}
                            onSendData={(data) => {
                                setExtFileCurrent(".jpg")
                                setBase64CurrentFile(data)
                                setInitCamera(false)
                                setInitChangeFile(false)
                            }} />
                    : null
                }

                {openSeeOCRExtraccion ?
                    <SeeOCRExtraction
                        open={openSeeOCRExtraccion}
                        metadata={currentMetadata} //for metadata
                        metaToSend={sendMetadataExtraction}
                        sendAction={askforTransactionId}
                        setLoadFile={setLoadView}
                        doc={documentFileCurrent}
                        docExt={extFileCurrent}
                        setMetadatos={setCurrentMetadata}
                        folioId={fileId}
                        docId={documentCurrentId}
                        loading={loadView}
                        ocrInfo={ocrInfo}
                        setMetaToSend={setSendMetadataExtraction} />
                    : null
                }


            </Box>
        </FormProvider>)
}

const getOcrValue = (array) => {
    let ocr = array.find(e => e.Name === "OcrCapture")
    let type = array.find(e => e.Name === "TypeOcrCapture")
    console.log("ocr,type-----------------: ", ocr, type)
    if (ocr === undefined) {
        return {
            ocr: 0,
            type: 'Online'
        }
    } else {
        return {
            ocr: ocr.Value,
            type: type.Value
        }
    }
}

const IsCaptured = (itemSelected) => {
    console.log("-----itemSelected-----: ", itemSelected)
    if (itemSelected.DocumentTypes?.length > 1) {
        if (itemSelected.DocumentTypes.filter(item => item.Document !== null
            && item.Document?.Validation?.Approved !== false)?.length > 0) {
            return true;
        } else {
            return false
        }
    } else {
        if (itemSelected.DocumentTypes[0].Document != undefined &&
            itemSelected.DocumentTypes[0].Document != null) {
            return true;
        } else
            if (itemSelected.DocumentTypes[0].Document != undefined &&
                itemSelected.DocumentTypes[0].Document != null &&
                itemSelected.DocumentTypes[0].Document?.Validation?.Approved != false &&
                itemSelected.DocumentTypes[0].Document?.Validation?.Approved != undefined) {
                return true;
            } else {
                return false;
            }
    }
}

const IsRejected = (itemSelected) => {
    console.log("-----IsRejected-----: ", itemSelected)
    // item.DocumentTypes[0]?.Document === null 
    // || item.DocumentTypes[0]?.Document?.Validation?.Approved == undefined
    //     ? null : item.DocumentTypes[0]?.Document?.Validation?.Approved
    if (itemSelected.DocumentTypes?.length > 1) {
        if (itemSelected.DocumentTypes.filter(item => item.Document !== null
            && item.Document?.Validation?.Approved !== false)?.length > 0) {
            return true;
        } else {
            return false
        }
    } else {
        if (itemSelected.DocumentTypes[0].Document != undefined &&
            itemSelected.DocumentTypes[0].Document != null) {
            return true;
        } else {
            return null;
        }
    }
}