import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const userRequests = {}

userRequests.ChangePassword = (data) => {
  return axios.post(
    AppUrls.ChangePassword,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigNoAuth()
  ).then(handleResponse)
}


userRequests.GetUsers = (data) => {
  return axios.post(
    AppUrls.GetUsers,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}


userRequests.RegisterUserRole = (data) => {
  return axios.post(
    AppUrls.RegisterUserRole,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}


userRequests.UpdateUserRole = (data) => {
  return axios.post(
    AppUrls.UpdateUserRole,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

userRequests.RecoverChangePassword = (data) => {
  return axios.post(
    AppUrls.RecoverChangePassword,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}


let handleResponse = (response) => {
  let respObject = response.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    let error = (respObject.Body || responseObject);
    return Promise.reject(error);
  }
  return respObject;
}