import axios from 'axios';
import { AppUrls, } from '../services/Globals';
import { Config } from '../services/Config';

export const docRequest = async (docId, docMatch, docDate, docSimilarity, frameSnap) => {

    const getType = (type) => {
        switch (type) {
            case '.pdf':
                return 'application/pdf'
            case '.mp4':
                return 'video/mp4'
            case '.jpg':
                return 'image/jpeg'
            default:
                return '.pdf'
        }
    }

    const handleResponse = (response) => {
        //Se obtiene el tipo de archivo
        const headerval = response.headers['content-disposition'];
        let extFile = '.pdf';
        if (headerval?.includes("?utf")) {
            extFile = '.pdf'
        } else {
            extFile = '.' + headerval?.split('.')[1]?.substring(0, 3);
        }
        //Create a Blob from the PDF Stream
        const file = new Blob(
            [response.data],
            //{ type: response.data.type }
            { type: getType(extFile) }
        );//Build a URL from the file
        const fileURL = URL.createObjectURL(file);//Open the URL on new Window

        let respObject = {
            data: fileURL,
            url: response.config.url,
            type: getType(extFile),
            extension: extFile,
            match: docMatch,
            date: docDate,
            similarity: docSimilarity,
            spnapShot: frameSnap
        };

        if (response.status !== 200 &&
            response.statusText !== "OK") {
            let responseObject = { message: response.Messages + ' ' + (response.TansactionId ? response.TansactionId : "") + ' ' + (response.statusText ? response.statusText : "") };
            const error = (respObject.Body || responseObject);
            return Promise.reject(error);
        } else {
            return respObject;
        }
    }

    return axios.get(
        AppUrls.GetBioDocument + "?Id=" + docId + "&&Authorization=" + Config.getTokenCurrentUser(),
        Config.ConfigNoAuthDoc()
    ).then(handleResponse)

}