export const workFlowConstants = {
  WF_BINNACLE_REQUEST: 'WF_BINNACLE_REQUEST',
  WF_BINNACLE_SUCCESS: 'WF_BINNACLE_SUCCESS',
  WF_BINNACLE_FAILURE: 'WF_BINNACLE_FAILURE',

  CONTINUE_WF_REQUEST: 'CONTINUE_WF_REQUEST',
  CONTINUE_WF_SUCCESS: 'CONTINUE_WF_SUCCESS',
  CONTINUE_WF_FAILURE: 'CONTINUE_WF_FAILURE',

  REJECT_WF_REQUEST: 'REJECT_WF_REQUEST',
  REJECT_WF_SUCCESS: 'REJECT_WF_SUCCESS',
  REJECT_WF_FAILURE: 'REJECT_WF_FAILURE',

  RETURN_STAGE_REQUEST: 'RETURN_STAGE_REQUEST',
  RETURN_STAGE_SUCCESS: 'RETURN_STAGE_SUCCESS',
  RETURN_STAGE_FAILURE: 'RETURN_STAGE_FAILURE',
}
