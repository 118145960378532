import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const executeSearchRequests = {}

executeSearchRequests.GetByCodigoPostal = (data) => {
    return axios.post(
        AppUrls.ExecuteSearchCp,
        Config.SetParameters(data, '', '', ''),
        Config.ConfigAuth()
    ).then(handleResponse)
}

executeSearchRequests.GetBySelectedOption = async (data) => {
    return await axios.post(
        AppUrls.GetBySelectedOption,
        Config.SetParameters(data, '', '', ''),
        Config.ConfigAuth()
    ).then(handleResponse)/* .catch((e) => handleResponse(false)) */
}

let handleResponse = (response) => {
    if (!response) {
        let responseObject = { message: 'unauthorized' };
        return Promise.reject(responseObject);
    }
    let respObject = response.data;//BodySecurityResponse(response.data);
    if (!respObject.IsOK) {
        let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
        let error = (respObject.Body || responseObject);
        return Promise.reject(error);
    }
    return respObject;
}