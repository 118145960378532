import { fileMasiveConstants } from '../constants/index.js'

export const GetBatchFile = (state = {}, action) => {
    // console.log('actionaction', action)
    switch (action.type) {
        case fileMasiveConstants.GET_BATCHFILE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case fileMasiveConstants.GET_BATCHFILE_SUCCESS:
            return {
                loading: false,
                items: action.dataExcel.Body,
                error: undefined
            }
        case fileMasiveConstants.GET_BATCHFILE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}
export const InitializeBatchFile = (state = {}, action) => {
    switch (action.type) {
        case fileMasiveConstants.INITIALIZATE_BATCHFILE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case fileMasiveConstants.INITIALIZATE_BATCHFILE_SUCCESS:
            return {
                loading: false,
                items: action.dataFile,
                error: undefined
            }
        case fileMasiveConstants.INITIALIZATE_BATCHFILE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}

export const GetByUsuarioRolBusquedaPortalGrupos = (state = {}, action) => {
    // console.log('GetByUsuarioRolBusquedaPortalGrupos', action)
    switch (action.type) {
        case fileMasiveConstants.FILE_SEARCH_MASIVE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case fileMasiveConstants.FILE_SEARCH_MASIVE_SUCCESS:
            return {
                loading: false,
                items: action.dataSearch.Body,
                error: undefined
            }
        case fileMasiveConstants.FILE_SEARCH_MASIVE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}

export const GetByUsuarioRolDetalleGrupo = (state = {}, action) => {
    // console.log('GetByUsuarioRolDetalleGrupo', action)
    switch (action.type) {
        case fileMasiveConstants.FILE_SEARCH_MASIVE_DEATILS_GROUP_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case fileMasiveConstants.FILE_SEARCH_MASIVE_DEATILS_GROUP_SUCCESS:
            return {
                loading: false,
                items: action.dataSearch.Body,
                error: undefined
            }
        case fileMasiveConstants.FILE_SEARCH_MASIVE_DEATILS_GROUP_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}

export const GetByUsuarioGruposStatus = (state = {}, action) => {
    // console.log('GetByUsuarioGruposStatus', action)
    switch (action.type) {
        case fileMasiveConstants.FILE_SEARCH_STATUS_MASIVE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case fileMasiveConstants.FILE_SEARCH_STATUS_MASIVE_SUCCESS:
            return {
                loading: false,
                items: action.dataSearch.Body,
                error: undefined
            }
        case fileMasiveConstants.FILE_SEARCH_STATUS_MASIVE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}

export const UploadBatchDocumentProcess = (state = {}, action) => {
    // console.log('GetByUsuarioGruposStatus', action)
    switch (action.type) {
        case fileMasiveConstants.UPLOAD_DOCUMENTS_MASIVE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case fileMasiveConstants.UPLOAD_DOCUMENTS_MASIVE_SUCCESS:
            return {
                loading: false,
                items: action.dataSearch.Body,
                error: undefined
            }
        case fileMasiveConstants.UPLOAD_DOCUMENTS_MASIVE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}

export const GetUsersInformationMetadadaMasive = (state = {}, action) => {
    // console.log('GetUsersInformationMetadadaMasive', action)
    switch (action.type) {
        case fileMasiveConstants.GET_USERS_METADATA_MASIVE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case fileMasiveConstants.GET_USERS_METADATA_MASIVE_SUCCESS:
            return {
                loading: false,
                items: action.dataSearch.Body,
                error: undefined
            }
        case fileMasiveConstants.GET_USERS_METADATA_MASIVE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}

export const UploadUsersInformationMetadadaMasive = (state = {}, action) => {
    // console.log('UploadUsersInformationMetadadaMasive', action)
    switch (action.type) {
        case fileMasiveConstants.UPLOAD_METADATA_USERS_MASIVE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case fileMasiveConstants.UPLOAD_METADATA_USERS_MASIVE_SUCCESS:
            return {
                loading: false,
                items: action.dataSearch.Body,
                error: undefined
            }
        case fileMasiveConstants.UPLOAD_METADATA_USERS_MASIVE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}