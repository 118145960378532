import { Alert } from '../../components';
import { fileMasiveConstants } from '../constants';
import { fileMasiveRequests } from '../../services'

export const fileMasiveActions = {}

fileMasiveActions.GetBatchFile = (data) => {
  return dispatch => {
    dispatch(request())
    fileMasiveRequests.UploadBatchFile2(data)
      .then(
        dataExcel => dispatch(success(dataExcel)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: fileMasiveConstants.GET_BATCHFILE_REQUEST }
  }

  function success(dataExcel) {
    console.log(dataExcel);
    return { type: fileMasiveConstants.GET_BATCHFILE_SUCCESS, dataExcel }
  }

  function failure(error) {
    return { type: fileMasiveConstants.GET_BATCHFILE_FAILURE, error }
  }
}

fileMasiveActions.InitializeBatchFile = (data) => {
  console.log('INICIAMASIVO', data)
  return dispatch => {
    dispatch(request())
    fileMasiveRequests.InitializeBatchFile(data)
      .then(
        dataFile => dispatch(success(dataFile)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: fileMasiveConstants.INITIALIZATE_BATCHFILE_REQUEST }
  }

  function success(dataFile) {
    console.log(dataFile);
    return { type: fileMasiveConstants.INITIALIZATE_BATCHFILE_SUCCESS, dataFile }
  }

  function failure(error) {
    return { type: fileMasiveConstants.INITIALIZATE_BATCHFILE_FAILURE, error }
  }
}

fileMasiveActions.GetByUsuarioRolBusquedaPortalGrupos = (data) => {
  return dispatch => {
    dispatch(request())
    fileMasiveRequests.GetByUsuarioRolBusquedaPortalGrupos(data)
      .then(
        dataSearch => dispatch(success(dataSearch)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: fileMasiveConstants.FILE_SEARCH_MASIVE_REQUEST }
  }

  function success(dataSearch) {
    console.log(dataSearch);
    return { type: fileMasiveConstants.FILE_SEARCH_MASIVE_SUCCESS, dataSearch }
  }

  function failure(error) {
    return { type: fileMasiveConstants.FILE_SEARCH_MASIVE_FAILURE, error }
  }
}

fileMasiveActions.GetByUsuarioRolBusquedaPortalGrupos = (data) => {
  return dispatch => {
    dispatch(request())
    fileMasiveRequests.GetByUsuarioRolBusquedaPortalGrupos(data)
      .then(
        dataSearch => dispatch(success(dataSearch)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: fileMasiveConstants.FILE_SEARCH_MASIVE_REQUEST }
  }

  function success(dataSearch) {
    console.log(dataSearch);
    return { type: fileMasiveConstants.FILE_SEARCH_MASIVE_SUCCESS, dataSearch }
  }

  function failure(error) {
    return { type: fileMasiveConstants.FILE_SEARCH_MASIVE_FAILURE, error }
  }
}

fileMasiveActions.GetByUsuarioRolDetalleGrupo = (data) => {
  return dispatch => {
    dispatch(request())
    fileMasiveRequests.GetByUsuarioRolDetalleGrupo(data)
      .then(
        dataSearch => dispatch(success(dataSearch)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: fileMasiveConstants.FILE_SEARCH_MASIVE_DEATILS_GROUP_REQUEST }
  }

  function success(dataSearch) {
    console.log(dataSearch);
    return { type: fileMasiveConstants.FILE_SEARCH_MASIVE_DEATILS_GROUP_SUCCESS, dataSearch }
  }

  function failure(error) {
    return { type: fileMasiveConstants.FILE_SEARCH_MASIVE_DEATILS_GROUP_FAILURE, error }
  }
}

//TRAER DATOS CON EL EXCEL QUYE SE SUBE DENTRO DE
fileMasiveActions.GetUsersInformationMetadadaMasive = (data) => {
  return dispatch => {
    dispatch(request())
    fileMasiveRequests.GetUsersInformationMetadadaMasive(data)
      .then(
        dataSearch => dispatch(success(dataSearch)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: fileMasiveConstants.GET_USERS_METADATA_MASIVE_REQUEST }
  }

  function success(dataSearch) {
    console.log(dataSearch);
    return { type: fileMasiveConstants.GET_USERS_METADATA_MASIVE_SUCCESS, dataSearch }
  }

  function failure(error) {
    return { type: fileMasiveConstants.GET_USERS_METADATA_MASIVE_FAILURE, error }
  }
}

// Mandar lo que ya subiste
fileMasiveActions.UploadUsersInformationMetadadaMasive = (data) => {
  return dispatch => {
    dispatch(request())
    fileMasiveRequests.UploadUsersInformationMetadadaMasive(data)
      .then(
        dataSearch => dispatch(success(dataSearch)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: fileMasiveConstants.UPLOAD_METADATA_USERS_MASIVE_REQUEST }
  }

  function success(dataSearch) {
    console.log(dataSearch);
    return { type: fileMasiveConstants.UPLOAD_METADATA_USERS_MASIVE_SUCCESS, dataSearch }
  }

  function failure(error) {
    return { type: fileMasiveConstants.UPLOAD_METADATA_USERS_MASIVE_FAILURE, error }
  }
}

fileMasiveActions.UploadBatchDocumentProcess = (data) => {
  return dispatch => {
    dispatch(request())
    fileMasiveRequests.UploadBatchDocumentProcess(data)
      .then(
        dataSearch => {
          dispatch(success(dataSearch))
          Alert({
            icon: "success",
            title: 'Documentación enviada con exito',
          })
        },
        error => {
          dispatch(failure(error.message))
          Alert({
            icon: 0,
            title: error.message,
          })
        }
      )
  }

  function request() {
    return { type: fileMasiveConstants.UPLOAD_DOCUMENTS_MASIVE_REQUEST }
  }

  function success(dataSearch) {
    console.log(dataSearch);
    return { type: fileMasiveConstants.UPLOAD_DOCUMENTS_MASIVE_SUCCESS, dataSearch }
  }

  function failure(error) {
    return { type: fileMasiveConstants.UPLOAD_DOCUMENTS_MASIVE_FAILURE, error }
  }
}
