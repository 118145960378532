import React, { useEffect, useState } from 'react'
import protect from '../../utils/protect'
import { useDispatch, useSelector } from 'react-redux'
import { TextField, Typography, Button, InputAdornment, CircularProgress, Grid, useTheme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';

import { fileActions } from '../../store/actions'
import TopImage from '../../assets/Busqueda_folio.png'
import noResult from '../../assets/NoResultMeet.png'

import { MainTable, UseImage, CustomLoadingOverlay, GenericHeaderActions } from '../../components'
import { mainStyles } from '../../theme/mainStyles'
import { useStyles } from './SearchStyles'
import { Icon } from '@iconify/react'
import { useLocation } from 'react-router-dom';
import { MainTableSearch } from '../../components/table/MainTableSearch';


function SearchComponent(props) {
    const theme = useTheme()
    const gridContainer = mainStyles.gridStyle()
    const noInfo = mainStyles.noDataSections()
    const localStyles = useStyles(theme)
    const location = useLocation()
    const [searching, setSearching] = useState("")
    const [search, setSearch] = useState("")

    const info = useSelector(state => state.InfoBySearch)
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const folioPersisted = location.state

    useEffect(() => {
        // console.log('Use effect SEARCH:: ', search)
        search !== '' && dispatch(fileActions.GetInfoBySearch({ "SearchText": search }))
    }, [search])

    // useEffect(() => {
    //     !!folioPersisted && setSearch(folioPersisted)
    // }, [])


    return (
        <>
            {search === "" ? (
                <div className={gridContainer.centerColumn}>
                    <div className={gridContainer.centerContent}>
                        <UseImage src={TopImage} className={localStyles.topImage} type='img' />
                        <form className={localStyles.formContent} onSubmit={(event) => {
                            console.log('Submit form:::', searching)
                            event.preventDefault()
                            setSearch(searching)
                            console.log('Set the search on useState', searching)
                        }}>
                            <Typography variant='h6' >Ingresa el folio, puesto o nombre para realizar la búsqueda.</Typography>
                            <TextField
                                variant='standard'
                                margin='none'
                                className={localStyles.inputs}
                                sx={{ margin: '0.5em' }}
                                size="normal"
                                placeholder="Buscar"
                                onChange={(event) => {
                                    // console.log('SEARCH VALUE::: ', event.target.value)
                                    setSearching(event.target.value)
                                }}
                                InputProps={
                                    {
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position='start' className={localStyles.icon}>
                                                <SearchIcon className={localStyles.iconMaterial} />
                                            </InputAdornment>
                                        )
                                    }
                                }
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ height: 45, px: 10 }}
                                type="submit" >
                                Buscar
                            </Button>
                        </form>
                    </div>
                </div >
            ) :
                (
                    // className={mainPage.mainComponent}
                    <div>
                        <form className={localStyles.form} >
                            <GenericHeaderActions textSearch={search} onChangeTextField={(event) => {
                                setSearching(event.target.value)
                            }}
                                placeholderTextField="Busca al candidato por folio, puesto o nombre o ingresa la clave del grupo"
                                onClickConfirm={(event) => {
                                    event.preventDefault()
                                    setSearch(searching)
                                }}
                                ConfirmButtonText="Buscar"
                                CancelButtonText="Limpiar búsqueda"
                                onClickCancel={() => {
                                    setSearch('')
                                    setSearching('')
                                }} />
                        </form>
                        {info.error && <p>Error</p>}
                        {info.loading === true ?
                            <CustomLoadingOverlay
                                active={info.loading} text="Espera un momento por favor..."
                            /> : info.items?.length !== 0 ?
                                <MainTableSearch data={info.items} SetCurrentPage={()=>{}}/> :
                                <div className={noInfo.noResult}>
                                    <UseImage src={noResult} type='img' style={{ maxWidth: 350 }} />
                                    <Typography variant="h6" className={noInfo.h6}>No se encontraron resultados de busqueda</Typography>
                                </div>
                        }

                    </div>
                )
            }
        </>
    )
}

export default protect(SearchComponent)