export const ticketsConstants = {
  TICKETS_INFO_REQUEST: 'TICKETS_INFO_REQUEST',
  TICKETS_INFO_SUCCESS: 'TICKETS_INFO_SUCCESS',
  TICKETS_INFO_FAILURE: 'TICKETS_INFO_FAILURE',

  TICKETS_DOCS_REQUEST: 'TICKETS_DOCS_REQUEST',
  TICKETS_DOCS_SUCCESS: 'TICKETS_DOCS_SUCCESS',
  TICKETS_DOCS_FAILURE: 'TICKETS_DOCS_FAILURE',

  TICKETS_BY_INFO_REQUEST: 'TICKETS_BY_INFO_REQUEST',
  TICKETS_BY_INFO_SUCCESS: 'TICKETS_BY_INFO_SUCCESS',
  TICKETS_BY_INFO_FAILURE: 'TICKETS_BY_INFO_FAILURE',

  TICKETS_SINGLE_REQUEST: 'TICKETS_SINGLE_REQUEST',
  TICKETS_SINGLE_SUCCESS: 'TICKETS_SINGLE_SUCCESS',
  TICKETS_SINGLE_FAILURE: 'TICKETS_SINGLE_FAILURE',

  TICKETS_CREATE_REQUEST: 'TICKETS_CREATE_REQUEST',
  TICKETS_CREATE_SUCCESS: 'TICKETS_CREATE_SUCCESS',
  TICKETS_CREATE_FAILURE: 'TICKETS_CREATE_FAILURE',

  TICKETS_BY_SEARCH_REQUEST: 'TICKETS_BY_SEARCH_REQUEST',
  TICKETS_BY_SEARCH_SUCCESS: 'TICKETS_BY_SEARCH_SUCCESS',
  TICKETS_BY_SEARCH_FAILURE: 'TICKETS_BY_SEARCH_FAILURE',

  TICKETS_EXCEL_EXPORT_REQUEST: 'TICKETS_EXCEL_EXPORT_REQUEST',
  TICKETS_EXCEL_EXPORT_SUCCESS: 'TICKETS_EXCEL_EXPORT_SUCCESS',
  TICKETS_EXCEL_EXPORT_FAILURE: 'TICKETS_EXCEL_EXPORT_FAILURE',

  TICKETS_SEARCH_REQUEST: 'TICKETS_SEARCH_REQUEST',
  TICKETS_SEARCH_SUCCESS: 'TICKETS_SEARCH_SUCCESS',
  TICKETS_SEARCH_FAILURE: 'TICKETS_SEARCH_FAILURE',

  TICKETS_FOLIO_REQUEST: 'TICKETS_FOLIO_REQUEST',
  TICKETS_FOLIO_SUCCESS: 'TICKETS_FOLIO_SUCCESS',
  TICKETS_FOLIO_FAILURE: 'TICKETS_FOLIO_FAILURE',

  TICKETS_UPDATEMETADATA_REQUEST: 'TICKETS_UPDATEMETADATA_REQUEST',
  TICKETS_UPDATEMETADATA_SUCCESS: 'TICKETS_UPDATEMETADATA_SUCCESS',
  TICKETS_UPDATEMETADATA_FAILURE: 'TICKETS_UPDATEMETADATA_FAILURE',

  TICKETS_ESTATUSPROCESO_REQUEST: 'TICKETS_ESTATUSPROCESO_REQUEST',
  TICKETS_ESTATUSPROCESO_SUCCESS: 'TICKETS_ESTATUSPROCESO_SUCCESS',
  TICKETS_ESTATUSPROCESO_FAILURE: 'TICKETS_ESTATUSPROCESO_FAILURE',

  TICKETS_CONTACTOLIST_REQUEST: 'TICKETS_CONTACTOLIST_REQUEST',
  TICKETS_CONTACTOLIST_SUCCESS: 'TICKETS_CONTACTOLIST_SUCCESS',
  TICKETS_CONTACTOLIST_FAILURE: 'TICKETS_CONTACTOLIST_FAILURE',

  TICKETS_AREARESPLIST_REQUEST: 'TICKETS_AREARESPLIST_REQUEST',
  TICKETS_AREARESPLIST_SUCCESS: 'TICKETS_AREARESPLIST_SUCCESS',
  TICKETS_AREARESPLIST_FAILURE: 'TICKETS_AREARESPLIST_FAILURE',

  TICKETS_EJECUTIVOLIST_REQUEST: 'TICKETS_EJECUTIVOLIST_REQUEST',
  TICKETS_EJECUTIVOLIST_SUCCESS: 'TICKETS_EJECUTIVOLIST_SUCCESS',
  TICKETS_EJECUTIVOLIST_FAILURE: 'TICKETS_EJECUTIVOLIST_FAILURE',

}