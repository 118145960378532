import { useTheme } from '@mui/styles';
import { useStyles } from '../tabs/TabsStyle'
import {
    Box,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    TextField,
    Button,
    Paper,
    Typography,
    Dialog, DialogContent, DialogTitle, IconButton,
    MenuItem
} from '@mui/material'
import { InputField } from '../.'
import { UseImage } from '../../components'
import CloseIcon from '@mui/icons-material/Close';
import VisorPDF from "../Visor/VisorPDF.js";
import {
    CustomLoadingOverlay,
    GenericHeaderActions
} from '../index';
import {
    ticketActions
} from '../../store/actions'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ticketDocument from '../../assets/ticketDocument.svg'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const TicketFollowing = (props) => {
    const theme = useTheme();
    const localStyles = useStyles(theme)
    const dispatch = useDispatch()

    const ticketInfo = useSelector(state => state.TicketsBySearch)
    const ticketsInfoBySearch = useSelector((state) => state.TicketsInfoByFile)
    const ticketCreate = useSelector((state) => state.CreateTicketSeguimiento)

    const [tickets, setTickets] = useState([])
    const [ticketDoc, setTicketDoc] = useState('')
    const [openDoc, setOpenDoc] = useState(false)

    const [observacionesState, setObservacionesState] = useState('')
    const [tipoSeguimientoState, setTipoSeguimientoState] = useState('')

    const optionList = [
        { Option: 'Llamada de cliente', Value: 'Llamada de cliente' },
        { Option: 'Seguimiento de área de dictamen', Value: 'Seguimiento de área de dictamen' },
        { Option: 'Seguimiento de área de dictamen Howden/IKE', Value: 'Seguimiento de área de dictamen Howden/IKE' },
        { Option: 'Envío de dictamen a HSBC', Value: 'Envío de dictamen a HSBC' },
    ]

    useEffect(() => {
        getTicketFollowinfDispatch(props?.TicketId)

        getTicketSearchDispatch('')
    }, [])

    useEffect(() => {
        if (ticketInfo?.items != undefined) {
            const ticketSelected = ticketInfo?.items.find(tk => tk.Id == props?.TicketId)
            setTicketDoc(ticketSelected?.Documento)
        }
    }, [ticketInfo])


    useEffect(() => {
        if (ticketsInfoBySearch?.items !== undefined) {
            setTickets(ticketsInfoBySearch.items)
        }
    }, [ticketsInfoBySearch])

    useEffect(() => {
        if (ticketCreate?.items !== undefined) {
            getTicketFollowinfDispatch(props?.TicketId)
        }
    }, [ticketCreate])

    const getTicketFollowinfDispatch = (ticketId) => {
        dispatch(ticketActions.GetInfoByFile(ticketId))
    }
    const getTicketSearchDispatch = (searchText) => {
        const objSearch = {
            "File_Id": props.folio,
            "Busqueda": searchText
        }
        dispatch(ticketActions.GetTicketsBySearch(objSearch))
    }

    const handleOpenDoc = () => {
        setOpenDoc(true)
    }
    const handleCloseDoc = () => {
        setOpenDoc(false)
    }

    const handleCreate = () => {
        const obj = {
            Id: 0,
            Ticket_Id: props?.TicketId,
            "TipoSeguimiento": tipoSeguimientoState,
            "Observaciones": observacionesState,
            "Responsable": ""
        }
        dispatch(ticketActions.CreateTicketSeguimiento(obj))
        setTipoSeguimientoState('')
        setObservacionesState('')
    }

    return (
        <div>
            <Dialog fullScreen={false} maxWidth={'xl'} onClose={handleCloseDoc} open={openDoc}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Documento
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDoc}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Box sx={{
                        display: 'flex !important',
                        flexDirection: 'column !important',
                        backgroundColor: '#ffffff',
                        width: '100vh !important',
                        borderRadius: '7px !important',
                        height: '80vh'
                    }}>
                        <object width="100%" height="100%" data={`data:application/pdf;base64,${ticketDoc}`} type='application/pdf' ></object>
                    </Box>
                </DialogContent>
            </Dialog>


            <div style={{ height: `${window.innerHeight - 350}px` }}/* className={localStyles.scroll}  */>
                <Stack display={'flex'} direction={'row'} alignItems={'center'} justifyContent={'start'} width={'100%'}>
                    <Box sx={{ mb: 1, mr: 1, '&:hover': { cursor: 'pointer' } }} onClick={props.onClickReturn}><ArrowBackIcon /></Box>
                    <GenericHeaderActions LabelTitle={`ID Ticket ${props?.TicketId}`} />
                </Stack>

                {ticketsInfoBySearch?.loading ?
                    <div className={localStyles.spinSection}>
                        <CustomLoadingOverlay
                            active={ticketsInfoBySearch?.loading} text="Espera un momento por favor..."
                        />
                    </div> :
                    ticketsInfoBySearch?.items ?
                        <Stack alignItems={'center'} justifyContent={'center'} direction={'column'} width={'100%'} sx={{ mt: 4 }}>
                            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%', px: 10 }}>
                                <Box sx={{ width: '100%', py: 2, pr: 1 }}>
                                    <TextField
                                        label={'Tipo de seguimiento*'}
                                        name={'followType'}
                                        value={tipoSeguimientoState}
                                        fullWidth
                                        select
                                        inputProps={{ displayEmpty: true, shrink: true }}
                                        onChange={(e) => { setTipoSeguimientoState(e.target.value) }}
                                    >
                                        {optionList.map((opt, i) => (
                                            <MenuItem
                                                key={`${opt?.Option}${i}`}
                                                value={opt?.Value}
                                                placeholder="Seleccione"
                                                selected
                                                sx={{ wordSpacing: '0.2rem' }}
                                            >
                                                {opt.Option?.toUpperCase()}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                                <Box sx={{ width: '100%', py: 2, pl: 1 }}>
                                    <TextField
                                        label={'Observaciones*'}
                                        name={'observaciones'}
                                        value={observacionesState}
                                        fullWidth
                                        inputProps={{ displayEmpty: true, shrink: true }}
                                        onChange={(e) => { setObservacionesState(e.target.value) }}
                                    />
                                </Box>
                                <Box sx={{ alignItems: 'end', alignContent: 'end', justifyContent: 'end', py: 2, pl: 1 }}>
                                    <Button disabled={tipoSeguimientoState === '' || observacionesState === ''}
                                        sx={{ width: '100%', height: '40px', alignContent: 'end', alignItems: 'center' }}
                                        variant='contained'
                                        onClick={() => { handleCreate() }}>
                                        Guardar
                                    </Button>
                                </Box>
                            </Stack>


                            <Box sx={{ width: '100%', px: 10 }}>
                                <TableContainer component={Paper} sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    height: '100%', width: '100%',
                                    alignItems: 'center !important',
                                    border: '1px solid lightgray',
                                }}>
                                    <Table sx={{
                                        borderCollapse: 'collapse', width: '100%', maxWidth: '100%'
                                    }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ border: '1px solid lightgray', width: '25%', maxWidth: '25%' }}>Tipo de seguimiento</TableCell>
                                                <TableCell sx={{ border: '1px solid lightgray', width: '50%', maxWidth: '50%' }}>Observaciones</TableCell>
                                                {/* <TableCell sx={{ width: '25%', maxWidth: '25%' }}>Acciones</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tickets?.map((row) =>
                                                <TableRow>
                                                    <TableCell sx={{ border: '1px solid lightgray', width: '25%', maxWidth: '25%' }}>{row?.TipoSeguimiento}</TableCell>
                                                    <TableCell sx={{ border: '1px solid lightgray', width: '50%', maxWidth: '50%', textOverflow: 'ellipsis', wordBreak: 'break-all' }}>{row?.Observaciones}</TableCell>
                                                    {/* <TableCell sx={{ width: '25%', maxWidth: '25%' }}><Button variant='contained' onClick={(row) => { handleSendEmail(row.Id) }}>Enviar correo</Button></TableCell> */}
                                                </TableRow>)}
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            </Box>

                            {!!ticketDoc ?
                                <>
                                    <Stack alignItems={'flex-start'} justifyContent={'center'} direction={'column'} width={'90%'} sx={{ mt: 6 }}>
                                        <Box sx={{ borderBottom: '1px solid red', width: '100%' }}><h4>Documentos</h4></Box>
                                        <Stack alignItems={'center'} justifyContent={'flex-start'} direction={'row'} width={'100%'} sx={{ mt: 1 }}>
                                            <Box sx={{ width: '50%', my: 2, height: '100px' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'flex-start', alignItems: 'flex-start', pb: 3 }}>
                                                    <Box onClick={handleOpenDoc} sx={{
                                                        '&:hover': { cursor: 'pointer' },
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                                        border: '1px solid #D2D2D2', borderRadius: '7px', px: 5, py: 3, maxWidth: '180px',
                                                        boxShadow: 5, position: 'relative', userSelect: 'none'
                                                    }}>
                                                        <UseImage src={ticketDocument} type='img' style={{ width: '80px' }} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </>
                                : null}
                        </Stack>
                        : null}
            </div>
        </div >
    );
}


