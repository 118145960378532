import axios from "axios";
import { AppUrls } from "./Globals";
import { Config } from "./Config";

export const ticketRequest = {};

ticketRequest.GetTicketsByFile = (data) => {
  return axios
    .post(
      AppUrls.GetTicketsByFile,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};
ticketRequest.GetInfoByFile = (data) => {
  return axios
    .post(
      AppUrls.GetInfoByFile,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};
ticketRequest.GetTicketSingle = (data) => {
  return axios
    .post(
      AppUrls.GetTicketSingle,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};
ticketRequest.GetTicketDocs = (data) => {
  return axios
    .post(
      AppUrls.GetTicketDocs,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};

ticketRequest.ExportTicketExcel = (data) => {
  return axios
    .post(
      AppUrls.ExportTicketExcel,
      {
        Body: data /* { File_Id: 143784 }  */,
        EncryptedBody: "",
        PKey: "",
        SecurityData: "",
      },
      {
        responseType: "blob",
        headers: {
          Accept: "application/json", //JSON
          "Content-Type": "application/json;charset=UTF-8", //Esperamos un json con configuración UTF-8
          Authorization: `${sessionStorage.getItem("token")}`, //Se añade Token   Content-Type: multipart/form-data; boundary=something
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

/* ticketRequest.ExportTicketExcel = (data) => {
  return axios
    .post(
      AppUrls.ExportTicketExcel,
      Config.SetParameters({ File_Id: 143784 }, "", "", ""),
      Config.ConfigAuth()
    )
    .then((response) => {
      return response.data;
    });
}; */

ticketRequest.GetInfoTicketsBySearsh = (data) => {
  return axios
    .post(
      AppUrls.GetInfoTicketsBySearsh,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};

ticketRequest.CreateTicketSeguimiento = (data) => {
  return axios
    .post(
      AppUrls.CreateTicketSeguimiento,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};

ticketRequest.GetTicketsBySearch = (data) => {
  return axios
    .post(
      AppUrls.GetTicketsBySearch,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};

ticketRequest.GetTicketsByFolio = (data) => {
  return axios
    .post(
      AppUrls.GetTicketsByFolio,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};

ticketRequest.AddUpdateTicketSeguimiento = (data) => {
  return axios
    .post(
      AppUrls.AddUpdateTicketSeguimiento,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};

ticketRequest.GetTicketEstatusProceso = (data) => {
  return axios
    .post(
      AppUrls.GetTicketEstatusProceso,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse)
    .catch((err) => console.log("Error on get status: ", err));
};
ticketRequest.GetTicketContactoList = (data) => {
  return axios
    .post(
      AppUrls.GetTicketContactoList,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};

ticketRequest.GetTicketAreaRespList = (data) => {
  return axios
    .post(
      AppUrls.GetTicketAreaRespList,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};

ticketRequest.GetTicketEjecutivoList = (data) => {
  return axios
    .post(
      AppUrls.GetTicketEjecutivoList,
      Config.SetParameters(data, "", "", ""),
      Config.ConfigAuth()
    )
    .then(handleResponse);
};

let handleResponse = (response) => {
  console.log("Response tickets: ", response);
  let respObject = response.data; //BodySecurityResponse(response.data);
  if (!respObject?.IsOK) {
    let responseObject = {
      message:
        respObject.Messages +
        " " +
        (respObject.TansactionId ? respObject.TansactionId : "") +
        " " +
        (respObject.statusText ? respObject.statusText : ""),
    };
    let error = respObject.Body || responseObject;
    return Promise.reject(error);
  }
  console.log("Va a regresar el response xd");
  return respObject;
};

let handleResponseExcel = (response) => {
  console.log("Response tickets: ", response);
  let respObject = response.data; //BodySecurityResponse(response.data);
  console.log("Va a regresar el response xd");
  return respObject;
};
