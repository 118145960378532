import { workFlowConstants } from '../constants'
import { workFlowRequests } from '../../services'

export const workFlowActions = {}

workFlowActions.GetBinnacleInfo = (data) => {
  return dispatch => {
    dispatch(request())
    workFlowRequests.GetBinnacleInfo(data)
      .then(
        binnacleInfo => dispatch(success(binnacleInfo)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: workFlowConstants.WF_BINNACLE_REQUEST }
  }

  function success(binnacleInfo) {
    return { type: workFlowConstants.WF_BINNACLE_SUCCESS, binnacleInfo }
  }

  function failure(error) {
    return { type: workFlowConstants.WF_BINNACLE_FAILURE, error }
  }
}

workFlowActions.ContinueWorkFlow = (data) => {
  return dispatch => {
    dispatch(request())
    workFlowRequests.ContinueWorkFlow(data)
      .then(
        contineWFData => dispatch(success(contineWFData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: workFlowConstants.CONTINUE_WF_REQUEST }
  }

  function success(contineWFData) {
    return { type: workFlowConstants.CONTINUE_WF_SUCCESS, contineWFData }
  }

  function failure(error) {
    return { type: workFlowConstants.CONTINUE_WF_FAILURE, error }
  }
}

workFlowActions.RejectWorkFlow = (data) => {
  return dispatch => {
    dispatch(request())
    workFlowRequests.RejectWorkFlow(data)
      .then(
        rejectWFData => dispatch(success(rejectWFData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: workFlowConstants.REJECT_WF_REQUEST }
  }

  function success(rejectWFData) {
    return { type: workFlowConstants.REJECT_WF_SUCCESS, rejectWFData }
  }

  function failure(error) {
    return { type: workFlowConstants.REJECT_WF_FAILURE, error }
  }
}

workFlowActions.ReturnStage = (data) => {
  return dispatch => {
    dispatch(request())
    workFlowRequests.ReturnStage(data)
      .then(
        returnStageData => dispatch(success(returnStageData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: workFlowConstants.RETURN_STAGE_REQUEST }
  }

  function success(returnStageData) {
    return { type: workFlowConstants.RETURN_STAGE_SUCCESS, returnStageData }
  }

  function failure(error) {
    return { type: workFlowConstants.RETURN_STAGE_FAILURE, error }
  }
}