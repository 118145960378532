import React from 'react';
import { getDateHour } from '../../utils'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, styled, useTheme
} from '@mui/material'
import { useStyles } from './TableStyle'

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableMain} !important`,
  color: `${theme.palette.primary.tableText} !important`,
}));

const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
}
}));

export function TableBinnacle({ data }) {
  const theme = useTheme()
  const localStyles = useStyles(theme)

  const columns = [
    { id: 'proceso', label: 'Proceso', width: "170px" },
    { id: 'estatus', label: 'Estatus de ejecución', width: "140px" },
    { id: 'resultado', label: 'Resultado', width: "140px" },
    { id: 'fecha', label: 'Fecha y hora', width: "170px" },
  ]

  const createData = (proceso, estatus, resultado, fecha) => {
    return { proceso, estatus, resultado, fecha }
  }

  const rows = data.map((row) => {
    let finalDate = getDateHour(row.CreationDate)
    return createData(row.FlowState, row.State, row.Result, finalDate)
  })

  return (

    <div className={localStyles.binnacleTable}>
      <TableContainer sx={{width: "100%"}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCellHead
                  key={column.id}
                  style={{ width: column.width }}
                >
                  {column.label}
                </TableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRowCustom hover role="checkbox" 
                tabIndex={-1} key={row.code} >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRowCustom>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )

}