import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Button,
  List,
  SwipeableDrawer,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Dialog,
  Box,
  styled,
  IconButton,
  Paper,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails, useTheme
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useForm, FormProvider } from "react-hook-form";

// import DeleteIcon from "@mui/icons-material/Delete";
// import ReceiptIcon from "@mui/icons-material/Receipt";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
// import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
// import AccountBoxIcon from "@mui/icons-material/AccountBox";
// import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
// import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
// import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
// import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
// import PersonIcon from "@mui/icons-material/Person";
// import AddIcon from "@mui/icons-material/Add";
// import PersonAdd from "@mui/icons-material/PersonAdd";
// import PhotoCameraOutlined from "@mui/icons-material/PhotoCameraOutlined";
// import Group from "@mui/icons-material/Group";
// import Description from "@mui/icons-material/Description";

import { B64toBlob } from "../utils";
import VisorPDF from "./Visor/VisorPDF";
// import {
//   mainStyles, //Estílos genéricos
//   theme, //Téma con colores de DS
// } from "../styles/Main.js"; //Se importan los estilos genéricos
import { UploadDcumentsControl } from "./";

const AccordionCustom = styled(Accordion)(({ theme }) => ({
  width: "100%",
  "& .MuiAccordionSummary-content": {
    margin: "1px 0 !important",
  },
  "& .MuiIconButton-root": {
    padding: "0 0.5em !important",
  },
}));

const AccordionDetailsCustom = styled(AccordionDetails)(({ theme }) => ({
  width: "100%",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
}));

const ContainerDropZone = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  margin: "0px",
  marginBottom: "0px",
  gridTemplateColumns: "20% 60% 20%",
  display: "grid !important",
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "100%",
    minHeight: "90vh",
  },
  overflowY: "hidden !important",
  height: "100vh",
}));

export const AccordeonElemnts = (props) => {
  const theme = useTheme()
  const formMethods = useForm({ mode: "onChange" });
  const [fileB64, setFileB64] = useState(null);
  const [fileUrl, setfileUrl] = useState(null);
  const [extensionCurrent, setExtensionCurrent] = useState("");
  const [expanded, setExpanded] = React.useState(false);
  const fileInfoResponse = useSelector((state) => state.FileInfo);

  let handleFileChange = (files, fileB64Response, extension) => {
    console.log(
      "handleFileChange***********************extension: ",
      extension,
      " fileB64Response: ",
      fileB64Response
    );
    if (fileB64Response == undefined || fileB64Response === null) {
      return;
    } else if (
      navigator.userAgent.match(/iPhone/i) &&
      (props.DocName === "TELMEX" || props.DocName === "IZZI")
    ) {
      props.onSendData(fileB64Response, extensionCurrent);
      props.onClose();
    } else {
      setFileB64(fileB64Response);
    }
    setExtensionCurrent(`.${extension}`);
  };

  useEffect(() => {
    if (fileB64 !== undefined && fileB64 !== null && fileB64 !== "") {
      console.log("extensionCurrent**********: ", extensionCurrent);
      let mimeType =
        extensionCurrent === ".pdf"
          ? "application/pdf"
          : `image/${extensionCurrent}`;
      const blob = B64toBlob(fileB64, mimeType);
      console.log("Blob generado: ", blob);
      const blobUrl = URL.createObjectURL(blob);
      // console.log("URL Blob: ", blobUrl)
      setfileUrl(blobUrl);
    }
  }, [fileB64]);

  let handlePhoto = () => {
    console.log("handlePhoto*********************** ");
    props.handleCamera();
  };

  const handleChange = (panel) => (event, isExpanded) => {
    console.log("panel: ", panel);
    setExpanded(isExpanded ? panel : false);
  };

  console.log(fileInfoResponse, "fileInfoResponse");

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%"
    }}>
      {" "}
      {/* {props.Data?.map((props.Data, index) => {
        console.log("---------props.Data:..................: ", props.Data)
        return ( */}
      <AccordionCustom
        expanded={expanded === `panel${props.Index}`}
        onChange={handleChange(`panel${props.Index}`)}
        key={`${props.Data._id}_${props.Index}`}
      >
        <AccordionSummary
          onClick={() => {
            props.handleButton(props.Data);
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={`id_${props.Data.Id}`}
          key={`id_${props.Data.Id}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            margin: 0,
            padding: "0.5em",
          }}
        >
          <Typography variant="h6">
            {props.Data.Required ? (
              <>
                {" "}
                {props.Data.Name}
                <spam style={{ color: "#F43E3E" }}> * </spam>
              </>
            ) : (
              props.Data.Name
            )}
          </Typography>{" "}
          &nbsp; &nbsp;
          <Typography
            variant="h6"
            style={{
              color: getReadableColorStatus(props.Data.IsRejected)
            }}
          >
            {getReadableStatus(props.Data.IsRejected)}
          </Typography>
        </AccordionSummary>
        <AccordionDetailsCustom>
          {props.Data.DocumentTypes?.length > 1 ?
            <Typography
              variant="h6" >
              {props.Data.Name}
            </Typography>
            :
            <FormProvider {...formMethods}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  minHeight: "34vh",
                }}
              >
                {fileB64 === null ? (
                  <>
                    {fileInfoResponse?.items?.StageName ===
                      "Carga documental" &&
                      fileInfoResponse?.items?.Status !==
                      "Carga documental por Background Check Vendor" ? (
                      <>
                        {/* <Typography variant="subtitle1">
                              Carga los documentos que formarán parte del
                              expediente, estos documentos podrán ser editables o
                              solo de lectura.{" "}
                            </Typography>{" "} 
                            <Typography variant="subtitle1">
                              Si el documento no es el correcto, presiona el botón
                              de “Cargar documento” y sube el archivo correcto.{" "}
                            </Typography>{" "}*/}
                        &nbsp; &nbsp;
                      </>
                    ) : null}
                    <UploadDcumentsControl
                      initLoadChange={true}
                      IsPhoto={false}
                      handleCamera={handlePhoto}
                      onChange={handleFileChange}
                      onClear={fileB64 == null ? true : false}
                    />
                  </>
                ) : fileUrl == null ? (
                  <span>Espere...</span>
                ) : extensionCurrent === ".pdf" ? (
                  <VisorPDF
                    pdfUrl={fileUrl}
                    pdfName={"Document"}
                    height={"50vh"}
                  />
                ) : (
                  <img
                    style={{ width: "100%" }}
                    src={fileUrl}
                    alt="experiment"
                  />
                )}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {fileB64 !== null ? (
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ height: 45, px: 10, maxWidth: 300 }}
                    onClick={() => {
                      props.onSendData(fileB64, extensionCurrent);
                      setFileB64(null);
                      setExtensionCurrent(null);
                    }} >
                    Subir
                  </Button>
                ) : null}
                {
                  fileB64 !== null ? (
                    <Button
                      fullWidth
                      variant="outlined"
                      sx={{ height: 45, px: 10, maxWidth: 300, marginTop: 2, color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' } }}
                      onClick={() => {
                        setFileB64(null);
                        setExtensionCurrent(null);
                      }} >
                      Elegir otro archivo
                    </Button>
                  ) : null
                }
              </Box>
            </FormProvider>}
        </AccordionDetailsCustom>
      </AccordionCustom>
      {/* ); */}
      {/* })} */}
    </Box>
  );
};

const getReadableColorStatus = (status) => {
  if (status == false) {
    return '#F20000';
  } else if (status == true || status == 1) {
    return '#00847F';
  } else {
    return '#FFD233';
  }
}

const getReadableStatus = (status) => {
  if (status == false) {
    return 'Rechazado'
  } else if (status == true) {
    return 'Leído y autorizado'
  } else if (status == 1) {
    return 'Capturado'
  } else {
    return 'Pendiente de carga'
  }
}