import React from 'react';
import { getDateHour } from '../../utils'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  styled, useTheme,
  Stack, IconButton
} from '@mui/material'
import { useStyles } from './TableStyle'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableMain} !important`,
  color: `${theme.palette.primary.tableText} !important`,
}));

const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
  }
}));

export function TableTicket({ data, onClickSee, onClickFollow }) {
  const theme = useTheme()
  const localStyles = useStyles(theme)

  const columns = [
    { id: 'consecutivo', label: 'ID Ticket', width: "10%" },
    { id: 'contacto', label: 'Tipo de seguimiento', width: "15%" },
    { id: 'etapa', label: 'Etapa / Fase', width: "15%" },
    { id: 'responsable', label: 'Responsable / Actor', width: "15%" },
    { id: 'area', label: 'Clasificación de movimiento', width: "15%" },
    { id: 'acciones', label: 'Acciones', width: "5%", align: 'center' },
  ]


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createData = (consecutivo, contacto, etapa, responsable, area, acciones) => {
    return { consecutivo, contacto, etapa, responsable, area, acciones }
  }

  const rows = data.map((row) => {
    return createData(row?.Id, row?.TipoSeguimiento, row?.Etapa, row?.Responsable, row?.ClasificacionMovimiento, 'Acciones')
  })

  return (

    <div className={localStyles.binnacleTable}>
      <TableContainer sx={{ width: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCellHead
                  key={column.id}
                  style={{ width: column.width }}
                >
                  {column.label}
                </TableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRowCustom hover role="checkbox"
                  tabIndex={-1} key={row?.code} >
                  {columns.map((column) => {
                    const value = row[column?.id];
                    return (
                      <TableCell key={column?.id} align={column?.align}>
                        {column?.id !== 'acciones' ? value :
                          <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-around'}>
                            <IconButton onClick={() => { onClickSee(row?.consecutivo/* consecutivo */) }}><VisibilityOutlinedIcon /></IconButton>
                            <IconButton onClick={() => { onClickFollow(row?.consecutivo) }}><PlaylistAddCheckIcon /></IconButton>
                          </Stack>}
                        {/* {column?.format && typeof value === "number"
                          ? column?.format(value)
                          : value} */}
                      </TableCell>
                    );
                  })}
                </TableRowCustom>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )

}