import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Box,
  Hidden,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, FormProvider } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

import logoMet from "../../assets/login.svg";
import ImageLogin from "../../assets/imageLogin.jpg";
// import ImageLogin from '../../assets/imageLogin.svg'
import {
  authActions,
  fileActions,
  validationActions,
} from "../../store/actions";
import { UseImage, Alert, InputField, LinkButton } from "../../components/";
// import { mainStyles, theme } from '../../styles'
import { obtenerDireccionIP } from "../../utils";
// import { useStyles } from './LoginStyles'

// import ImageTopLogin from '../../assets/logo.gif'
// import ImageLeftLogin from '../../assets/Imagen_inicio_portal.jpeg'
// import ImageTopLogin from '../../assets/login.png'
import ImageLeftLogin from "../../assets/Imagen_inicio_portal.jpg";
import Img1 from "../../assets/carusel1.jpg";
import Img2 from "../../assets/carusel2.jpg";
import Img3 from "../../assets/carusel3.jpg";

import { loginFunctions } from "./LoginFunctions.js";
import { coreFunctions } from "../Core";
import { getHash } from "../../utils";
import packageJson from "../../../package.json";

const useStyles = makeStyles((theme) => ({
  //Estílos solamente del componente "LoginComponent"
  containerImagen: {
    height: "100vh",
  },
  imagenIlustrativa: {
    height: "100%",
    width: "100%",
    backgroundRepeat: "none",
    backgroundSize: "100%",
    objectFit: "cover",
  },
  imagenLogin: {
    height: "15vh",
    // width: "100%",
    backgroundRepeat: "none",
    backgroundSize: "100%",
    objectFit: "cover",
  },
  formInput: {
    width: "100%",
    padding: 1,
  },
  boxReponsive: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  containerForm: {
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  loader: {
    position: "absolute !important",
    top: "0 !important",
    bottom: "0 !important",
    left: "0 !important",
    right: "0 !important",
    height: "100vh !important",
    width: "100% !important",
  },
}));

export default function LoginComponent(props) {
  const theme = useTheme();
  const formMethods = useForm({ mode: "onChange" });
  const { control, handleSubmit, register, formState } = useForm({
    mode: "onBlur",
  });

  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [lat, setLat] = useState("12222");
  const [lon, setLon] = useState("22222");
  const [sendCAPTCHA, setSendCAPTCHA] = useState(false);

  const [loadView, setLoadView] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [formChange, setFormChange] = useState(false);
  const [requestCaptcha, setRequestCaptcha] = useState(false);
  const [captchaSucess, setCaptchaSucess] = useState(true /* false */);
  const [ipCurrent, setIpCurrent] = useState("");
  const [idRef, setIdRef] = useState("");
  const [showCaptcha, setShowCaptcha] = useState(false);

  const classes = useStyles(theme);

  // const fieldStyles = null //mainStyles.useStylesField()
  const navigation = useNavigate();
  const { id } = useParams();
  // console.log('ID PARAMS: ', id)

  //Redux hooks
  // const auth = useSelector(state => state.Authentication)
  const auth = useSelector((state) => state.AuthenticationReferer);

  const clientIPs = useSelector((state) => state.GetClientIPsWithRefer);
  const catpcha = useSelector((state) => state.catpchaValidation);
  const dispatch = useDispatch();

  console.log("showCaptcha::: ", showCaptcha, idRef);

  useEffect(() => {
    resetRecaptcha();
    dispatch(authActions.GetClientIPsWithRefer());

    obtenerDireccionIP((ip) => {
      setIpCurrent(ip);
      // console.log("Dirección IP:", ip);
    });
  }, []);

  useEffect(() => {
    caching();
  }, []);

  useEffect(() => {
    // console.log('clientIPs::: ', clientIPs)
    if (clientIPs?.items !== undefined) {
      setShowCaptcha(clientIPs?.items.UseCapcha);
      if (clientIPs?.items.ReferNoCapcha !== null) {
        setIdRef(clientIPs?.items.ReferNoCapcha);
      } else {
        clientIPs?.items.UseCapcha && setIdRef("");
      }
    } else if (clientIPs?.error !== undefined) {
      alert(clientIPs?.error);
      // dispatch(authActions.GetClientIPsWithRefer())
    }
  }, [clientIPs]);

  const cerrarSesion = () => {
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.removeItem("token");
    localStorage.removeItem("versionApp");
  };

  let caching = async () => {
    // cerrarSesion()
    let version = localStorage?.getItem("versionApp");
    if (version != packageJson.version) {
      if ("caches" in window) {
        simulateAsyncOperation().catch((error) => {
          console.error("Caught an error:", error);
        });
        console.log("Ejecuto reload.");
        window.location.reload(true);
      }
      window.location.reload(true);
      localStorage.clear();
      sessionStorage.clear();
      sessionStorage.removeItem("token");
      localStorage.setItem("versionApp", packageJson.version);
      setIdRef("");
    }
  };

  function simulateAsyncOperation() {
    return new Promise((resolve, reject) => {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      resolve("Ejecucion correcta");
    });
  }

  useEffect(() => {
    // console.log('params en login', id)
    // console.log('authauth', auth)
    if (auth !== undefined && auth?.loading && formChange) {
      setLoadView(auth.loading);
    } else if (auth.error !== undefined && formChange) {
      // console.log('Esta aqui 1')
      setLoadView(false);
      // console.log('auth.error', auth.error)
      Alert(
        {
          icon: 0,
          title: auth.error,
        },
        () => {
          resetRecaptcha();
        }
      );
    } else {
      // sessionStorage.clear()
      let wfData = {
        Workflow_Id: 0,
        Workflow_Version: 0,
        Apk_Version: "1.0.5",
        IMEI: "PWA",
      };
      if (auth.items !== undefined && !!sessionStorage.getItem("token")) {
        // dispatch(notificationActions.GetNotificationsUser())
        // console.log('auth:: ', auth)
        // dispatch(fileActions.GetFileInfo('394'))
        if (!!auth.items.UserLoginData?.ActiveDirectory_Sid) {
          console.log("auth loggin:: ", auth);
          navigation("/auth/changePassword");
        } else if (formChange && id === undefined) {
          navigation("/inbox/dashboards");
        } else if (formChange && id !== undefined) {
          navigation("/inbox/dashboards");
        }
      }
    }
  }, [auth]); //Valor a evaluar, sí este cambia, el contenido del arrow se ejecutará

  useEffect(() => {
    setLoadView(catpcha.loading);
    console.log("catpcha:: ", catpcha);
    if (catpcha.items !== undefined && requestCaptcha) {
      setCaptchaSucess(catpcha.items.Success);
      setIdRef(catpcha.items?.ReferId);
    } else {
      showCaptcha && setIdRef("");
    }
  }, [catpcha]);

  //Obtener valor de campos de formulario
  let handleFields = (e) => {
    //almacenar usuario y contraseña en state
    switch (e.target.name) {
      case "userField":
        setUser(e.target.value); //Setear state del usuario
        break;
      case "passField":
        setPass(e.target.value); //Setear state de la contraseña
        break;
      default:
    }
  };

  const resetRecaptcha = () => {
    if (showCaptcha) {
      window.grecaptcha.reset();
      setIdRef("");
    } else {
      dispatch(authActions.GetClientIPsWithRefer());
    }
  };

  //Acción login
  let submitLogin = (formData) => {
    //Login
    // console.log('formData login:', formData)

    let request = {
      //Objeto a enviar a API login
      RequestDataInfo: {
        Coordinates: {
          Latitude: lat,
          Longitude: lon,
        },
        SOVersion: "2",
        PhoneBrand: "5525397878",
        PhoneModel: "vr",
        BrowserRefer: "chrome",
        IP: ipCurrent,
        MacAddress: "FC:01:7C:45:48:3F",
      },
      Tenant: "",
      Username: formData["txtUser"],
      Password: formData["txtPassword"],
      PasswordHash: getHash(formData["txtPassword"]),
      AppType: "MC",
      ReferId: idRef,
    };
    // console.log("data: LOgin: ", request)
    dispatch(authActions.AuthenticationReferer(request));
    // dispatch(authActions.Authentication(request))
    setFormChange(true);
  };

  function catpchaChange(event) {
    console.log("captcha: ", event);
    dispatch(validationActions.GetCatpcha(event));
    setRequestCaptcha(true);
  }

  return (
    <FormProvider {...formMethods} theme={theme}>
      {loadView && (
        <LoadingOverlay
          active={loadView}
          spinner
          text="Espera un momento por favor..."
          className={classes.loader}
        ></LoadingOverlay>
      )}
      <Grid container>
        <Hidden smDown>
          <Grid item lg={6} md={6}>
            <Box className={classes.containerImagen}>
              <UseImage
                src={ImageLogin}
                type="img"
                className={classes.imagenIlustrativa}
              />
            </Box>
          </Grid>
        </Hidden>
        <Grid
          item
          lg={6}
          md={6}
          style={{ height: "100vh", overflow: "auto" }}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box className={classes.containerForm}>
            <Box
              display={"flex"}
              justifyContent="center"
              alignItems="center"
              style={{ marginBottom: "1em" }}
            >
              <UseImage
                src={logoMet}
                type="img"
                className={classes.imagenLogin}
              />
            </Box>
            <Typography variant="h4" style={{ fontWeight: 600 }} gutterBottom>
              Iniciar sesión
            </Typography>
            <InputField
              inputType="text"
              control={control}
              register={register}
              name="txtUser"
              label="Usuario"
              validations={{ required: true }}
              required={true}
              error={!!formState.errors["txtUser"]}
              helperText={formState.errors["txtUser"]?.message}
            />
            <InputField
              inputType="password"
              isPassword={true}
              control={control}
              register={register}
              name="txtPassword"
              label="Contraseña"
              validations={{ required: true }}
              required={true}
              error={!!formState.errors["txtPassword"]}
              helperText={formState.errors["txtPassword"]?.message}
            />
            {showCaptcha ? (
              <Box
                sx={{
                  width: "100%",
                  marginTop: "0.5em",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <ReCAPTCHA
                  className="iframeCaptcha"
                  sitekey="6LeSrKEaAAAAAGnBnM94eRtpu1Z7PwnY3WpOyDvf"
                  tabindex={100}
                  onChange={catpchaChange}
                />
              </Box>
            ) : null}
            {/* <LinkButton textAlign={'right'} textColor={'#000000'} LinkButtonText="¿Olvidaste tu contraseña?" LinkButtonUrl="/auth/changePassword" justify={'end'} /> */}
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                textAlign: "flex-start",
                marginTop: "1em",
              }}
            >
              <Box style={{ width: "100%" }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ height: 45, px: 10 /* , borderRadius: '0' */ }}
                  // type={captchaSucess ? 'submit' : 'button'}
                  onClick={handleSubmit(submitLogin)}
                  disabled={idRef == ""/*  || auth.loading */}
                >
                  Iniciar Sesión
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Box
          sx={(theme) => ({
            height: "35px",
            position: "absolute;",
            backgroundColor: `${theme.palette.footerBar.main} !important`,
            width: "100%",
            color: "#FFF",
            bottom: 0,
            ["@media only screen and (max-width: 600px)"]: {
              width: "100%",
            },
          })}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={(theme) => ({
              width: "100%",
              textAlign: "center",
              paddingTop: "7px",
              letterSpacing: "0.53px",
              color: `${theme.palette.footerBar.text} !important`,
              opacity: "1",
              fontSize: "16px",
              margin: "inherit !important",
              marginBottom: "0px !important",
              paddingBottom: "0px !important",
            })}
          >{`${packageJson.textDescription} V.${packageJson.version}`}</Typography>
        </Box>
      </Grid>
    </FormProvider>
  );
}
