/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Box, Hidden, Typography, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { fileActions } from "../../store/actions";
import { paths, routes } from "../../utils";
import { constPathRoot } from "../../utils/PathPublish";
import { useStyles } from "./HeaderStyles";
import NotificationsView from "../NotificationsView";
import { Alert } from "../Alert";
// import { mainStyles, theme } from '../../styles'

export function Header() {
  const theme = useTheme();
  const localStyles = useStyles(theme);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [folio, setFolio] = useState("");
  const [user, setUser] = useState("");
  const [name, setName] = useState("");
  const [rolUser, setRolUser] = useState("");
  const [initial, setInitial] = useState("");
  const navigate = useNavigate();

  const infoFile = useSelector((state) => state.FileInfo);
  const auth = useSelector((state) => state.AuthenticationReferer);

  const roles = auth?.items?.UserLoginData?.SecurityLoginData?.Roles;

  const showNoti = () => {
    return roles?.find(
      (rol) =>
        rol.Name === "Mesa de control Admin" || rol.Name === "Mesa de control"
    );
  };
  useEffect(() => {
    // console.log('Inicitial---- headers', id)
    // console.log("Initial---- window.sessionStorage", sessionStorage)
    // dispatch(fileActions.GetFileInfo(id))

    let userVal = sessionStorage.getItem("userName");
    if (userVal !== undefined && userVal !== null && userVal !== "") {
      // console.log("Entra en validacion------ ", userVal)
      let nameVal = userVal; //user.split(" ")[0]
      let rolUserVal = sessionStorage.getItem("Rol");
      let initialVal = userVal?.split("")[0];

      setUser(userVal);
      setName(nameVal);
      setRolUser(rolUserVal);
      setInitial(initialVal);
    } else {
      console.log(
        "Entra en constantes de header------ ",
        user,
        name,
        rolUser,
        initial
      );
    }
  }, []);

  useEffect(() => {
    if (infoFile.items !== undefined) {
      setFolio(infoFile.items?.Folio);
    } else if (infoFile.error !== undefined) {
      if (infoFile.error == "unauthorized") {
        Alert(
          {
            icon: "error",
            title: "Error de autentificación, inicia sesión nuevamente",
            allowOutsideClick: false,
            allowEscapeKey: false,
          },
          () => {
            Logout();
          }
        );
      } else {
        alert(infoFile.error);
      }
    }
  }, [infoFile]);

  useEffect(() => {
    if (auth.items !== undefined) {
      // console.log("Datos Login:----------------------------------------------- ", auth.items)
      let userVal = `${auth.items.UserLoginData.Name} ${
        auth.items.UserLoginData.FatherLastName
      } ${auth.items.UserLoginData.MotherLastName ?? ""}`;
      let nameVal = userVal; //user.split(" ")[0]
      let rolUserVal = `${auth.items.UserLoginData.SecurityLoginData.Roles[0].Name}`;
      let initialVal = userVal?.split("")[0];
      // console.log("Datos Login:---------------setters-------------------------- ", userVal, nameVal, rolUserVal, initialVal)

      setUser(userVal);
      setName(nameVal);
      setRolUser(rolUserVal);
      setInitial(initialVal);
      // sessionStorage.setItem('userName', userVal)
      // sessionStorage.setItem('Rol', rolUserVal)
    } else if (auth.error !== undefined) {
      // setLoadView(false)
      // alert(auth.error)
      console.log("(auth.error)-----------: ", auth.error);
    }
  }, [auth]);

  let path = window.location.pathname;

  // console.log('pathpath', path)

  // AQUI DEBES DE CONDICIONAR QUE TIENES EN LA URL PARA PODER DAR EL TITULO DE LA MISMA
  const setSectionName = () => {
    console.log("path: ", path);
    switch (path) {
      case `${constPathRoot.pathRoot}/inbox/dashboards`:
        return "Dashboard";
      case `${constPathRoot.pathRoot}/inbox/search`:
        return "Buscar";
      case `${constPathRoot.pathRoot}/inbox/new-file`:
        return "Alta candidatos";
      case `${constPathRoot.pathRoot}/inbox/homeComponent`:
        return "Asignaciones";
      case `${constPathRoot.pathRoot}/inbox/homeComponent/single`:
        return "Asignaciones Individuales";
      case `${constPathRoot.pathRoot}/inbox/homeComponent/masive`:
        return "Asignaciones Masivas";
      case `${constPathRoot.pathRoot}/inbox/busqueda/:id`:
        return "Asignaciones";
      case `${constPathRoot.pathRoot}/inbox/bulkLoadUsers`:
        return "Alta candidatos masiva";
      case `${constPathRoot.pathRoot}/inbox/notifications`:
        return "Recordatorios";
      case `${constPathRoot.pathRoot}/inbox/UserManagement`:
        return "Administrador de Usuarios";
    }
  };

  const Logout = () => {
    console.log("CIERRASESION");
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.removeItem("token");
    localStorage.removeItem("versionApp");
  };

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        margin: ".7em 1.5em 0 1.5em",
      }}
    >
      <Box display="flex" alignItems={"center"} flexGrow={1}>
        {path.includes(paths.folio) ||
        path.includes(paths.folioSearch) ||
        path.includes(paths.recordatorios) ||
        path.includes(paths.notificationsAlert) ? (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <ArrowBackIcon
              style={{
                color: theme.palette.userStyle.textDetails,
                marginRight: ".6em",
              }}
              onClick={() => {
                if (path.includes(paths.folio)) {
                  return navigate(routes.home);
                } else if (path.includes(paths.folioSearch)) {
                  return navigate(routes.search, { state: folio });
                } else if (path.includes(paths.recordatorios)) {
                  return navigate(routes.recordatorios);
                } else {
                  return navigate(routes.home);
                }
              }}
            />
            <Typography
              style={{
                color: theme.palette.userStyle.textDetails,
                fontSize: "30px",
                fontWeight: 550,
              }}
            >
              Datos de solicitud
            </Typography>
          </Box>
        ) : (
          ""
        )}
        <Typography
          style={{
            color: theme.palette.userStyle.textDetails,
            fontSize: "30px",
            fontWeight: 550,
          }}
        >
          {setSectionName()}
        </Typography>
      </Box>
      <Hidden smDown>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {showNoti() && <NotificationsView />}
          <Box>
            <Icon
              fontSize={50}
              color={theme.palette.userStyle.icUser}
              icon="teenyicons:user-circle-outline"
            />
          </Box>
          <Box style={{ marginLeft: ".5em" }}>
            <Typography style={{ color: theme.palette.userStyle.textDetails }}>
              {name}
            </Typography>
            <Typography style={{ color: theme.palette.userStyle.textDetails }}>
              {rolUser}
            </Typography>
            <Typography
              style={{ color: theme.palette.userStyle.textDetails }}
              variant="caption"
            >
              <Link
                to="/auth/login"
                style={{ color: theme.palette.userStyle.textDetails }}
                onClick={() => {
                  Logout();
                }}
              >
                Cerrar Sesión
              </Link>
            </Typography>
          </Box>
        </Box>
      </Hidden>
    </Box>
  );
}

{
  /* <div className={localStyles.headerName} >
                {path.includes(paths.folio) || path.includes(paths.folioSearch) ?
                    <ArrowBackIcon
                        style={{ color: "#a0a1a2" }}
                        onClick={() => {
                            if (path.includes(paths.folio)) {
                                return navigate(routes.home)
                            } else if (path.includes(paths.folioSearch)) {
                                return navigate(routes.search)
                            }
                        }} />
                    : ""}
                <Typography variant="h6" className={localStyles.h6}>{setSectionName()}</Typography>
            </div>
            <div className={localStyles.generalSection}>
                <div className={localStyles.sectioUser}>
                    <Typography className={localStyles.p}>{name}</Typography>
                    <Typography className={localStyles.text}>{rolUser}</Typography>
                    <Typography className={localStyles.text}>
                        <Link className={localStyles.link} onClick={Logout}>
                            Cerrar Sesión
                        </Link>
                    </Typography>
                </div>
                <div className={localStyles.userIcon}>
                    <p className={localStyles.capital}>{initial}</p>
                </div>
            </div>  */
}
