import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({

    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: theme.palette.iconPrincButton.main,
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(2),
        width: 40,
        height: 40,
        borderRadius: '50%',
    },

    materialIcon: {
        color: `${theme.palette.actorsButton.main} !important`,
    },

    materialSelectedIcon: {
        color: `${theme.palette.actorsButton.bgIcon} !important`,
    },

    materialIconContainer: {
        '&:focus': {
            outline: 'none !important',
        },
        "&:hover $materialIcon": {
            color: '#ffffff !important',
        }
    },

    iconSelectedActor: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: `${theme.palette.actorsButton.main} !important`,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        width: 40,
        height: 40,
        borderRadius: '50%',
        fill: theme.palette.status.info,
        border: `solid 2px ${theme.palette.actorsButton.main}`,
        // color: '#ffffff',
        '&:focus': {
            outline: 'none',
        }
    },
    iconActor: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        width: 40,
        height: 40,
        borderRadius: '50%',
        border: `solid 2px ${theme.palette.actorsButton.main}`,
        color: theme.palette.status.info,
        '&:hover': {
            // color: '#ffffff',
            backgroundColor: `${theme.palette.actorsButton.main} !important`,
        },
        '&:focus': {
            outline: 'none',
            // color: '#ffffff',
            backgroundColor: theme.palette.status.info,
        }
    },

    requestIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: `${theme.palette.iconPrincButton.main} !important`,
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(2),
        width: 40,
        height: 40,
        borderRadius: '50%',
    },

    iconBackToRequest: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(2),
        width: 40,
        height: 40,
        borderRadius: '50%',
        fill: `${theme.palette.iconPrincButton.main}`,
        border: `solid 2px ${theme.palette.iconPrincButton.main}`,
        '&:hover': {
            backgroundColor: theme.palette.iconPrincButton.main,
        },
    },

    capital: {
        marginTop: '5px',
        padding: 0,
        fontSize: 20,
        fontWeight: 'bold',
        color: theme.palette.iconPrincButton.text,
    },


    capitalActor: {
        marginTop: '3px',
        padding: 0,
        fontSize: 20,
        fontWeight: 'bold',
    },

    sectionActors: {
        display: 'flex',
        alignItems: 'center'
    },

    headerOfSection: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },

    nameSection: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },

    nameAndFolio: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: ' center'
    },

    name: {
        color: `${theme.palette.status.info} !important`,
        fontWeight: 'bold !important'
    },

    folio: {
        color: `${theme.palette.status.info} !important`
    },

    accepted: {
        color: theme.palette.success.main,
        fontSize: 18,
        fontWeight: 'bold'
    },

    rejected: {
        color: theme.palette.error.main,
        fontSize: 18,
        fontWeight: 'bold'
    },

    status: {
        fontSize: 18,
        fontWeight: 'bold'
    }

}))