//Peticiones HTTP para temas referentes a Login y datos de usuario
import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';
import { BodySecurityResponseForceEncripted } from '../utils'

export const authRequests = {}//Objeto agrupador de funciones

authRequests.Authentication = (data) => {//Función de autenticación
  return axios.post(
    AppUrls.Authentication,//url
    Config.SetParameters(data, '', '', ''),//Objeto a enviar al API
    Config.ConfigNoAuth()//headers
  ).then(handleResponse)//next => actions => AuthActions
    .then(userAuthenticate => {
      sessionStorage.clear()
      sessionStorage.setItem(//Almacenar token opcion 1: SessionStorage
        'token',
        userAuthenticate.Body.Token
      )
      sessionStorage.setItem(//Borrar al cerrar sesión
        'userName',
        `${userAuthenticate.Body.UserLoginData.Name} ${userAuthenticate.Body.UserLoginData.FatherLastName}`
      )
      sessionStorage.setItem(//Borrar al cerrar sesión
        'Rol',
        `${userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name}`
      )
      return userAuthenticate;
    });
}

authRequests.AuthenticationEncripted = (data) => {
  // console.log("Request: ", data)
  return axios.post(
    AppUrls.Authentication,
    Config.SetParameters(data, "", "", "", true),
    Config.ConfigNoAuth()//headers
  ).then(handleResponseForceEncripted)
    .then(userAuthenticate => {
      console.log('userAuthenticate', userAuthenticate)
      // console.log("userAuthenticate: response: ", userAuthenticate)
      sessionStorage.clear()
      sessionStorage.setItem(//Almacenar token opcion 1: SessionStorage
        'token',
        userAuthenticate.Body.Token
      )
      sessionStorage.setItem(//Borrar al cerrar sesión
        'userName',
        `${userAuthenticate.Body.UserLoginData.Name} ${userAuthenticate.Body.UserLoginData.FatherLastName} ${userAuthenticate.Body.UserLoginData.MotherLastName}`
      )
      // console.log("Role: ", userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name)
      sessionStorage.setItem(//Borrar al cerrar sesión
        'Rol',
        `${userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name}`
      )

      //       SecurityLoginData: Object { Roles: (1) […], Applications: (1) […] }
      // ​​​​
      // Applications: Array [ {…} ]
      // ​​​​​
      // 0: Object { Id: 1, Name: "OnBoarding", Modules: [] }
      // ​​​​​​
      // Id: 1
      // ​​​​​​
      // Modules: Array []
      // ​​​​​​​
      // length: 0
      // ​​​​​​​
      // <prototype>: Array []
      // ​​​​​​
      // Name: "OnBoarding"
      // ​​​​​​
      // <prototype>: Object { … }
      // ​​​​​
      // length: 1
      // ​​​​​
      // <prototype>: Array []
      // ​​​​
      // Roles: Array [ {…} ]
      // ​​​​​
      // 0: Object { Id: 10, Name: "Mesa de control" }
      // ​​​​​​
      // Id: 10
      // ​​​​​​
      // Name: "Mesa de control"



      return userAuthenticate;
    });
}

authRequests.AuthenticationReferer = (data) => {
  // console.log("Request: ", data)
  return axios.post(
    AppUrls.AuthenticationReferer,
    Config.SetParameters(data, "", "", "", true),
    Config.ConfigNoAuth()//headers
  ).then(handleResponseForceEncripted)
    .then(userAuthenticate => {
      console.log('userAuthenticate', userAuthenticate)
      sessionStorage.clear()
      sessionStorage.setItem(//Almacenar token opcion 1: SessionStorage
        'token',
        userAuthenticate.Body.Token
      )
      sessionStorage.setItem(//Borrar al cerrar sesión
        'userName',
        `${userAuthenticate.Body.UserLoginData.Name} ${userAuthenticate.Body.UserLoginData.FatherLastName} ${userAuthenticate.Body.UserLoginData.MotherLastName}`
      )
      // console.log("Role: ", userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name)
      sessionStorage.setItem(//Borrar al cerrar sesión
        'Rol',
        `${userAuthenticate.Body.UserLoginData.SecurityLoginData.Roles[0].Name}`
      )

      //       SecurityLoginData: Object { Roles: (1) […], Applications: (1) […] }
      // ​​​​
      // Applications: Array [ {…} ]
      // ​​​​​
      // 0: Object { Id: 1, Name: "OnBoarding", Modules: [] }
      // ​​​​​​
      // Id: 1
      // ​​​​​​
      // Modules: Array []
      // ​​​​​​​
      // length: 0
      // ​​​​​​​
      // <prototype>: Array []
      // ​​​​​​
      // Name: "OnBoarding"
      // ​​​​​​
      // <prototype>: Object { … }
      // ​​​​​
      // length: 1
      // ​​​​​
      // <prototype>: Array []
      // ​​​​
      // Roles: Array [ {…} ]
      // ​​​​​
      // 0: Object { Id: 10, Name: "Mesa de control" }
      // ​​​​​​
      // Id: 10
      // ​​​​​​
      // Name: "Mesa de control"



      return userAuthenticate;
    });
}


authRequests.GetClientIPsWithRefer = () => {
  return axios.get(
    AppUrls.GetClientIPsWithRefer,
    // Config.SetParameters('', "", "", "", true),
    Config.ConfigNoAuth()//headers
  ).then(handleResponse)
}

//desencripta y devuelve objeto de la respuesta del API
let handleResponse = (response) => {
  let respObject = response.data;//BodySecurityResponse(response.data);

  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    const error = (respObject.Body || responseObject);
    return Promise.reject(error);
  }
  return respObject;
}

let handleResponseForceEncripted = (response) => {
  let respObject = BodySecurityResponseForceEncripted(response.data);
  if (!respObject.IsOK) {
    // console.log("respObject:handleResponseForceEncripted ", respObject)
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    const error = (respObject.Body || responseObject);
    return Promise.reject(error);
  }
  console.log('REQUEST AUTH:::', respObject)
  return respObject;
}