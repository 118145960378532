import { notificationConstants } from '../constants'
import { notificationRequests } from '../../services'

export const notificationActions = {}

notificationActions.GetNewPin = (data) => {
  return dispatch => {
    dispatch(request())
    notificationRequests.GetNewPin(data)
      .then(
        newPin => dispatch(success(newPin)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: notificationConstants.NEW_PIN_REQUEST }
  }

  function success(newPin) {
    return { type: notificationConstants.NEW_PIN_SUCCESS, newPin }
  }

  function failure(error) {
    return { type: notificationConstants.NEW_PIN_FAILURE, error }
  }

}

notificationActions.GetNotificationsUser = (data) => {
  return dispatch => {
    dispatch(request())
    notificationRequests.GetNotificationsUser(data)
      .then(
        notifications => dispatch(success(notifications)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: notificationConstants.GET_NOTIFICATIONS_USER_REQUEST }
  }

  function success(notifications) {
    return { type: notificationConstants.GET_NOTIFICATIONS_USER_SUCCESS, notifications }
  }

  function failure(error) {
    return { type: notificationConstants.GET_NOTIFICATIONS_USER_FAILURE, error }
  }

}

notificationActions.GetMetadataFill = (data) => {
  return dispatch => {
    dispatch(request())
    notificationRequests.GetMetadataFill(data)
      .then(
        metadataFill => dispatch(success(metadataFill)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: notificationConstants.GET_METADATA_FILL_REQUEST }
  }

  function success(metadataFill) {
    return { type: notificationConstants.GET_METADATA_FILL_SUCCESS, metadataFill }
  }

  function failure(error) {
    return { type: notificationConstants.GET_METADATA_FILL_FAILURE, error }
  }

}

notificationActions.GetCancelables = (data) => {
  return dispatch => {
    dispatch(request())
    notificationRequests.GetCancelables(data)
      .then(
        cancelables => dispatch(success(cancelables)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: notificationConstants.GET_NOTIFICATIONS_CANCELABLES_REQUEST }
  }

  function success(cancelables) {
    return { type: notificationConstants.GET_NOTIFICATIONS_CANCELABLES_SUCCESS, cancelables }
  }

  function failure(error) {
    return { type: notificationConstants.GET_NOTIFICATIONS_CANCELABLES_FAILURE, error }
  }

}

notificationActions.UpdateCancelFiles = (data) => {
  return dispatch => {
    dispatch(request())
    notificationRequests.UpdateCancelFiles(data)
      .then(
        setCancelables => dispatch(success(setCancelables)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: notificationConstants.GET_NOTIFICATIONS_SETCANCEL_REQUEST }
  }

  function success(setCancelables) {
    return { type: notificationConstants.GET_NOTIFICATIONS_SETCANCEL_SUCCESS, setCancelables }
  }

  function failure(error) {
    return { type: notificationConstants.GET_NOTIFICATIONS_SETCANCEL_FAILURE, error }
  }

}