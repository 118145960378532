import React, { useState } from 'react'
import { Dialog, DialogTitle, Typography, TextField, IconButton, MenuItem } from '@mui/material'
import { Close } from '@mui/icons-material'

import { mainStyles } from '../theme/mainStyles'
import { Buttons } from "../components"

export const CustomAlert = (props) => {
    const classes = mainStyles.customDialogStyles()
    const [disabledButton, setDisabledButton] = useState(props.disabledConfirm !== undefined && props.disabledConfirm !== null ? props.disabledConfirm : false)
    return (
        <Dialog onClose={props.closeAction} open={props.open} >
            <div className={classes.dialogCenter} style={props.containerStyle ? props.containerStyle : { padding: 5 }}>
                <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.title}>
                    {props.title}
                    {/* <IconButton
                        aria-label="close"
                        onClick={props.closeAction}
                        className={classes.customButtonClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton> */}
                </Typography>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    className={classes.dialogBody}>
                    {props.body}
                </Typography>
                {props.comments &&
                    <TextField
                        autoFocus
                        className={classes.textField}
                        placeholder={props.commentPlaceHolder}
                        type="text"
                        multiline
                        value={props.valueComment}
                        rows={4}
                        variant="outlined"
                        onChange={(event) => {
                            props.setValueComment(event.target.value)
                        }}
                    />
                }
                {props.list &&
                    <TextField
                        autoFocus
                        // className={classes.textField}
                        fullWidth
                        select
                        placeholder={props.commentPlaceHolder}
                        onChange={(event) => {
                            console.log('event.target.value::: ', event.target.value)

                            props.setValueComment(event.target.value)
                        }}
                    >
                        {props.list.map((opt, i) => (
                            <MenuItem
                                key={`${opt?.Option}${i}`}
                                value={opt?.Value}
                                placeholder="Seleccione"
                                selected
                                sx={{ wordSpacing: '0.2rem' }} onClick={() => { setDisabledButton(false) }}
                            >
                                {opt.Option?.toUpperCase()}
                            </MenuItem>
                        ))}
                    </TextField>
                }
                <Buttons ConfirmButtonText={props.rightButtonText} disabledConfirm={disabledButton}
                    onClickConfirm={props.rightButtonAction}
                    CancelButtonText={props.leftButtonText}
                    onClickCancel={props.NoCancelButton ? () => { setDisabledButton(true); props.leftButtonAction() } : undefined} />

                {/* <div className={classes.btnContainer}>
                    <mainStyles.CustomDynamicLink
                        text_size={12}
                        text_color={theme.palette.button.main}
                        text={props.leftButtonText}
                        type='button'
                        className={classes.leftButton}
                        click={props.leftButtonAction} />
                    <mainStyles.CustomDynamicLink
                        text_size={12}
                        text_color={theme.palette.button.main}
                        text={props.rightButtonText}
                        type='button'
                        className={classes.rightButton}
                        click={props.rightButtonAction} /> 
                </div>*/}
            </div>
        </Dialog >
    )
}