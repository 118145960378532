import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fileActions, validationActions } from "../../store/actions";
import protect from "../../utils/protect";

import {
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
  Typography,
  Grid,
  useTheme,
} from "@mui/material";
import Empty from "../../assets/NoResultMeet.png";
import {
  MainTable,
  UseImage,
  CustomLoadingOverlay,
  GenericHeaderActions,
} from "../../components";

import { useStyles } from "./HomeStyles";
import { mainStyles } from "../../theme/mainStyles";
// import TableUsersSigners from '../BulkLoad/TableUsersSigners'

function HomeComponent(props) {
  const theme = useTheme();
  const localStyles = useStyles(theme);
  const noInfo = mainStyles.noDataSections();
  const [searching, setSearching] = useState("");
  const [search, setSearch] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  // const [infoItems, setInfoItems] = useState([]);

  const [pageData, setPageData] = useState([]);
  const [pageSize, setPageSize] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const info = useSelector((state) => state.SearchByUserPagination);
  const dispatch = useDispatch();

  const getTableInfo = () => {
    dispatch(
      fileActions.GetUserSearchPagination({
        SearchText: search,
        Page: currentPage,
      })
    );
  };

  useEffect(() => {
    // dispatch(fileActions.GetSearchByUser({ "SearchText": search }))
    getTableInfo();
    dispatch(validationActions.PostFileValidation({}));
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    // dispatch(fileActions.GetSearchByUser({ "SearchText": search }))
    !firstLoad && getTableInfo();
  }, [search]);

  useEffect(() => {
    if (info.items !== undefined) {
      // setInfoItems(info.items);
      setPageData(info.items.PageData);
      setPageSize(info.items.PageSize);
      setTotalRecords(info.items.TotalRecords);
    }
  }, [info]);

  useEffect(() => {
    !firstLoad && getTableInfo();
  }, [currentPage]);

  return (
    <div style={{ maxHeight: "80vh" }}>
      <form className={localStyles.form}>
        <GenericHeaderActions
          onChangeTextField={(event) => {
            console.log("event.target.value::: ", event.target.value);
            event.target.value === "" && setSearch("");
            setSearching(event.target.value);
          }}
          placeholderTextField="Busca por Asegurado, Beneficiario, Póliza o No. de reclamación"
          onClickConfirm={(event) => {
            event.preventDefault();
            setSearch(searching);
          }}
          ConfirmButtonText="Buscar"
          CancelButtonText="Limpiar búsqueda"
          onClickCancel={() => {
            setSearch("");
            setSearching("");
          }}
        />
      </form>
      {info.error && <p>{"Error de red favor de actualizar la pagina"}</p>}
      {info.loading ? (
        <CustomLoadingOverlay
          active={info.loading}
          text="Espera un momento por favor..."
        />
      ) : !!pageData && pageData.length > 0 ? (
        <MainTable
          data={pageData}
          PageSize={pageSize}
          CurrentPage={currentPage}
          TotalRecords={totalRecords}
          SetCurrentPage={(p) => {
            setCurrentPage(p);
          }}
        />
      ) : (
        <div className={noInfo.noResult}>
          <UseImage src={Empty} type="img" style={{ maxWidth: 300 }} />
          <Typography variant="h6" className={noInfo.h6}>
            No se encontraron solicitudes
          </Typography>
        </div>
      )}
    </div>
  );
}

export default protect(HomeComponent);
